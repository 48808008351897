import { ON_ENTER_TEST_ROOM, ON_REFETCH_TEST_LIST, SET_ANSWER_LIST, SET_FOCUS_TEST_ID, SET_FOCUS_USER, SET_NOT_SUBMIT_USER, SET_OMR_FREQUENCY, SET_QUESTION_LIST, SET_STATISCIS_LIST, SET_SUBMIT_LIST, SET_SURVEY_FREQUENCY, SET_TEST_BACKGROUND, SET_TEST_LIST, TOGGLE_FOLD_MODAL, TOGGLE_TEST_MODAL, } from './actionTypes';
export const onEnterTestRoom = () => {
    return {
        type: ON_ENTER_TEST_ROOM,
    };
};
export const onRefetchTestList = () => {
    return {
        type: ON_REFETCH_TEST_LIST,
    };
};
export const setTestNoticeMessage = (message) => {
    return {
        type: 'SET_TEST_NOTICE_MESSAGE',
        message,
    };
};
export const setIsEnterRoom = (isEnterRoom) => {
    return {
        type: 'SET_IS_ENTER_ROOM',
        isEnterRoom,
    };
};
export const setTestBackground = (backgroundUrl) => {
    return {
        type: SET_TEST_BACKGROUND,
        backgroundUrl,
    };
};
export const setTestList = (items) => {
    return {
        type: SET_TEST_LIST,
        items,
    };
};
export const setStatisticsList = (test_id, statisticsList) => {
    return {
        type: SET_STATISCIS_LIST,
        testId: test_id,
        statisticsList,
    };
};
export const setSubmitList = (test_id, submitStatus) => {
    return {
        type: SET_SUBMIT_LIST,
        testId: test_id,
        submitStatus,
    };
};
export const setQuestionList = (test_id, questionList) => {
    return {
        type: SET_QUESTION_LIST,
        testId: test_id,
        questionList,
    };
};
export const setAnswerList = (answerList) => {
    return {
        type: SET_ANSWER_LIST,
        answerList,
    };
};
export const setNotSubmitUser = (submitted) => {
    return {
        type: SET_NOT_SUBMIT_USER,
        submitted,
    };
};
export const toggleTestModal = (isModal) => {
    return {
        type: TOGGLE_TEST_MODAL,
        isModal,
    };
};
export const toggleTestFold = (isModal) => {
    return {
        type: TOGGLE_FOLD_MODAL,
        isModal,
    };
};
export const setFocusTestId = (testId) => {
    return {
        type: SET_FOCUS_TEST_ID,
        testId,
    };
};
export const setFocusUser = (userId, userName) => {
    return {
        type: SET_FOCUS_USER,
        user: userId ? { id: userId, name: userName } : undefined,
    };
};
export const setSurveyFrequency = (frequency) => {
    return {
        type: SET_SURVEY_FREQUENCY,
        frequency,
    };
};
export const setOmrFrequency = (testId, frequency) => {
    return {
        type: SET_OMR_FREQUENCY,
        testId,
        frequency,
    };
};
export const setOmrTempSave = (testId) => {
    return {
        type: 'SET_OMR_TEMP_SAVE',
        testId,
    };
};
export const setSubmitOption = (data) => {
    return {
        type: 'SET_SUBMIT_OPTION',
        data,
    };
};
