import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { getIsSubmitUser, getTestItemByTestId } from '../../test';
import { Colors } from '../common/Colors';
import { AttachmentQuestionContainer } from './AttachmentQuestionContainer';
import { RemaingTimer } from './RemaingTimer';
import { SurveyQuestionContainer } from './SurveyQuestionContainer';
import { OmrContainer } from './omr/OmrContainer';
// @ts-ignore
const QuestionSolveStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
	height: 100%;
	flex-wrap: wrap;

	.bold_number {
		font-weight: 700;
		font-size: 18px;
		line-height: 26px;
	}

	.btn {
		p {
			min-width: ${props => (props.$isMobile ? 'auto' : '76px')};
		}
	}

	.dropdown-icon {
		width: max-content;
		.dropdown-field {
			background-color: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
		}

		.placeholder {
			color: var(--color-grey-c, #e6e8ed);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
		}

		.dropdown-list {
			border-color: var(--color-grey-c, #e6e8ed);
			border-radius: 8px;
			padding: 10px 8px 10px 8px;
			min-width: 200px;
			left: 0;
			top: 43px;

			.dropdown-label {
				background: var(--color-white, #ffffff);
				border-radius: 8px;
				padding: 10px 8px 10px 8px;
			}
		}

		.dropdown-label2 {
			background-color: var(--color-grey-c, #e6e8ed);
			.placeholder2 {
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				font-weight: var(--b3-font-weight, 400);
			}
		}
	}

	.field-001 {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
	}

	.pdf-control {
		background: rgba(77, 77, 77, 0.8);
		border-radius: 100px;
		padding: 6px 28px 6px 28px;
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: center;
		// position: absolute;
		min-width: 182px;
		// left: 50%;
		// top: 14px;
		color: var(--color-white, #ffffff);
		// transform: translateX(-50%);
		z-index: 10;

		> * {
			margin: 0;
		}
	}

	.pdf-control-body {
		min-height: 500px;
		width: 100%;
		height: 100%;
	}

	.test-area {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
		width: 100%;

		.fext-field {
			width: 100%;
			height: 100%;
		}
	}

	.file-container {
		flex-direction: ${props => (!props.$isDesktop ? 'column' : 'row')};

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.test-area,
	.field-001 {
		margin: 0;
		> * {
			margin: 0;
		}
	}

	.react-pdf__Document {
		width: 100%;
		height: 100%;
	}

	.no_file,
	.react-pdf__message--error {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #d5d5d5;
		border-radius: 8px;
	}

	.test-area-before-submit {
		width: 100%;
		flex: 1;

		.fext-field {
			flex: 1;
		}
	}

	@media screen and (max-width: 738px) {
		.file-container {
			flex-direction: column;
			height: 100%;

			.test-area {
				min-height: 482px;
				width: 100%;

				.fext-field {
					flex: 1;
				}
			}
		}
	}

	${props => props.$isMobile && mobileStyles}
`;
const mobileStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		/* .table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		} */

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
export const QuestionSolve = React.memo(({ test_id }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useGlobalState();
    // 제출 사용자 및 미제출 사용자 구분
    const notSubmit = useSelector((state) => getIsSubmitUser(state), shallowEqual);
    // 테스트 아이템 조회
    const testItem = useSelector((state) => getTestItemByTestId(state, test_id), shallowEqual);
    const type = useMemo(() => {
        if (!testItem)
            return null;
        if (testItem.type === '설문형') {
            return 'survey';
        }
        else if (testItem.type === '파일첨부형') {
            return 'attachment';
        }
        else {
            return 'omr';
        }
    }, [testItem?.type]);
    if (!testItem)
        return null;
    return (React.createElement(QuestionSolveStyled, { "$isMobile": isMobile, "$isDesktop": isDesktop },
        React.createElement("div", { className: "field-001", style: {
                width: '100%',
                overflow: 'hidden',
            } },
            React.createElement("div", { className: "task-label", style: { minWidth: 57, alignItems: 'flex-start' } }, "\uD14C\uC2A4\uD2B8\uBA85"),
            React.createElement("div", { className: "fext-field", style: { overflow: 'hidden' } },
                React.createElement("p", { className: "overText5", style: { whiteSpace: 'break-spaces', overflow: 'hidden' } }, testItem.title))),
        React.createElement("div", { className: "field-001" }, testItem.status === 'closed' || testItem.status === 'created' ? (React.createElement("h3", { style: { color: Colors.pink } }, t(`assignment.${testItem.status}Guid`))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "task-label", style: { minWidth: 65 } }, "\uB0A8\uC740 \uC2DC\uAC04"),
            React.createElement(RemaingTimer, { test_id: testItem.id })))),
        type === 'omr' && React.createElement(OmrContainer, { testItem: testItem, notSubmit: notSubmit }),
        type === 'survey' && (React.createElement(SurveyQuestionContainer, { test_id: test_id, testItem: testItem, isSubmit: !notSubmit })),
        type === 'attachment' && (React.createElement(AttachmentQuestionContainer, { test_id: test_id, testItem: testItem, isSubmit: !notSubmit }))));
});
