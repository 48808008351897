import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { getAnswerList, getIsSubmitUser, getSubmitUserScoreByTestId, getTestItemByTestId } from '../../test';
import { SurveyLayout } from './SurveyLayout';
import { SurveyResultLayout } from './SurveyResultLayout';
import { ErrataTable } from './omr/ErrataTable';
import { AttachFileAnswer } from './AttachFileAnswer';
// @ts-ignore
const DSTL001ViewStyled = styled.div `
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: ${props => (props.$isMobile ? '24px' : 'unset')};

	&.hidetitle {
		height: 100%;
		flex: 1;
	}

	input:disabled {
		background: var(--color-white, #ffffff);
	}

	.field-short {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.errataTable_wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: 100%;

		.score {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;

			padding: 20px 0px 20px 0px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			${props => props.$isMobile
    ? css `
							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 16px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 20px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						`
    : css `
							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						`}

			.fixed {
				color: #f46790;
				text-align: right;
				font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
				font-size: var(--h3-font-size, 24px);
				line-height: var(--h3-line-height, 36px);
				font-weight: var(--h3-font-weight, 700);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	&.hidetitle {
		.errataTable_wrap {
			margin-top: 12px;
		}
	}

	.btn_default {
		margin-top: 36px;
	}

	.no_submit_wrap {
		width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		font-weight: var(--b3-font-weight, 400);
		position: relative;
		height: ${props => (props.$isTablet ? '100%' : undefined)};
	}

	.name-tag-info {
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.att_submit_view {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;
		margin: 0;
		* {
			margin: 0;
		}
	}

	.feedback-submit-button {
		display: flex;
		justify-content: flex-end;
	}

	.feedback-file {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.submit-false {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.survey {
		width: 100%;
	}

	.body4 {
		font-weight: normal;
	}

	.survey-header {
		flex-direction: row;
		padding: 12px 16px 12px 16px;
		gap: 30px;
		background: var(--color-silver, #f3f4f8);
		border-radius: 8px 8px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.survey-title {
		gap: 12px;
		font-size: var(--b4-font-size, 14px);
		display: flex;
		flex: 1;
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.dropdown {
		display: flex;
		flex-direction: column;
		gap: 6px;
		align-items: flex-start;
		justify-content: flex-start;
		cursor: pointer;
		width: 100%;
		position: relative;
	}

	.survey .dropdown-field,
	.survey .survey-input {
		border-width: 0px !important;
		border: none;
		padding: 0;
	}

	.dropdown-popup .dropdown-field {
		border-radius: 4px;
		padding: 10px;
	}

	.dropdown-field {
		background: var(--color-white, #ffffff);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 100%;
	}

	.dropdown-field .placeholder {
		text-align: left;
		font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
	}

	.dropdown-popup .placeholder {
		color: var(--color-black, #101010);
		font-size: var(--b5-font-size, 12px);
		line-height: var(--b5-line-height, 22px);
		font-weight: var(--b5-font-weight, 400);
	}

	.survey-body {
		padding: 16px;
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.survey .survey-body .survey-content {
		display: flex;
		gap: 12px;
		flex-direction: column;
	}

	.checkbox,
	.tablet .checkbox {
		display: flex;
		gap: 12px;
		flex-wrap: wrap;
	}

	.flex.column {
		flex-direction: column;
	}

	.survey .survey-body label {
		height: 40px;
	}

	.checkbox label,
	.tablet .checkbox label {
		gap: 6px;
		cursor: pointer;
	}

	.checkbox label {
		gap: 6px;
		cursor: pointer;
	}

	.checkbox-item {
		display: flex;
		flex-direction: row;
		gap: 5px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		cursor: pointer;
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b-l-medium-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b-l-medium-font-size, 14px);
		line-height: var(--b-l-medium-line-height, 20px);
		font-weight: var(--b-l-medium-font-weight, 400);
		position: relative;
		display: flex;
	}

	.survey .survey-body .survey-content input:not(.suvey-input) {
		border: none;
		padding: 12px 0;
	}

	input[type='checkbox'] {
		display: none;
	}

	.checkbox label span,
	.tablet .checkbox label span {
		font-size: var(--b4-font-size, 12px);
		line-height: 150%;
		letter-spacing: -0.02em;
	}

	.checkbox label span {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.suvey-input {
		padding: 6px 10px;
	}

	.survey_option_detail {
		display: none;

		.radio_wrap {
			label {
				> span {
					margin-left: 6px;
					font-size: 12px;
					line-height: 1.41;
					letter-spacing: -0.01em;
				}
			}

			.mark {
				background: var(--color-grey-c);
				border-color: var(--color-grey-c);
			}
		}

		.chk_wrap {
			label {
				> span {
					margin-left: 6px;
					font-size: 12px;
					line-height: 1.41;
					letter-spacing: -0.01em;
				}
			}
		}
	}

	.survey_option_detail.active {
		display: block;
	}

	.survey_option_detail[survey-option='1'].active {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 5px 20px;
	}

	.survey_option_detail[survey-option='4'].active {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 5px 20px;
	}

	.survey-submit-view {
		// height: calc(100% - 70px);
		overflow: auto;
		gap: 12px;
		display: grid;
		padding: 10px;
		margin: 0;
		* {
			margin: 0;
		}
	}

	.survey-table {
		width: 100%;
		border-collapse: collapse;
	}

	.survey-table th,
	.survey-table td {
		font-weight: 100;
		padding: 10px;
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
	}

	.survey-data-header {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
		position: relative;
		width: 100%;
	}

	.survey-data-header th {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
	}

	.count-column {
		width: 50px;
		text-align: center;
	}

	.survey-result {
		padding: 20px 20px 20px 20px;
		border-radius: 0px 0px 8px 8px;
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 1.5px 1.5px 1.5px;
	}

	${props => props.$isMobile && mobileStyles}
`;
const mobileStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		/* .table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		} */

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}

	.survey-submit-view {
		padding: 0px;
		margin: 0;
		* {
			margin: 0;
		}
	}

	.survey {
		width: 100%;
		border-radius: 12px;
		border: 1px solid #f3f4f8;
		position: relative;
	}

	.body4 {
		font-size: 14px;
	}

	.survey .survey-header {
		flex-direction: column;
		padding: 6px 10px 6px 10px;
	}

	.survey .survey-header {
		background: var(--color-silver, #f3f4f8);
		border-radius: 8px 8px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		gap: 0;
	}

	.survey .dropdown {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 6px 0px;
	}

	.survey .dropdown .dropdown-list {
		top: 33px !important;
	}

	.survey .survey-header .survey-title {
		gap: 8px;
		font-size: var(--b4-font-size, 12px);
		width: 100%;
	}

	.survey .dropdown-field,
	.survey .survey-input {
		border-width: 0px !important;
		border: none;
		padding: 0;
	}
`;
const count = 20;
export const DSTL001_Submit_View = React.memo(({ hideTitle = false, test_id, member }) => {
    const { isTablet, isMobile } = useGlobalState();
    const { t } = useTranslation();
    const testItem = useSelector((state) => getTestItemByTestId(state, test_id), shallowEqual);
    const notSubmit = useSelector((state) => getIsSubmitUser(state), shallowEqual);
    const answerData = useSelector((state) => getAnswerList(state, test_id), shallowEqual);
    const [submittedFileList, setSubmittedFileList] = useState([]);
    React.useEffect(() => {
        if (!answerData || answerData.length === 0)
            return;
        const valuesArray = answerData.map(item => item?.value);
        // console.log("valuesArray", valuesArray);
        setSubmittedFileList(valuesArray);
    }, [answerData]);
    const [changeSurveyData, setChangeSurveyData] = React.useState([]);
    // 과제 정보 조회
    const score = useSelector((state) => getSubmitUserScoreByTestId(state, test_id), shallowEqual);
    const type = useMemo(() => {
        if (!testItem)
            return null;
        if (testItem.type === '설문형') {
            return 'survey';
        }
        else if (testItem.type === '파일첨부형') {
            return 'attachment';
        }
        else {
            return 'omr';
        }
    }, [testItem?.type]);
    const { member_id, member_name } = useMemo(() => {
        return { member_id: member?.id || '', member_name: member?.name };
    }, [member]);
    const renderEmptySurvey = () => {
        return (React.createElement("div", { className: "submit-false", style: { flex: 1 } },
            React.createElement("p", null, "\uC81C\uCD9C\uB41C \uC124\uBB38\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.")));
    };
    return (React.createElement(DSTL001ViewStyled, { "$isDetail": member_id ? true : false, "$isTablet": isTablet, "$isMobile": isMobile, className: `${hideTitle ? 'hidetitle' : ''}` },
        type === 'omr' && (React.createElement("div", { className: `${hideTitle ? 'hidetitle' : ''} dstl001-submit-view` },
            React.createElement("div", { className: "score-title" },
                hideTitle && !member_id
                    ? t('assignment.scoreStatus2')
                    : t('assignment.scoreStatus', { score }),
                member_name ? `- ${member_name}` : ''),
            React.createElement("div", { className: "errataTable_wrap" },
                React.createElement(ErrataTable, { isDetail: member_id ? true : false })))),
        type === 'survey' &&
            (member_id ? (!notSubmit ? (React.createElement("div", { className: "survey-submit-view" },
                React.createElement(SurveyLayout, { testItem: testItem, isSubmit: true, isRegist: false, setChangeSurveyData: setChangeSurveyData }))) : (renderEmptySurvey())) : (React.createElement("div", { className: "survey-submit-view", style: { flex: 1 } },
                React.createElement(SurveyResultLayout, { isSubmit: true, testItem: testItem, isRegist: false })))),
        type === 'attachment' &&
            (!notSubmit ? (React.createElement("div", { className: "att_submit_view" },
                React.createElement("div", { className: "feedback-file" },
                    React.createElement(AttachFileAnswer, { isTeacher: true, showRegist: false, isRegist: false, attachFileList: submittedFileList })))) : (React.createElement("div", { className: "submit-false", style: { flex: 1 } },
                React.createElement("p", null, "\uC81C\uCD9C\uB41C \uD30C\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4."))))));
});
