import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getQuestionList, getSurveyData, getAnswerList } from '../../test';
import SurveyItem from './SurveyItem';
// SurveyLayout 컴포넌트
export const SurveyLayout = React.memo(({ testItem, isRegist, isSubmit, isModify, setChangeSurveyData, }) => {
    const questionList = useSelector((state) => getQuestionList(state), shallowEqual);
    const answersList = useSelector((state) => getAnswerList(state), shallowEqual);
    const [surveyData, setSurveyData] = React.useState([]);
    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    // 설문 데이터를 가져오는 useEffect
    React.useEffect(() => {
        if (isSubmit && (answersList.length === 0 || !answersList || isEmptyObject(answersList)))
            return;
        const data = getSurveyData(testItem, questionList, answersList);
        // console.log('answerList:', answersList);
        // console.log('frequencyList:', frequenciesList);
        // console.log('surveyData:', data);
        setSurveyData(data);
        setChangeSurveyData(data);
    }, [testItem, questionList, answersList, isSubmit]);
    // 설문 데이터가 변경되었을 때 처리하는 함수
    const handleSurveyDataChange = (updatedItem) => {
        setChangeSurveyData(prevData => {
            const data = prevData.map(item => {
                if (item.id === updatedItem.id) {
                    return {
                        ...item,
                        ...updatedItem,
                    };
                }
                return item;
            });
            return data;
        });
    };
    return (React.createElement(React.Fragment, null, surveyData.length > 0 &&
        surveyData.map((item, index) => (React.createElement(SurveyItem, { key: index, index: index, item: item, isSubmit: isSubmit, isRegist: isRegist, setChangeSurveyData: handleSurveyDataChange })))));
});
