import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTestSubmitStatusByTestId } from '../../test';
import { setFocusTestId } from '../../test/action';
import { Button } from '../common';
import { Colors } from '../common/Colors';
import { toggleLoad } from '../loading';
import { getExternalUserId } from '../local';
import { RemaingTimer } from './RemaingTimer';
import { startTimer } from './querys';
import { useGlobalState } from '../../app/GlobalStateProvider';
export const TestItem = React.memo(({ i, notSubmit, isTeacher }) => {
    const dispatch = useDispatch();
    const { isMobile } = useGlobalState();
    const textRef = useRef(null);
    const handlerStartTimer = useMutation(startTimer);
    const memberId = useSelector((state) => (!isTeacher ? getExternalUserId(state) : undefined), shallowEqual);
    const staticsData = useSelector((state) => getTestSubmitStatusByTestId(state, i.id), shallowEqual);
    const [notSubmitted, setNotSubmitted] = React.useState(false);
    const [submitCount, setSubmitCount] = React.useState(0);
    const [submitRate, setSubmitRate] = React.useState(0);
    console.log(notSubmitted);
    useEffect(() => {
        if (staticsData) {
            if (staticsData.total_count > 0) {
                const member = memberId ? staticsData.items.find(i => i.member_id === memberId) : null;
                setSubmitCount(staticsData.submitted);
                setSubmitRate(((staticsData.submitted / staticsData.total_count) * 100).toFixed(2));
                setNotSubmitted(member ? member.not_submitted : false);
            }
            else {
                setSubmitCount(0);
            }
        }
    }, [staticsData]);
    return (React.createElement("div", { className: "wrap-box", key: i.id },
        React.createElement("div", { className: "field-global-001", style: { flexWrap: `${isMobile ? 'wrap' : 'nowrap'}`, alignItems: 'flex-start' } },
            React.createElement("div", { className: "task-label" }, "\uD14C\uC2A4\uD2B8\uBA85"),
            React.createElement("div", { className: "fext-field" },
                React.createElement("p", { className: "overText5", style: { whiteSpace: 'break-spaces' } }, i.title))),
        React.createElement("div", { className: "field-global-001", style: { flexWrap: `${isMobile ? 'wrap' : 'nowrap'}`, alignItems: 'flex-start' } },
            React.createElement("div", { className: "task-label" }, "\uC9C4\uD589\uC2DC\uAC04"),
            React.createElement("div", { className: "fext-field" }, i.status === 'created' ? (isTeacher ? (React.createElement(React.Fragment, null,
                React.createElement("input", { ref: textRef, type: "number", min: 0, defaultValue: i.duration, className: "fext-input" }),
                "\uBD84")) : (`${i.duration}분`)) : (React.createElement(RemaingTimer, { test_id: i.id })))),
        isTeacher && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, "\uC81C\uCD9C\uC778\uC6D0"),
                React.createElement("div", { className: "fext-field" },
                    submitCount,
                    " \uBA85")),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, "\uC81C\uCD9C \uBE44\uC728"),
                React.createElement("div", { className: "fext-field" },
                    React.createElement("div", { className: "fext-field" },
                        submitRate,
                        "%"))))),
        React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: "click_button undefined text-button no-color submit-status-btn", text: isTeacher ? `common.${i.status}` : !notSubmitted ? '제출 완료' : `common.show${i.status}`, styles: {
                    buttonWrap: {
                        backgroundColor: i.status === 'open'
                            ? !isTeacher && !notSubmitted
                                ? Colors.w_grey
                                : Colors.pink
                            : i.status === 'created' && isTeacher
                                ? '#8bacff'
                                : Colors.w_grey,
                    },
                }, onClick: !isTeacher
                    ? i.status !== 'created'
                        ? () => {
                            dispatch(setFocusTestId(i.id));
                        }
                        : undefined
                    : i.status === 'created'
                        ? () => {
                            try {
                                const duration = (textRef.current && textRef.current.value) || '1';
                                handlerStartTimer.mutateAsync({
                                    id: i.id,
                                    duration: Number(duration),
                                });
                                dispatch(toggleLoad(true));
                            }
                            catch (error) {
                                try {
                                    // Handle error
                                    APP.UI.alertMessage(error);
                                }
                                catch (error) {
                                    APP.UI.alertMessage('시작할 수 없습니다.');
                                }
                            }
                        }
                        : () => {
                            dispatch(setFocusTestId(i.id));
                        } }))));
});
