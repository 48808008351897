import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { getFocusUser } from '../../test';
import { setFocusUser } from '../../test/action';
import { Button } from '../common';
import { Colors } from '../common/Colors';
import { DSTL001_Submit_View } from './DSTL001_Submit_View';
import { SubmissionStatusTable } from './SubmissionStatusTable';
export const SubmissionContainer = React.memo(({ isTest, test_id }) => {
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const selectedUser = useSelector((state) => getFocusUser(state));
    if (!test_id)
        return null;
    return (React.createElement("div", { className: `${!isDesktop ? 'mobile submission-container' : 'submission-container'}` },
        React.createElement("div", { className: "wrap" },
            ((isMobile || isTablet) && !selectedUser) || isDesktop ? (React.createElement("div", { className: "submission-list" },
                React.createElement(SubmissionStatusTable, { isTest: isTest, test_id: test_id, isDetail: true }))) : null,
            ((isMobile || isTablet) && selectedUser) || isDesktop ? (React.createElement(React.Fragment, null,
                (isMobile || isTablet) && selectedUser && (React.createElement(Button, { className: "prev", option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 20,
                            color: Colors.w_grey,
                        },
                    }, text: "\uB4A4\uB85C\uAC00\uAE30", onClick: () => {
                        dispatch(setFocusUser(undefined, undefined));
                    } })),
                React.createElement("div", { className: "score-list" },
                    React.createElement(DSTL001_Submit_View, { hideTitle: false, test_id: test_id, member: selectedUser })))) : null)));
});
