import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../../base/common';
import { RemaingTimer } from '../../base/question/RemaingTimer';
import { Icon } from '../../base/ui';
import { onEnterTestRoom } from '../action';
import { getIsEnterTestRoom, getTestBackgroundUrl, getTestItemByTestId } from '../functions';
// @ts-ignore
const TestModeStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.device-container {
		display: flex;
		flex-direction: column;
		.device_wrapper {
			width: 100%;
			height: 100%;
			flex: 1;
		}
		.overText2 {
			white-space: break-spaces;
		}

		.device_title_container {
			width: 100%;
			height: 100%;

			.device_container {
				width: ${props => (!props.isDesktop ? '100%' : '402px')};
				overflow: auto;
			}
			.device_guid {
				width: ${props => (!props.isDesktop ? '100%' : '402px')};
				overflow: hidden;

				font-size: ${props => (!props.isDesktop ? '20px' : '24px;')};
				white-space: break-spaces;
			}
		}
	}

	.device_btn {
		p {
			color: #fff;
			text-align: left;
			font-family: Pretendard;
			font-size: ${props => (!props.isDesktop ? '16px' : '24px;')};
			font-style: normal;
			font-weight: 700;
			letter-spacing: -0.4px;
			line-height: 40px;
		}
	}

	.red_time {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translate(-50%, 0);
		.text-field {
			font-size: 60px;
			text-align: center;

			padding: 20px;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
			box-sizing: border-box;
			background-color: red;
			border-radius: 20px;

			color: #fff;
		}
	}

	.test-mode-head {
		display: flex;
		padding: 20px;
		box-sizing: border-box;
		width: 100%;
		background-color: #000;
	}

	img {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
`;
/**
 * 모의고사 모드인 경우 입장 화면 및 모의고사 진행 시간을 보여주는 컴포넌트
 */
export const OnlyTestMode = React.memo(() => {
    const { isDesktop } = useGlobalState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isEnterTestRoom = useSelector((state) => getIsEnterTestRoom(state));
    const testItem = useSelector((state) => getTestItemByTestId(state));
    const backgroundUrl = useSelector((state) => getTestBackgroundUrl(state));
    const onEnterTest = () => {
        dispatch(onEnterTestRoom());
    };
    return (React.createElement(TestModeStyled, { className: "test_layout", isDesktop: isDesktop }, isEnterTestRoom ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "red_time" }, testItem?.status === 'open' ? (React.createElement(RemaingTimer, { test_id: testItem.id })) : (React.createElement("div", { className: "text-field", style: { margin: 0 } }, "00:00:00"))),
        React.createElement("img", { src: backgroundUrl, alt: "mock_test background" }))) : (React.createElement("div", { className: "device-container" },
        React.createElement("div", { className: "device_wrapper" },
            React.createElement("div", { className: "device_title_container" },
                React.createElement("div", { className: "device_container" },
                    React.createElement(React.Fragment, null,
                        React.createElement(Icon, { icon: "device_connect", size: isDesktop ? 68 : 48, color: "#B9D1EF" }),
                        React.createElement("p", { className: "device_title" }, t('deviceSelection.testMode')),
                        React.createElement("p", { className: "device_guid" }, t('deviceSelection.testModeGuid'))),
                    React.createElement(Button, { className: "device_btn", onClick: onEnterTest, text: 'deviceSelection.enterTestMode', option: {
                            buttonBeforeIcon: {
                                show: true,
                                name: 'nickname_enter',
                                size: 24,
                                color: '#FFF',
                            },
                        } }))))))));
});
