import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../common/Colors';
import { Button, FileInput } from '../common';
import { useGlobalState } from '../../app/GlobalStateProvider';
import styled, { css } from 'styled-components';
const AttachFileAnswerStyled = styled.div `
	width: 100%;
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.attach-file-box-render {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-answer {
		width: 100%;
		display: grid;
		gap: 12px;

		* {
			margin: 0;
		}
	}
	
	.attach-file-column {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-box {
		border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
	}

	.mobile-button-font {
		padding: 12px 10px 12px 10px;
		border-radius: 6px;
		p {
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}
	}

	.btn_light_grey {
		background-color: #83868a;
		color: #fff;
	}

	.xmd {
		gap: 10px;
		padding: 12px 26px;
		border-radius: 6px;
	}

	.button {
		display: inline-flex;
		width: min-content;
		border: none;
		justify-content: center;
		align-items: center;

		p {
			min-width: 76px;
			font-family: var(--b-l-medium-font-family, "NotoSansKr-Regular", sans-serif);
    		font-size: var(--b-l-medium-font-size, 14px);
    		line-height: var(--b-l-medium-line-height, 20px);
    		font-weight: var(--b-l-medium-font-weight, 400);
		}
	}

	.cursor-default {
		cursor: default;
	}

	.body5 {
		font-size: 12px;
		font-weight: normal;
		font-family: 'NotoSansKr-Regular', sans-serif;
	}

	.desktop input {
		width: 288px;
    	font-family: var(--b3-font-family, "NotoSansKr-Regular", sans-serif);
    	font-size: var(--b3-font-size, 16px);
    	font-weight: var(--b3-font-weight, 400);
    	padding: 3px;
	}

	.btn_tur {
		background-color: #458c94;
		color: #fff;
	}

	.sm {
		gap: 10px;
		padding: 8px 20px;
		border-radius: 6px;
	}

	.btn_pink {
		background-color: #f46790;
		color: #fff;
	}

	.btn_white {
		background-color: #fff;
		color: #101010;
		border: 1px solid #b6bac0;
	}

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}

		border-radius: 10px;
    	display: flex;
    	flex-direction: column;
    	gap: 16px;
    	align-items: flex-start;
    	justify-content: flex-start;
    	flex-shrink: 0;
    	position: relative;
    	width: 100%;

		.button {
			display: inline-flex;
			width: min-content;
			justify-content: center;
			align-items: center;

			.overText {
			min-width: 76px;
			font-family: var(--b-l-medium-font-family, "NotoSansKr-Regular", sans-serif);
    		font-size: var(--b-l-medium-font-size, 14px);
    		line-height: var(--b-l-medium-line-height, 20px);
    		font-weight: var(--b-l-medium-font-weight, 400);
			display: inline-block;
			}
		}

		.btn_silver {
			background-color: #f3f4f8;
			color: #4d4d4d;
		}

		.md_round {
			padding: 4px 8px;
			gap: 4px;
			border-radius: 100px;
		}

		.cursor-pointer {
			cursor: pointer;
		}


	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;

		.field-global-001 {
			flex-direction: column;
		}
	}

	.box {
 	   	border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
	}

	.attach_item {
		svg {
    		min-width: 16px;
		}
	}
`;
const desktopStyles = css `
	.attach-file-box-render {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-answer {
		width: 100%;
		display: grid;
		gap: 12px;
		* {
			margin: 0;
		}
	}
	
	.attach-file-column {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-box {
		border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
	}

	.mobile-button-font {
		padding: 12px 10px 12px 10px;
		border-radius: 6px;
		p {
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}
	}

	.btn_light_grey {
		background-color: #83868a;
		color: #fff;
	}

	.xmd {
		gap: 10px;
		padding: 12px 26px;
		border-radius: 6px;
	}

	.button {
		display: inline-flex;
		width: min-content;
		border: none;
		justify-content: center;
		align-items: center;

		p {
			min-width: 76px;
			font-family: var(--b-l-medium-font-family, "NotoSansKr-Regular", sans-serif);
    		font-size: var(--b-l-medium-font-size, 14px);
    		line-height: var(--b-l-medium-line-height, 20px);
    		font-weight: var(--b-l-medium-font-weight, 400);
		}
	}

	.cursor-default {
		cursor: default;
	}

	.body5 {
		font-size: 12px;
		font-weight: normal;
		font-family: 'NotoSansKr-Regular', sans-serif;
	}

	.desktop input {
		width: 288px;
    	font-family: var(--b3-font-family, "NotoSansKr-Regular", sans-serif);
    	font-size: var(--b3-font-size, 16px);
    	font-weight: var(--b3-font-weight, 400);
    	padding: 3px;
	}

	.btn_tur {
		background-color: #458c94;
		color: #fff;
	}

	.sm {
		gap: 10px;
		padding: 8px 20px;
		border-radius: 6px;
	}

	.btn_pink {
		background-color: #f46790;
		color: #fff;
	}

	.btn_white {
		background-color: #fff;
		color: #101010;
		border: 1px solid #b6bac0;
	}

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}

		border-radius: 10px;
    	display: flex;
    	flex-direction: column;
    	gap: 16px;
    	align-items: flex-start;
    	justify-content: flex-start;
    	flex-shrink: 0;
    	position: relative;

		.button {
			display: inline-flex;
			width: min-content;
			justify-content: center;
			align-items: center;

			.overText {
			min-width: 76px;
			font-family: var(--b-l-medium-font-family, "NotoSansKr-Regular", sans-serif);
    		font-size: var(--b-l-medium-font-size, 14px);
    		line-height: var(--b-l-medium-line-height, 20px);
    		font-weight: var(--b-l-medium-font-weight, 400);
			display: inline-block;
			}
		}

		.btn_silver {
			background-color: #f3f4f8;
			color: #4d4d4d;
		}

		.md_round {
			padding: 4px 8px;
			gap: 4px;
			border-radius: 100px;
		}

	.cursor-pointer {
		cursor: pointer;
	}

	

	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;

		.field-global-001 {
			flex-direction: column;
		}
	}

	.box {
 	   	border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
	}

	.attach_item {
		svg {
    		min-width: 16px;
		}
	}

`;
// 첨부 파일 컴포넌트
export const AttachFileAnswer = React.memo(({ attachFileList, setAttachFileList, isRegist, showRegist = false, isAnswer = false, isTeacher, isRequestion, isLive = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const fileRef = React.useRef(null);
    const [count, setCount] = React.useState(0);
    //이미지, pdf, 엑셀, 워드, PPT, 텍스트 파일
    const acceptedFileTypes = 'image/*,application/pdf,.xlsx, .xls, .csv, .ppt, .pptx, .pdf, .doc, .docx, .txt, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    const downloadFile = (url) => {
        const formattedUrl = url.replace(/\s/g, '%20');
        const a = document.createElement('a');
        a.href = formattedUrl; // 다운로드 URL 설정
        a.download = url.split('/').pop() || 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    // (onChange) 첨부 파일 추가
    const onChangeFile = (e, index) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const updatedList = [...attachFileList];
            updatedList[index] = file;
            setAttachFileList(updatedList);
        }
        if (fileRef.current)
            fileRef.current.value = '';
    };
    const addNewBox = () => {
        setAttachFileList([...attachFileList, null]);
    };
    const deleteBox = (index) => {
        const updatedList = [...attachFileList];
        updatedList.splice(index, 1); // Remove the file box
        setAttachFileList(updatedList);
    };
    const deleteAttachFile = (index) => {
        const updatedList = [...attachFileList];
        updatedList.splice(index, 1); // Remove the file box
        setAttachFileList(updatedList);
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const renderAttachFileButton = useMemo(() => (React.createElement(FileInput, { ref: fileRef, id: `dst_attach_file_${count}`, onChange: (e) => onChangeFile(e, count), accept: acceptedFileTypes },
        React.createElement(Button, { className: `btn_tur ${isDesktop || isAnswer ? 'sm' : 'mobile-button-font'}`, text: isMobile
                ? '업로드'
                : isAnswer
                    ? 'supportRoom.regist'
                    : 'common.regist', styles: {
                buttonWrap: isAnswer
                    ? {
                        width: '60px',
                        height: '30px',
                    }
                    : isLive && isMobile
                        ? { width: '100%' }
                        : {
                            height: isTeacher ? undefined : '44px',
                            width: isMobile ? '60px' : '80px',
                        },
            } }))), [isDesktop, isTeacher, isAnswer, attachFileList]);
    const renderAddButton = (index) => (React.createElement(Button, { key: count, className: `btn_white ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: "common.add", styles: {
            buttonWrap: {
                maxWidth: isMobile ? 60 : 80,
                maxHeight: isMobile && 44,
                height: isMobile && 44,
            },
        }, onClick: () => {
            setCount(index + 1);
            addNewBox();
        } }));
    const renderDeleteButton = (index) => (React.createElement(Button, { className: `btn_pink ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: "assignment.delete", styles: {
            buttonWrap: {
                maxWidth: isMobile ? 60 : 80,
                maxHeight: isMobile && 44,
                height: isMobile && 44,
                boxShadow: 'unset',
            },
        }, onClick: () => {
            if (attachFileList.length === 1) {
                setAttachFileList([]);
                setCount(0);
                return;
            }
            deleteAttachFile(index);
            deleteBox(index);
            setCount(count - 1);
        } }));
    const renderAttachFile = (file, index) => (React.createElement(React.Fragment, null,
        React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "attach-file-box", style: {
                width: '100%',
                // minHeight:
                // 	isAnswer || (isTeacher && isTablet)
                // 		? 50
                // 		: isTeacher && !isMobile
                // 		? 100
                // 		: undefined,
                // maxHeight: isTeacher && !isTablet ? 100 : undefined,
                alignContent: 'center',
                alignItems: 'center',
            } },
            React.createElement("div", { style: {
                    width: isAnswer ? '85%' : '100%',
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '10px',
                } }, file ? (React.createElement(Button, { key: index, className: `btn_silver md_round body5 cursor attach_item ${isMobile ? 'mobile-xxs' : ''}`, text: typeof file === 'string'
                    ? file.split('/')[file.split('/').length - 1]
                    : file.name, styles: {
                    buttonWrap: {
                        height: 'fit-content',
                        maxWidth: isMobile ? 160 : 220,
                    },
                }, option: {
                    buttonBeforeIcon: {
                        name: 'file',
                        color: Colors.dark_tur,
                        size: isMobile ? 12 : 20,
                        show: true,
                    },
                    buttonAfterIcon: {
                        name: !showRegist &&
                            !isAnswer &&
                            typeof file === 'string'
                            ? 'file-down'
                            : '',
                        color: Colors.accent,
                        size: !showRegist &&
                            !isAnswer &&
                            typeof file === 'string'
                            ? 16
                            : 12,
                        show: showRegist || isAnswer
                            ? true
                            : typeof file === 'string',
                    },
                }, onClick: !showRegist && typeof file === 'string'
                    ? () => downloadFile(file)
                    : () => { } })) : (React.createElement("p", { className: "body5", style: { color: Colors.light_grey, width: '100%' } }, isTeacher && isRegist
                ? t('assignment.attachFilePlaceholder')
                : t('assignment.noAttachFilePlaceholder'))))),
        showRegist && !file && renderAttachFileButton,
        showRegist && attachFileList.length >= 1 && (attachFileList[index] != null || index !== 0) && renderDeleteButton(index),
        showRegist && index == count && attachFileList.length >= 1 && attachFileList[index] != null && renderAddButton(index)));
    return (React.createElement(AttachFileAnswerStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile },
        React.createElement("div", { className: "field-global-001", style: isRequestion ? { gap: '48px' } : { alignItems: 'flex-start' } },
            React.createElement("div", { className: 'attach-file-answer' },
                React.createElement("div", null,
                    React.createElement("div", { className: "task-label" }, t('assignment.studentAttachFile'))),
                React.createElement("div", { className: 'attach-file-column' }, showRegist ? [...Array(count + 1)].map((_, i) => (React.createElement("div", { key: i, className: 'attach-file-box-render' },
                    !isMobile &&
                        React.createElement(Button, { key: i, className: `btn_light_grey ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: `No.${i + 1}`, styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 44,
                                    boxShadow: 'unset',
                                },
                            } }),
                    renderAttachFile(attachFileList[i], i)))) :
                    // I want to repeat this section 10 times to test overflow
                    [...Array(attachFileList.length)].map((_, i) => (React.createElement("div", { key: i, className: 'attach-file-box-render' },
                        React.createElement(Button, { key: i, className: `btn_light_grey ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: `No.${i + 1}`, styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 44,
                                    boxShadow: 'unset',
                                },
                            }, ellipsis: true }),
                        renderAttachFile(attachFileList[i], i)))))))));
});
