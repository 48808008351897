import { MockTestWebsocket } from '../../modules/server/MockTestWebsocket';
import { toggleLoad } from '../base/loading';
import { checkOccupiedUser, getExternalUserId, getIsTeacher } from '../base/local';
import { objectToQueryString } from '../base/question/functions';
import { getAnswerList, getAssignmentList, getLoadAnswer, getQuestionList, getStatistics, getSubmitStatus, postSaveSubmitAnswer, postSubmitAnswer, } from '../base/question/querys';
import { MiddlewareRegistry } from '../base/redux';
import { SET_ROOM_JOINED, getRoomOption } from '../room';
import { onRefetchTestList, setAnswerList, setIsEnterRoom, setNotSubmitUser, setOmrFrequency, setQuestionList, setStatisticsList, setSubmitList, setTestBackground, setTestList, } from './action';
import { ON_ENTER_TEST_ROOM, ON_REFETCH_TEST_LIST, SET_FOCUS_USER } from './actionTypes';
import { getFocusTestId, getOmrFrequencyByTestId, getStatisticsOption } from './functions';
const getSubmitStatusAPI = async (test_id, member_id, statisticsOption) => {
    // 제출 현황
    const submitStatusResponse = await getSubmitStatus({
        id: test_id,
        props: { member_id, ...statisticsOption },
    });
    APP.store.dispatch(setSubmitList(test_id, submitStatusResponse.result));
};
const initTest = async (store, roomId, isOnlyTestMode) => {
    const queryString = objectToQueryString({
        conference_id: roomId,
        // ...(isTeacher ? {} : { status: ['open', 'closed'] }),
    });
    let testBackgroundUrl = undefined;
    let testItem = undefined;
    let testList = [];
    let testStatistics = [];
    let testSubmitStatus = [];
    // isOnlyTestMode일 경우 conference 정보를 가져오는 로직
    if (isOnlyTestMode) {
        const conferenceBackAPI = `/api/mimac/v1/conferences?id=${roomId}&with_more_info=true`;
        const response = await fetch(conferenceBackAPI);
        const result = await response.json();
        const { total_count, items } = result.result;
        if (total_count > 0) {
            const item = items[0];
            if (item.type === 'mock_test') {
                testBackgroundUrl = item.background;
            }
        }
        else {
            return;
        }
    }
    // Assginment 리스트 가져오기
    const assignmentResponse = await getAssignmentList(queryString);
    const { total_count, items } = assignmentResponse.result;
    if (total_count === 0) {
        return { testBackgroundUrl, testItem, testList, testStatistics, testSubmitStatus };
    }
    const member_id = getExternalUserId(store.getState);
    // 모든 비동기 작업을 병렬로 실행하기 위한 프로미스 배열
    const promises = items.map(async (item) => {
        // 설문형일 경우 설문 빈도 조회
        if (item.type === '설문형') {
            // 설문형에 대한 추가 로직
        }
        // 파일첨부형일 경우의 처리
        else if (item.status === '파일첨부형') {
            // 필요한 경우 이 블록에 추가 작업을 구현
        }
        else {
            if (!getIsTeacher(store.getState)) {
                const loadAnswerResponse = await getLoadAnswer({
                    id: item.id,
                    props: {
                        member_id,
                    },
                });
                const { selected_item, answers } = loadAnswerResponse.result;
                store.dispatch(setOmrFrequency(item.id, {
                    server: { subject_item: selected_item, answer: answers },
                }));
            }
        }
        const questionQueryString = objectToQueryString({
            test_id: item.id,
            orderby: ["IF(subject_item='공통',0,1)", 'subject_item', 'number'],
        });
        const questionListResponse = await getQuestionList(questionQueryString);
        const { total_count: questionTotalCount, items: questionItems } = questionListResponse.result;
        if (questionTotalCount > 0) {
            store.dispatch(setQuestionList(item.id, questionItems));
        }
        // 통계
        const statisticsQueryString = objectToQueryString({ test_id: item.id });
        const statisticsResponse = await getStatistics({ test_id: item.id, queryString: statisticsQueryString });
        store.dispatch(setStatisticsList(item.id, statisticsResponse.result));
        // 제출 현황
        const statisticsOption = getStatisticsOption(store.getState);
        const isTeacher = getIsTeacher(store.getState);
        getSubmitStatusAPI(item.id, isTeacher ? '' : member_id || '', statisticsOption);
    });
    // 모든 비동기 작업을 기다림
    await Promise.all(promises);
    testItem = isOnlyTestMode ? items[0] : items.find((i) => i.status === 'open');
    testList = items;
    store.dispatch(setTestBackground(testBackgroundUrl));
    store.dispatch(setTestList(testList));
    return testList;
};
const handlerMessage = async (data) => {
    switch (data.class) {
        case 'Room.Member.Session.Add':
            // data.status === 200 && setJoin(true);
            break;
        case 'Meeting.Meeting.Closed':
        case 'MIMAC.Conference.Finished':
            APP.store.dispatch(setIsEnterRoom(false));
            window.close();
            break;
        case 'Room.Member.Session.Remove':
            APP.store.dispatch(setIsEnterRoom(false));
            window.close();
            break;
        case 'Event.Room.Member.Kicked':
            alert('같은 아이디로 접속되어 퇴장 되었습니다 아이디를 확인해 주세요');
            APP.store.dispatch(setIsEnterRoom(false));
            break;
        case 'Meeting.Meeting.Signaled':
            const { payload } = data.payload;
            switch (payload.class) {
                case 'MIMAC.Test.Started':
                case 'MIMAC.Test.Submitted':
                    APP.store.dispatch(onRefetchTestList());
                    APP.store.dispatch(toggleLoad(false));
                    break;
                case 'MIMAC.Test.Finished':
                    APP.store.dispatch(onRefetchTestList());
                    if (getIsTeacher(APP.store.getState)) {
                        APP.store.dispatch(toggleLoad(false));
                        break;
                    }
                    APP.store.dispatch(toggleLoad(true));
                    const test_id = payload?.test_id;
                    const member_id = getExternalUserId(APP.store.getState);
                    try {
                        await getSubmitStatus({
                            id: test_id,
                            props: { member_id },
                        }).then(async (response) => {
                            const { total_count, items } = response.result;
                            if (total_count > 0) {
                                const item = items.find((i) => i.member_id === member_id);
                                if (item.not_submitted) {
                                    const omrFrequency = getOmrFrequencyByTestId(APP.store.getState(), test_id);
                                    await postSubmitAnswer({
                                        id: test_id,
                                        props: 
                                        // selected_item: omrFrequency.subject_item,
                                        omrFrequency.answer,
                                    });
                                }
                            }
                        });
                    }
                    catch (error) {
                    }
                    finally {
                        APP.store.dispatch(toggleLoad(false));
                    }
                    break;
            }
            break;
    }
};
let disconnectWebsocketList = [];
let alreadyDisconnect = false;
export const setDisconnectWebsocketList = (list) => {
    disconnectWebsocketList = list;
};
export const disconnectWebsocket = async (e) => {
    if (alreadyDisconnect)
        return;
    alreadyDisconnect = true;
    try {
        disconnectWebsocketList.forEach(async (test_id) => {
            const omrFrequency = getOmrFrequencyByTestId(APP.store.getState(), test_id);
            await postSaveSubmitAnswer({
                id: test_id,
                props: {
                    selected_item: omrFrequency.subject_item,
                    answer: omrFrequency.answer,
                },
            });
        });
    }
    catch (error) {
        console.error({ error });
    }
    finally {
        APP.store.dispatch(toggleLoad(false));
    }
    if (websocket && e) {
        websocket.disconnect();
        websocket = null;
    }
};
let websocket = null;
MiddlewareRegistry.register(store => next => async (action) => {
    const { dispatch } = store;
    const roomId = getRoomOption(store.getState)?.uuid;
    switch (action.type) {
        case 'SET_SUBMIT_OPTION':
            const test_id = getFocusTestId(store.getState);
            getSubmitStatusAPI(test_id, '', action.data);
            break;
        case ON_ENTER_TEST_ROOM:
            dispatch(toggleLoad(true));
            if (!roomId) {
                return;
            }
            await initTest(store, roomId, true);
            const searchResponse = await APP.API.searchRoom({ uuid: roomId }, false, true).then(async (response) => {
                if (response.complete) {
                    const { items } = response.data;
                    if (items && items.length > 0) {
                        const item = items[0];
                        if (item.status === 'closed') {
                            return true;
                        }
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return false;
                }
            });
            if (searchResponse === false) {
                alert('이미 종료된 모의고사입니다.');
                dispatch(setIsEnterRoom(false));
                dispatch(toggleLoad(false));
                return;
            }
            if (websocket) {
                websocket.disconnect();
                websocket = null;
            }
            const checkResponse = await checkOccupiedUser();
            if (checkResponse) {
                if (!checkResponse.complete) {
                    alert(checkResponse.data);
                    return;
                }
                websocket = new MockTestWebsocket(disconnectWebsocket);
                websocket
                    .connect(handlerMessage)
                    .then(() => {
                    store.dispatch(setIsEnterRoom(true));
                })
                    .catch(err => {
                    console.log({ err });
                    websocket.disconnect();
                    websocket = null;
                })
                    .finally(() => {
                    dispatch(toggleLoad(false));
                });
            }
            break;
        case SET_ROOM_JOINED:
            if (action.joined) {
                if (!roomId) {
                    store.dispatch(setTestBackground(undefined));
                    return;
                }
                await initTest(store, roomId, false);
                dispatch(setIsEnterRoom(true));
                alreadyDisconnect = false;
            }
            else {
                await disconnectWebsocket(undefined);
            }
            break;
        case ON_REFETCH_TEST_LIST:
            dispatch(toggleLoad(true));
            if (!roomId) {
                dispatch(setIsEnterRoom(false));
                return;
            }
            await initTest(store, roomId, false);
            dispatch(toggleLoad(false));
            break;
        case SET_FOCUS_USER:
            dispatch(toggleLoad(true));
            try {
                const selectedTestId = getFocusTestId(store.getState());
                const member_id = action?.user?.id;
                // 미제출 사용자 여부 파악
                if (member_id) {
                    await getSubmitStatus({
                        id: selectedTestId,
                        props: { member_id },
                    }).then(async (response) => {
                        const { total_count, items } = response.result;
                        if (total_count === 0) {
                            store.dispatch(setNotSubmitUser(undefined));
                            return next(action);
                        }
                        else {
                            const item = items.find((i) => i.member_id === member_id);
                            if (item.not_submitted === false) {
                                const answerQueryString = objectToQueryString({
                                    test_id: selectedTestId,
                                    from: member_id,
                                    orderby: 'value',
                                });
                                await getAnswerList(answerQueryString).then((answerResponse) => {
                                    let answerList = [];
                                    if (answerResponse) {
                                        const { total_count, items } = answerResponse.result;
                                        answerList = items;
                                    }
                                    store.dispatch(setAnswerList(answerList));
                                });
                                store.dispatch(setNotSubmitUser(item));
                            }
                            else {
                                store.dispatch(setNotSubmitUser(undefined));
                                return next(action);
                            }
                        }
                    });
                }
            }
            catch (error) {
            }
            finally {
                dispatch(toggleLoad(false));
            }
            break;
        case 'SET_IS_ENTER_ROOM':
            if (!action.isEnterRoom) {
                window.removeEventListener('beforeunload', disconnectWebsocket);
                if (websocket) {
                    websocket.disconnect();
                    websocket = null;
                }
                await disconnectWebsocket(undefined);
            }
            else {
                window.addEventListener('beforeunload', disconnectWebsocket);
            }
            break;
        case 'SET_OMR_TEMP_SAVE':
            if (getIsTeacher(store.getState))
                return next(action);
            const member_id = getExternalUserId(store.getState);
            const loadAnswerResponse = await getLoadAnswer({
                id: action.testId,
                props: {
                    member_id,
                },
            });
            const { selected_item, answers } = loadAnswerResponse.result;
            store.dispatch(setOmrFrequency(action.testId, {
                server: { subject_item: selected_item, answer: answers },
            }));
            break;
    }
    return next(action);
});
