import { useEffect } from 'react';
const BackButtonAlert = () => {
    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            const userConfirmed = window.confirm("변경된 내용이 저장되지 않을 수 있습니다. 정말 떠나시겠습니까?");
            if (userConfirmed) {
                window.history.go(-1);
            }
            else {
                window.history.pushState(null, document.title, window.location.href);
            }
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);
    return null;
};
export default BackButtonAlert;
