import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../app/GlobalStateProvider';
import { getMergeOmrFrequency, getSelectedSubjectListAndSelectSubject } from '../../../test';
import { setOmrFrequency, setOmrTempSave } from '../../../test/action';
import { Button, CheckBox } from '../../common';
import { toggleLoad } from '../../loading';
import { postSaveSubmitAnswer, postSubmitAnswer } from '../querys';
import { DetailQuestionTable } from './DetailQuestionTable';
// @ts-ignore
const DetailQuestionStyled = styled.div `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	.checkbox {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		.checkbox-item {
			margin: 0;
		}
	}

	.fext-field {
		height: 100%;
	}

	.task-label {
		width: 100%;
	}

	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		overflow: hidden;

		input {
			width: 100%;
			outline: none;
			border: none;
			padding: 0;
			text-decoration: none;
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		/* .table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		} */

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
const desktopStyles = css `
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.scroll-content {
			margin: 0;
			width: 100%;
			overflow: auto;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
`;
export const DetailQuestionContainer = React.memo(({ subject, test_id }) => {
    const dispatch = useDispatch();
    const { isMobile } = useGlobalState();
    const [submitQuestionIds, setSubmitQuestionIds] = useState([]);
    // omr 서버 임시저장  & omr 클라이언트 임시저장
    const omrFrequency = useSelector((state) => getMergeOmrFrequency(state), shallowEqual);
    const { selectedSubjectList, selectedSubject, isChoice } = useSelector((state) => getSelectedSubjectListAndSelectSubject(state), shallowEqual);
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const saveSubmitAnswerMutation = useMutation(postSaveSubmitAnswer);
    // (action) 저장
    const onSubmit = async () => {
        if (!test_id) {
            APP.UI.alertMessage('테스트를 선택해주세요');
            return;
        }
        try {
            const missingQuestionIds = submitQuestionIds
                .filter(submitItem => {
                return !omrFrequency.answer.some(answer => answer.question_id === submitItem.id);
            })
                .map(submitItem => submitItem.index); // 존재하지 않는 항목의 index 값을 추출
            if (missingQuestionIds.length > 0) {
                // 없는 ID들을 문자열로 변환하여 경고 메시지 표시
                const missingQuestionsString = missingQuestionIds.join(', ');
                APP.UI.alertMessage(`${missingQuestionsString}번 항목에 답변하지 않았습니다.`);
                return;
            }
            else {
                dispatch(toggleLoad(true));
                const myAnswer = omrFrequency.answer.filter(answer => {
                    return submitQuestionIds.some(question => question.id === answer.question_id);
                });
                await submitAnswerMutation.mutateAsync({
                    id: test_id,
                    props: myAnswer,
                });
            }
        }
        catch (error) {
            // 에러 메시지 처리
            const errorMessage = error.split('Error: ')[1];
            APP.UI.alertMessage(errorMessage);
            dispatch(toggleLoad(false));
        }
        finally {
            dispatch(setOmrTempSave(test_id));
        }
    };
    // (action) 임시저장
    const onPrevSubmit = async () => {
        if (!test_id) {
            APP.UI.alertMessage('테스트를 선택해주세요');
            return;
        }
        try {
            await saveSubmitAnswerMutation.mutateAsync({
                id: test_id,
                props: {
                    selected_item: omrFrequency.subject_item,
                    answer: omrFrequency.answer,
                },
            });
            APP.UI.alertMessage('답변이 임시 저장되었습니다.');
        }
        catch (error) {
            try {
                const errorMessage = error.split('Error: ')[1];
                // Handle error
                APP.UI.alertMessage(errorMessage);
            }
            catch (error) {
                APP.UI.alertMessage('답변을 제출할 수 없습니다.');
            }
        }
        finally {
            dispatch(setOmrTempSave(test_id));
        }
    };
    // subject_item 선택 여부에 따른 렌더링
    if (!isChoice && selectedSubjectList.length > 1) {
        const checkList = selectedSubjectList.map(item => ({
            id: item,
            title: item,
            checked: item === selectedSubject ? true : false,
        }));
        return (React.createElement(DetailQuestionStyled, { "$isMobile": isMobile, className: "field-global-001 detail-question-wrap test-area-body" },
            selectedSubjectList && selectedSubjectList.length > 0 && (React.createElement(CheckBox, { list: checkList, isRadioButton: true, handlerCheck: (id, checked) => {
                    if (id === selectedSubject)
                        return;
                    dispatch(setOmrFrequency(test_id, {
                        client: {
                            subject_item: id,
                            answer: [],
                        },
                    }));
                } })),
            React.createElement(Button, { className: "btn btn-black btn-right", text: "\uD655\uC778", onClick: () => {
                    onPrevSubmit();
                } })));
    }
    return (React.createElement(DetailQuestionStyled, { "$isMobile": isMobile, className: "field-global-001 detail-question-wrap test-area-body" },
        React.createElement(DetailQuestionTable, { test_id: test_id, subject: subject, selectedSubjectList: selectedSubjectList, setSubmitQuestionIds: setSubmitQuestionIds }),
        React.createElement("div", { className: "btn-wrap-container" },
            React.createElement(Button, { className: "btn btn-error", text: "\uC784\uC2DC \uC800\uC7A5", onClick: () => {
                    onPrevSubmit();
                } }),
            React.createElement(Button, { className: "btn btn-black", text: "\uC81C\uCD9C", onClick: () => {
                    onSubmit();
                } }))));
});
