import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { DropDown, InputElement } from '../common';
import { Colors } from '../common/Colors';
import { SurveyCheckBox } from './SurveyCheckBox';
import { DEFAULT_SURVEY_DROP, SURVEY_DROP_LIST, SURVEY_TYPE } from './SurveyTypes';
// SurveyItem 컴포넌트
const SurveyItem = React.memo(({ index, item, isSubmit, isRegist, setChangeSurveyData }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [data, setData] = React.useState();
    const titleRef = React.useRef(null);
    // 드롭다운 값 상태
    const [dropValue, setDropValue] = React.useState(DEFAULT_SURVEY_DROP);
    useEffect(() => {
        const newDropValue = SURVEY_DROP_LIST.find(drop => drop.id === item.type) || DEFAULT_SURVEY_DROP;
        setDropValue(newDropValue);
        if (titleRef.current)
            titleRef.current.value = item.title || '';
        setData(item);
        // console.log("surveyItem: ", item);
    }, [item]);
    // 드롭다운 변경 핸들러
    const handleDropChange = (dropItem) => {
        setDropValue(dropItem);
        // @ts-ignore
        setData(prevData => ({
            ...prevData,
            data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE ? [] : '',
        }));
        setChangeSurveyData({
            id: item.id,
            type: dropItem.id,
            data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE ? [] : '',
        });
    };
    const renderQuestion = useMemo(() => (React.createElement("div", { className: "survey-title" },
        React.createElement("p", null,
            " Q",
            index + 1,
            ". "),
        isRegist ? (React.createElement(InputElement, { ref: titleRef, className: `survey-input ${isMobile ? '' : 'body4 '}`, type: "text", defaultValue: data?.title, placeholder: t('survey.inputQuestionPlaceholder'), onChange: value => {
                // @ts-ignore
                setData({ ...data, title: value });
                setChangeSurveyData({
                    // @ts-ignore
                    id: data.id,
                    data: value,
                });
            } })) : (React.createElement("p", null, data?.title)))), [index, titleRef, data]);
    const renderType = useMemo(() => (
    /* 드롭다운 */
    React.createElement(DropDown, { className: "dropdown-popup", value: dropValue, list: SURVEY_DROP_LIST, setDropValue: handleDropChange, option: {
            titleIcon: { show: false },
            itemIcon: { show: false },
        }, styles: {
            dropWrap: { width: isMobile ? '100%' : 85 },
            titleWrap: { backgroundColor: Colors.transparent, height: '100%' },
        }, isDisable: !isRegist })), [dropValue]);
    const inputRef = React.useRef(null);
    React.useEffect(() => {
        if (!item)
            return;
        if (inputRef.current) {
            inputRef.current.value = item.data;
        }
    }, [item]);
    if (!data)
        return null;
    return (React.createElement("div", { className: "survey body4", key: index },
        React.createElement("div", { className: "survey-header" }, isMobile ? (React.createElement(React.Fragment, null,
            renderType,
            renderQuestion)) : (React.createElement(React.Fragment, null,
            renderQuestion,
            renderType))),
        React.createElement("div", { className: "survey-body" },
            React.createElement("div", { className: "survey-content" }, dropValue.id === SURVEY_TYPE.CHECKBOX || dropValue.id === SURVEY_TYPE.OBJECTIVE ? (React.createElement(SurveyCheckBox, { key: data.id, id: data.id, isRadioButton: dropValue.id === SURVEY_TYPE.OBJECTIVE, defaultList: item.data, isRegist: isRegist, isSubmit: isSubmit, setChangeSurveyData: setChangeSurveyData })) : (React.createElement(InputElement, { ref: inputRef, className: "suvey-input", type: "text", textarea: dropValue.id === SURVEY_TYPE.LONG_ANSWER ? true : false, defaultValue: isRegist ? item.data : isSubmit ? item.data : '', onChange: value => {
                    setData({ ...data, data: value });
                    setChangeSurveyData({
                        id: data.id,
                        data: value,
                    });
                }, isDisable: isSubmit, max: 500 }))))));
});
export default SurveyItem;
