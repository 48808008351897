import _ from 'lodash';
import { createSelector } from 'reselect';
import { getIsTeacher } from '../base/local';
import { toState } from '../base/redux';
export const getSurveyFrequency = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].surveyFrequency;
};
export const getSubmitUser = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].selectUserSubmit;
};
export const getSubmitUserByTestIdAndMemberId = (stateful, test_id, member_id) => {
    if (!test_id)
        return false;
    if (!member_id)
        return false;
    const submitStatus = getTestSubmitStatusByTestId(stateful, test_id);
    return submitStatus.items?.find((member) => member.member_id === member_id) || [];
};
export const getSubmitUserScoreByTestId = (stateful, test_id) => {
    if (!test_id)
        return 0;
    const user = getSubmitUser(stateful);
    const selectedMember = getFocusUser(stateful);
    if (!user && !selectedMember) {
        const submitStatus = getTestSubmitStatusByTestId(stateful, test_id);
        return submitStatus?.total_score || 0;
    }
    if (!selectedMember) {
        return 0;
    }
    else {
        const submit = getSubmitUser(stateful);
        return submit?.score === -1 ? 0 : submit?.score || 0;
    }
};
export const getIsSubmitUser = (stateful) => {
    const isTeacher = getIsTeacher(stateful);
    const user = getSubmitUser(stateful);
    if (isTeacher) {
        const selectUser = getFocusUser(stateful);
        return selectUser && !user ? true : false;
    }
    return user === undefined ? true : user.not_submitted;
};
export const getSubjectName = (stateful) => {
    const item = getTestItemByTestId(stateful);
    return item?.type || '';
};
export const getSubmitData = (questionList, answerList) => {
    let mergedList = [];
    // answerList를 Map으로 변환하여 빠른 탐색 가능하도록 변경
    const answerMap = new Map(answerList.map(answer => [answer.question_id, answer]));
    // questionList와 answerMap을 병합
    mergedList = questionList
        .map((question) => {
        if (answerMap.size === 0) {
            return {
                ...question,
                question_id: question.id, // explicitly setting question_id from question
                value: '-',
                from: '',
                from_name: '',
                correction: '',
            };
        }
        const answer = answerMap.get(question.id);
        if (answer) {
            return {
                ...question,
                ...answer,
            };
        }
    })
        .filter(Boolean);
    return mergedList;
};
export const getQuestionList = (stateful, test_id) => {
    const id = test_id || getFocusTestId(stateful);
    const state = toState(stateful);
    const list = state['features/test'].questionList;
    return list.get(id) || [];
};
export const getOmrFrequency = (stateful, test_id) => {
    const id = test_id || getFocusTestId(stateful);
    const state = toState(stateful);
    const omrFrequency = state['features/test'].omrFrequency;
    return (omrFrequency.get(id) || { server: { subject_item: '', answer: [] }, client: { subject_item: '', answer: [] } });
};
export const getStatisticsOption = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].staticsOptions;
};
export const getAnswerList = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].answerList;
};
export const getTestNoticeMessage = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].testNoticeMessage;
};
export const getTestList = (stateful) => {
    const state = toState(stateful);
    const isTeacher = getIsTeacher(stateful);
    const list = state['features/test'].list;
    if (isTeacher)
        return list;
    else
        return list.filter((item) => item.status === 'open' || item.status === 'closed') || [];
};
export const getTestItemByTestId = (stateful, test_id) => {
    const id = test_id || getFocusTestId(stateful);
    if (!id) {
        const list = getTestList(stateful);
        return list.find((testItem) => testItem.status === 'open');
    }
    const list = getTestList(stateful);
    return id && id !== '' ? list.find((item) => item.id === id) : undefined;
};
export const getTestStatistics = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].statistics;
};
export const getTestStatisticsByTestId = (stateful, testId) => {
    const data = getTestStatistics(stateful);
    return data.find((item) => item.test_id === testId);
};
export const getTestSubmitStatus = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].submitStatus;
};
export const getTestSubmitStatusByTestId = (stateful, testId) => {
    const submitStatus = getTestSubmitStatus(stateful);
    return submitStatus.get(testId);
};
export const getIsEnterTestRoom = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].isEnterRoom;
};
export const getFocusTestId = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].selectTestId;
};
export const getFocusUser = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].selectUser;
};
export const getTestBackgroundUrl = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].backgroundUrl;
};
export const getIsTestOpenModal = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].openModal;
};
export const getIsTestFoldModal = (stateful) => {
    const state = toState(stateful);
    return state['features/test'].foldModal;
};
export const getSurveyData = (testItem, questionList, submittedAnswers) => {
    const combinedArray = questionList.map((i) => {
        const question = testItem.survey_questions[i.id];
        const questionAnswers = submittedAnswers.filter(answer => answer.question_id === i.id);
        return {
            id: i.id,
            type: i.type,
            title: question ? question.title : null,
            data: i.type === 'choice' || i.type === 'checkbox'
                ? question.options.map((option, optionIndex) => ({
                    id: `${i.id}-${optionIndex}`,
                    title: option,
                    checked: questionAnswers.some(answer => answer.value === option),
                }))
                : i.type === 'short' || i.type === 'long'
                    ? questionAnswers.length > 0
                        ? questionAnswers[0].value
                        : ''
                    : '',
        };
    });
    // console.log('combinedArray:', combinedArray);
    return combinedArray;
};
export const getSubmittedAttachmentData = (stateful) => {
    const state = toState(stateful);
    const answerData = state['features/test'].answerList;
    const valuesArray = answerData.map(item => item?.value);
    return valuesArray;
};
// 상태에서 questionList와 frequenciesList를 가져오는 셀렉터
const getQuestionListSelector = (stateful) => getQuestionList(stateful);
const getOmrFrequencySelector = (stateful) => getOmrFrequency(stateful);
// 선택된 과목 리스트와 선택한 과목을 반환하는 셀렉터
export const getSelectedSubjectListAndSelectSubject = createSelector([getTestItemByTestId, getQuestionListSelector, getOmrFrequencySelector], (testItem, questionList, frequenciesList) => {
    if (testItem && testItem.subject && ['math', 'korean'].includes(testItem.subject)) {
        // subject_item이 선택되어 있는지 확인하고 중복 제거
        const subjectItems = Array.from(new Set(questionList.map(item => item.subject_item).filter(item => item && !item.includes('공통'))));
        // 현재 선택된 과목 리스트가 있는지 조회
        const selectedSubject = frequenciesList.server.subject_item;
        if (subjectItems.length <= 1) {
            return {
                selectedSubjectList: [],
                selectedSubject: '',
                isChoice: true,
            };
        }
        else {
            const subject = selectedSubject === '' ? frequenciesList.client.subject_item : selectedSubject;
            const selectedSubjectList = testItem.subject === 'math'
                ? subjectItems.sort((a, b) => {
                    const subjectOrder = ['선택 - 미적분', '선택 - 확률과 통계', '선택 - 기하'];
                    return subjectOrder.indexOf(a) - subjectOrder.indexOf(b);
                })
                : subjectItems;
            return {
                selectedSubjectList: selectedSubjectList,
                selectedSubject: subject === '' ? undefined : subject,
                isChoice: selectedSubject === '' ? false : true,
            };
        }
    }
    else {
        return {
            selectedSubjectList: [],
            selectedSubject: '',
            isChoice: true,
        };
    }
});
export const getMergeOmrFrequency = createSelector([getOmrFrequencySelector], frequenciesList => {
    const cloneFrequenciesList = _.cloneDeep(frequenciesList);
    return {
        subject_item: cloneFrequenciesList.server.subject_item === ''
            ? cloneFrequenciesList.client.subject_item
            : cloneFrequenciesList.server.subject_item,
        answer: _.merge(cloneFrequenciesList.server.answer, cloneFrequenciesList.client.answer),
    };
});
export const getOmrFrequencyByTestId = (stateful, test_id) => {
    const omrFrequency = getOmrFrequency(stateful, test_id);
    const cloneFrequenciesList = _.cloneDeep(omrFrequency);
    return {
        subject_item: cloneFrequenciesList.server.subject_item === ''
            ? cloneFrequenciesList.client.subject_item
            : cloneFrequenciesList.server.subject_item,
        answer: _.merge(cloneFrequenciesList.server.answer, cloneFrequenciesList.client.answer),
    };
};
