import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import throttle from 'lodash/throttle';
import { TableOption } from '../option';
import InfinityScroll from './InfinityScroll';
import Paging from './Paging';
import RenderBody from './TableItem';
import { CheckBoxElement } from './CheckBox';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../ui';
const TableHeader = React.memo(({ allChecked, titleFontClass, styles, showCheckBox, showIndex, headTitleList, sort, desc, handlerUpdateSort, handlerCheck, tableOption, }) => {
    const { t } = useTranslation();
    const renderHeadItem = useMemo(() => {
        return headTitleList.map((item, index) => {
            const isSorted = item.sort && item.title === sort;
            const rotation = isSorted ? (desc ? 'rotate(90deg)' : 'rotate(270deg)') : 'rotate(90deg)';
            return (React.createElement("div", { key: item.title, className: "table-header-item overText", style: {
                    ...styles?.headItem,
                    cursor: item.sort ? 'pointer' : 'default',
                    flex: item.space,
                    flexDirection: item.children ? 'column' : 'row',
                    ...(item.maxWidth && { maxWidth: item.maxWidth }),
                    ...(item.minWidth && { minWidth: item.minWidth }),
                }, onClick: item.sort ? () => handlerUpdateSort(item.title) : undefined },
                React.createElement("div", { className: "text" },
                    React.createElement("p", { className: "overText", style: styles?.headText }, t(`table.${item.title}`)),
                    item.sort && (React.createElement(Icon, { style: {
                            transform: rotation,
                        }, icon: "arrow", size: tableOption.sortIcon.size, stroke: tableOption.sortIcon.color }))),
                item.children && (React.createElement("div", { className: "table-header-child", style: { width: '100%' } }, item.children.map(i => (React.createElement("p", { key: i, style: { flex: 1 } }, i)))))));
        });
    }, [headTitleList, sort, desc]);
    return (React.createElement("div", { className: `table-header ${titleFontClass}`, style: styles?.headWrapper },
        showCheckBox && (React.createElement("div", { className: "table-header-item", style: { ...styles?.headItem, minWidth: 30 } },
            React.createElement(CheckBoxElement, { id: "all", iconSize: 22, checked: allChecked, handlerCheck: handlerCheck }))),
        showIndex && (React.createElement("div", { className: "table-header-item", style: { ...styles?.headItem, minWidth: 30 } },
            React.createElement("p", { style: styles?.headText },
                " ",
                t('table.no')))),
        renderHeadItem));
});
// 컴포넌트 선언
export const Table = React.memo(({ className, initPageSize = 10, titleFontClass = 'body3', showIndex = false, showCheckBox = false, isInifinite, data = [], showCount = 10, defaultCheckList, page, totalCount, sortDefault, headTitleList, renderTitle, renderFixItem, renderItem, renderMoreItem, renderEmpty, option, styles, handlerMoreData, handlerUpdateList, handlerCheckList, forceShowheader = false, }) => {
    // 상태 정의
    const [sort, setSort] = useState(sortDefault);
    const [desc, setDesc] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [tableList, setTableList] = useState(data);
    const isShowMore = useMemo(() => tableList?.length < totalCount, [tableList, totalCount]);
    const tableOption = useMemo(() => _.merge({}, TableOption, option), [option]);
    const handlerScroll = throttle(() => {
        APP.eventManager.publish('scroll');
    }, 1000, { leading: true, trailing: false });
    // 정렬 업데이트 핸들러
    const handlerUpdateSort = (changeSort) => {
        if (sort === changeSort) {
            setDesc(!desc);
        }
        else {
            setSort(changeSort);
            setDesc(false);
        }
    };
    useEffect(() => {
        if (!isInifinite) {
            setCheckedList([]);
        }
    }, [page]);
    useEffect(() => {
        handlerCheckList && handlerCheckList(checkedList);
    }, [checkedList]);
    useEffect(() => {
        if (defaultCheckList && defaultCheckList.length > 0)
            setCheckedList(defaultCheckList);
    }, [defaultCheckList]);
    // 데이터 변경 감지 및 리스트 업데이트
    useEffect(() => {
        setTableList(data);
        if (isInifinite && allChecked) {
            setCheckedList(data.map(item => item.id));
        }
    }, [data]);
    // 정렬 변경 감지 및 리스트 업데이트
    useEffect(() => {
        handlerUpdateList && handlerUpdateList(sort, desc);
    }, [sort, desc]);
    useEffect(() => {
        if (tableList.length > 0 && checkedList.length === tableList.length)
            setAllChecked(true);
        else
            setAllChecked(false);
    }, [defaultCheckList, checkedList, tableList]);
    // 체크박스 핸들러
    const handlerCheck = (id, checked) => {
        if (id === 'all') {
            setAllChecked(checked);
            if (checked)
                setCheckedList(tableList.map(item => item.id));
            else
                setCheckedList([]);
        }
        else {
            setCheckedList(checked ? [...checkedList, id] : checkedList.filter(item => item !== id));
        }
    };
    // 테이블 컴포넌트 반환
    return (React.createElement(React.Fragment, null,
        renderTitle,
        React.createElement("div", { className: `table ${className}`, style: { width: '100%', height: '100%' } },
            forceShowheader && (React.createElement(TableHeader, { allChecked: allChecked, titleFontClass: titleFontClass, styles: styles, showCheckBox: showCheckBox, showIndex: showIndex, headTitleList: headTitleList, sort: sort, desc: desc, handlerUpdateSort: handlerUpdateSort, handlerCheck: handlerCheck, tableOption: tableOption })),
            renderFixItem,
            React.createElement("div", { className: "scroll-content", style: styles?.scrollContent, onScroll: handlerScroll },
                renderEmpty || (React.createElement(RenderBody, { tableList: tableList, showCheckBox: showCheckBox, showIndex: showIndex, styles: styles, renderItem: renderItem, checkedList: checkedList, handlerCheck: handlerCheck, page: page, isInifinite: isInifinite, renderMoreItem: renderMoreItem, showCount: showCount })),
                isShowMore && isInifinite && (React.createElement(InfinityScroll, { page: page, data: tableList, totalCount: totalCount, handlerMoreData: handlerMoreData }))),
            tableList?.length > 0 && !isInifinite && (React.createElement(Paging, { showCount: showCount, page: page, data: tableList, initPageSize: initPageSize, totalCount: totalCount, handlerMoreData: handlerMoreData })))));
});
