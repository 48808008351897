import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from '../app/GlobalStateProvider';
import { setLayoutContentStyle, setLayoutType } from './actions';
import { LayoutScreen } from './components';
import { LayoutContainerStyled } from './components/layout/styles.web';
import { HORIZONTAL_MODE, LAYOUT_TYPE, VIDEO_MODE_LIST } from './constants';
import { getCurrentMode } from './functions';
const LayoutContainer = React.memo(() => {
    const layoutRef = useRef(null);
    const dispatch = useDispatch();
    const mode = useSelector((state) => getCurrentMode(state));
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [layoutStyle, setLayoutStyle] = useState({ width: 0, height: 0 });
    useEffect(() => {
        if (isMobile) {
            dispatch(setLayoutType(LAYOUT_TYPE.mobile));
        }
        else if (isTablet) {
            dispatch(setLayoutType(LAYOUT_TYPE.tablet));
        }
        else if (isDesktop) {
            dispatch(setLayoutType(LAYOUT_TYPE.desktop));
        }
    }, [isMobile, isTablet, isDesktop]);
    useEffect(() => {
        dispatch(setLayoutContentStyle(layoutStyle.width, layoutStyle.height));
    }, [layoutStyle]);
    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (!width && !height)
                    return;
                setLayoutStyle({ width, height });
            }
        });
        const fullElement = layoutRef.current;
        if (fullElement) {
            if (fullElement) {
                resizeObserver.observe(fullElement);
            }
        }
        return () => {
            if (resizeObserver && layoutRef.current) {
                resizeObserver.unobserve(layoutRef.current);
            }
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(LayoutContainerStyled, { name: APP.config.name, ref: layoutRef, isGrid: VIDEO_MODE_LIST.includes(mode), horizontal: HORIZONTAL_MODE.includes(mode) },
            React.createElement(LayoutScreen, { mode: mode, layoutStyle: layoutStyle }))));
});
export default LayoutContainer;
