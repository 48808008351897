import i18next from 'i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default {
	alertMessage: (message, props, isAlert, option) => {
		if (isAlert) {
			message && window.alert(i18next.t(message, props));
			return;
		}

		const isTestMode = location.pathname.includes('test');
		message &&
			toast(i18next.t(message, props), {
				position: isTestMode ? 'bottom-center' : 'top-right',
				theme: isTestMode ? 'dark' : 'light',
				autoClose: isTestMode ? 2000 : 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,

				...option,
			});
	},
	confirmMessage: message => {
		if (message) {
			return confirm(i18next.t(message));
		}

		return false;
	},
};
