export const ON_ENTER_TEST_ROOM = 'ON_ENTER_TEST_ROOM';
export const ON_REFETCH_TEST_LIST = 'ON_REFETCH_TEST_LIST';
export const SET_QUESTION_LIST = 'SET_QUESTION_LIST';
export const SET_ANSWER_LIST = 'SET_ANSWER_LIST';
export const SET_STATISCIS_LIST = 'SET_STATISCIS_LIST';
export const SET_SUBMIT_LIST = 'SET_SUBMIT_LIST';
export const SET_TEST_BACKGROUND = 'SET_TEST_BACKGROUND';
export const SET_TEST_LIST = 'SET_TEST_LIST';
export const TOGGLE_TEST_MODAL = 'TOGGLE_TEST_MODAL';
export const TOGGLE_FOLD_MODAL = 'TOGGLE_FOLD_MODAL';
export const SET_FOCUS_TEST_ID = 'SET_FOCUS_TEST_ID';
export const SET_FOCUS_USER = 'SET_FOCUS_USER';
export const SET_NOT_SUBMIT_USER = 'SET_NOT_SUBMIT_USER';
export const SET_SURVEY_FREQUENCY = 'SET_SURVEY_FREQUENCY';
export const SET_OMR_FREQUENCY = 'SET_OMR_FREQUENCY';
