import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Management from '../../../modules/server';
import { getPropertyValue } from '../../base/settings';
import { SideBarContainer } from '../../base/sidebar';
import { ClickButton } from '../../base/ui';
import { OnlyTestMode, getIsEnterTestRoom } from '../../test';
import { default as LayoutContainer } from '../../video-layout/LayoutContainer';
import { RoomStyled } from './styles';
let list = new Set();
const Room = React.memo(({ uiOption, isMobile, isTest, renderRoomItem }) => {
    const individualLive = useSelector((state) => getPropertyValue(state, 'individualLive'));
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const isEnterTestRoom = useSelector((state) => getIsEnterTestRoom(state));
    const roomRef = useRef();
    const test = async () => {
        const management = new Management();
        const response = await APP.API.createTempUser();
        if (response.complete) {
            const { auth_name, auth_password } = response.data;
            const result = await APP.API.login(auth_name, auth_password);
            if (result.complete) {
                const { access_token } = result.data;
                management.join(null, null, access_token).then(() => {
                    list.add(management);
                });
            }
        }
    };
    const renderItem = type => {
        const { show, direction, left, middle, right } = uiOption[type];
        const renderHeadItem = (item, components) => {
            return (React.createElement("div", { className: "head_item", key: item }, components));
        };
        const renderHeadOption = () => {
            if (type === 'header' && location.search.includes('test')) {
                return (React.createElement(ClickButton, { className: "test", isText: true, label: 'test', color: "#000", size: 20, onClick: test }));
            }
        };
        if (!show)
            return null;
        return (React.createElement("div", { className: `room_item ${type} ${direction}` },
            left && React.createElement("div", { className: "left" }, renderRoomItem(left, null, renderHeadItem)),
            middle && React.createElement("div", { className: "room_center" }, renderRoomItem(middle, null, renderHeadItem)),
            right && React.createElement("div", { className: "right" }, renderRoomItem(right, null, renderHeadItem)),
            renderHeadOption()));
    };
    return (React.createElement(RoomStyled, { id: "room-container", ref: roomRef, individualLive: individualLive, isMobile: isMobile, isTestMode: isTest, name: APP.config.name },
        isEnterTestRoom && renderItem('header'),
        React.createElement("div", { className: `body ${uiOption.footer.direction}` },
            !isTest ? (React.createElement("div", { className: "layout" },
                React.createElement(LayoutContainer, null),
                !openSide && React.createElement(SideBarContainer, null))) : (React.createElement("div", { className: "layout" },
                React.createElement(OnlyTestMode, null))),
            isEnterTestRoom && renderItem('footer')),
        isEnterTestRoom && renderItem('floating')));
});
export default Room;
