import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { getFocusTestId, getIsSubmitUser, getIsTestFoldModal, getIsTestOpenModal, getTestList } from '../../test';
import { setFocusTestId, toggleTestFold, toggleTestModal } from '../../test/action';
import { Button } from '../common';
import { Dialog } from '../dialog';
import { getIsTeacher } from '../local';
import { DragTest } from './DragTest';
import { QuestionSolve } from './QuestionSolve';
import { SubmissionContainer } from './SubmissionContainer';
import { TestItem } from './TestItem';
import BackButtonAlert from './BackButtonAlert';
export const TestMain = React.memo(() => {
    const dispatch = useDispatch();
    const { isDesktop } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const testList = useSelector((state) => getTestList(state), shallowEqual);
    const selectedTestId = useSelector((state) => getFocusTestId(state));
    const openModal = useSelector((state) => getIsTestOpenModal(state));
    const foldModal = useSelector((state) => getIsTestFoldModal(state));
    // 제출 사용자 및 미제출 사용자 구분
    const notSubmit = useSelector((state) => getIsSubmitUser(state), shallowEqual);
    const onCancelModal = () => {
        const doingTest = testList.find(test => test.status === 'open');
        if (doingTest) {
            dispatch(toggleTestFold());
        }
        else {
            dispatch(toggleTestModal());
        }
    };
    const renderItem = useMemo(() => {
        if (!selectedTestId) {
            if (testList && testList.length > 0) {
                return testList.map(testItem => {
                    return React.createElement(TestItem, { key: testItem.id, i: testItem, notSubmit: notSubmit, isTeacher: isTeacher });
                });
            }
            else {
                return React.createElement("div", null, "\uD14C\uC2A4\uD2B8\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.");
            }
        }
        else {
            if (isTeacher) {
                return React.createElement(SubmissionContainer, { isTest: true, test_id: selectedTestId });
            }
            else {
                return React.createElement(QuestionSolve, { test_id: selectedTestId });
            }
        }
    }, [isTeacher, testList, selectedTestId]);
    const renderPrevious = useMemo(() => {
        if (selectedTestId && (isTeacher ? true : notSubmit)) {
            return (React.createElement(Button, { className: "btn-default", option: {
                    buttonBeforeIcon: {
                        // @ts-ignore
                        icon: 'arrow-left',
                        size: 16,
                        className: 'icon',
                        color: 'var(--color-black)',
                    },
                }, styles: {
                    buttonWrap: {
                        margin: 0,
                    },
                }, text: "common.back", onClick: () => dispatch(setFocusTestId()) }));
        }
    }, [selectedTestId, notSubmit, isTeacher]);
    if (openModal) {
        if (foldModal) {
            return (React.createElement("div", null,
                React.createElement(BackButtonAlert, null),
                React.createElement(DragTest, null)));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(BackButtonAlert, null),
                React.createElement(Dialog, { className: "test", titleKey: "dialog.test", onCancel: onCancelModal },
                    React.createElement("div", { className: `test_container ${!isDesktop ? 'mobile' : 'desktop'}` },
                        renderItem,
                        renderPrevious))));
        }
    }
    else
        return null;
});
