import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../app/GlobalStateProvider';
import DeviceSelection from '../../../device-selection/DeviceSelection2';
import { DualMonitor } from '../../../dualConference';
import RoomContainer from '../../../room/RoomContainer';
import { toggleLoad } from '../../loading';
import { PAGE_TYPE } from '../constants';
const LazyLogin = React.lazy(() => import('../../../account/LoginContainer'));
const LazySignup = React.lazy(() => import('../../../account/SignupContainer'));
const LazyLobby = React.lazy(() => import('../../../lobby/LobbyContainer'));
// const LazyDeviceSelection = React.lazy(() => import('../../../device-selection/DeviceSelection2'));
const LazyNickname = React.lazy(() => import('../../../nickname/NicknameContainer'));
function BaseApp({ page, roomUid }) {
    const { isDesktop } = useGlobalState();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigator = useNavigate();
    useEffect(() => {
        changePage();
        if (page === PAGE_TYPE.LOBBY ||
            page === PAGE_TYPE.ERROR ||
            page === PAGE_TYPE.DUP_LOGIN ||
            page === PAGE_TYPE.TEST_MAIN ||
            page === PAGE_TYPE.LOGIN) {
            dispatch(toggleLoad(false));
        }
    }, [page, roomUid]);
    const changePage = () => {
        if (!page)
            return;
        if ([PAGE_TYPE.MAIN, PAGE_TYPE.NICKNAME, PAGE_TYPE.DEVICE].includes(page) && !roomUid)
            return;
        let path = '';
        let state = PAGE_TYPE.LOBBY;
        switch (page) {
            // case 'test':
            // 	path = `/test`;
            // 	state = 'test';
            // 	break;
            case PAGE_TYPE.MAIN:
                path = `/room/${roomUid}${location.search}`;
                state = PAGE_TYPE.MAIN;
                break;
            case PAGE_TYPE.NICKNAME:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.NICKNAME}`;
                state = PAGE_TYPE.NICKNAME;
                break;
            case PAGE_TYPE.DEVICE:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.DEVICE}`;
                state = PAGE_TYPE.DEVICE;
                break;
            case PAGE_TYPE.ERROR:
                path = `/error${location.search}`;
                state = PAGE_TYPE.ERROR;
                break;
            case PAGE_TYPE.LOGIN:
                path = `/login${location.search}`;
                state = PAGE_TYPE.LOGIN;
                break;
            case PAGE_TYPE.SIGNUP:
                path = `/signup${location.search}`;
                state = PAGE_TYPE.SIGNUP;
                break;
            case PAGE_TYPE.TEST_MAIN:
                path = `/test/${roomUid}${location.search}`;
                state = PAGE_TYPE.TEST_MAIN;
                break;
            case PAGE_TYPE.DUP_LOGIN:
                path = `/dup_login`;
                state = PAGE_TYPE.DUP_LOGIN;
                break;
            default:
                path = `/${location.search}`;
                break;
        }
        navigator(path, { state });
    };
    if (!page)
        return null;
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: "/error", element: React.createElement(LazyLobby, { isErrorPage: true, page: PAGE_TYPE.ERROR }) }),
        React.createElement(Route, { path: "/dup_login", element: React.createElement(LazyLobby, { isErrorPage: true, page: PAGE_TYPE.DUP_LOGIN }) }),
        React.createElement(Route, { path: "/room/*", element: page === PAGE_TYPE.NICKNAME ? (React.createElement(LazyNickname, null)) : page === PAGE_TYPE.DEVICE ? (React.createElement(DeviceSelection, { isDialog: false })) : page === PAGE_TYPE.MAIN ? (React.createElement(RoomContainer, null)) : null }),
        React.createElement(Route, { path: "/test/*", element: page === PAGE_TYPE.TEST_MAIN ? React.createElement(RoomContainer, { isTest: true }) : null }),
        React.createElement(Route, { path: "/login", element: React.createElement(LazyLogin, { isDialog: false, isMobile: !isDesktop }) }),
        React.createElement(Route, { path: "/signup", element: React.createElement(LazySignup, { isDialog: false, isMobile: !isDesktop }) }),
        React.createElement(Route, { path: "/dual/*", element: React.createElement(DualMonitor, null) }),
        React.createElement(Route, { path: "/*", element: React.createElement(LazyLobby, { isErrorPage: false, page: PAGE_TYPE.LOBBY }) })));
}
export default BaseApp;
