import { getExternalUserId, getIsTeacher } from '../base/local';
import { objectToQueryString } from '../base/question/functions';
import { getSurveyFrequency, getTestTime } from '../base/question/querys';
import { ReducerRegistry, StateListenerRegistry } from '../base/redux';
import { SET_ROOM_JOINED } from '../room';
import { setFocusTestId, setFocusUser, setSurveyFrequency, setTestNoticeMessage, toggleTestModal } from './action';
import { SET_ANSWER_LIST, SET_FOCUS_TEST_ID, SET_FOCUS_USER, SET_NOT_SUBMIT_USER, SET_OMR_FREQUENCY, SET_QUESTION_LIST, SET_STATISCIS_LIST, SET_SUBMIT_LIST, SET_SURVEY_FREQUENCY, SET_TEST_BACKGROUND, SET_TEST_LIST, TOGGLE_FOLD_MODAL, TOGGLE_TEST_MODAL, } from './actionTypes';
import { getFocusTestId, getIsEnterTestRoom, getIsTestOpenModal, getSubmitUserByTestIdAndMemberId, getTestItemByTestId, getTestList, } from './functions';
import { disconnectWebsocket, setDisconnectWebsocketList } from './middleware';
const DEFAULT_STATE = {
    list: [],
    questionList: new Map(),
    answerList: [],
    statistics: new Map(),
    submitStatus: new Map(),
    backgroundUrl: '',
    isEnterRoom: false,
    openModal: false, // testModal open 된 상태?
    foldModal: false,
    selectTestId: '',
    selectUser: undefined,
    selectUserSubmit: undefined,
    surveyFrequency: {},
    omrFrequency: new Map(),
    testNoticeMessage: undefined,
    staticsOptions: {
        offset: 0,
        limit: 10,
    },
};
ReducerRegistry.register('features/test', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_NOT_SUBMIT_USER:
            return {
                ...state,
                selectUserSubmit: action.submitted,
            };
        case SET_QUESTION_LIST:
            const updateQuestionList = new Map(state.questionList);
            // testId가 없는 경우, 새로운 항목으로 추가
            updateQuestionList.set(action.testId, action.questionList);
            return {
                ...state,
                questionList: updateQuestionList,
            };
        case SET_ANSWER_LIST:
            return {
                ...state,
                answerList: action.answerList,
            };
        case SET_STATISCIS_LIST:
            const updateStatisticsList = new Map(state.statistics);
            // testId가 없는 경우, 새로운 항목으로 추가
            updateStatisticsList.set(action.testId, action.statisticsList);
            return {
                ...state,
                statistics: updateStatisticsList,
            };
        case SET_SUBMIT_LIST:
            const submitStatusList = new Map(state.submitStatus);
            submitStatusList.set(action.testId, action.submitStatus);
            return {
                ...state,
                submitStatus: submitStatusList,
            };
        case SET_TEST_LIST:
            return {
                ...state,
                list: action.items,
            };
        case SET_TEST_BACKGROUND:
            return {
                ...state,
                backgroundUrl: action.backgroundUrl !== undefined ? action.backgroundUrl : state.backgroundUrl,
            };
        case TOGGLE_TEST_MODAL:
            const openModal = action.isModal !== undefined ? action.isModal : !state.openModal;
            return {
                ...state,
                openModal,
                foldModal: openModal === true ? false : state.foldModal,
                ...(action.isModal === false
                    ? {
                        selectTestId: '',
                        selectUser: undefined,
                        selectUserSubmit: undefined,
                    }
                    : {}),
            };
        case TOGGLE_FOLD_MODAL:
            return {
                ...state,
                foldModal: action.isModal !== undefined ? action.isModal : !state.foldModal,
            };
        case SET_FOCUS_TEST_ID:
            return {
                ...state,
                selectTestId: action.testId,
                staticsOptions: {
                    offset: 0,
                    limit: 10,
                },
            };
        case SET_FOCUS_USER:
            return {
                ...state,
                selectUser: action.user,
            };
        case SET_SURVEY_FREQUENCY:
            return {
                ...state,
                surveyFrequency: action.frequency,
            };
        case SET_OMR_FREQUENCY:
            const { testId, frequency } = action;
            const updatedOmrFrequency = new Map(state.omrFrequency); // 현재 omrFrequency 복사
            if (updatedOmrFrequency.has(testId)) {
                const existingFrequency = updatedOmrFrequency.get(testId);
                updatedOmrFrequency.set(testId, {
                    ...existingFrequency,
                    ...frequency, // 기존의 frequency와 병합
                });
            }
            else {
                // testId가 없는 경우, 새로운 항목으로 추가
                updatedOmrFrequency.set(testId, {
                    server: {
                        subject_item: '',
                        answer: [],
                    },
                    client: { subject_item: '', answer: [] },
                    ...frequency,
                });
            }
            return {
                ...state,
                omrFrequency: updatedOmrFrequency, // 업데이트된 omrFrequency 저장
            };
        case SET_ROOM_JOINED:
            return {
                ...state,
                isEnterRoom: action.joined,
            };
        case 'SET_TEST_NOTICE_MESSAGE':
            return {
                ...state,
                testNoticeMessage: action.message,
            };
        case 'SET_SUBMIT_OPTION':
            return {
                ...state,
                staticsOptions: action.data,
            };
        case 'SET_IS_ENTER_ROOM': {
            return {
                ...state,
                isEnterRoom: action.isEnterRoom,
                ...(!action.isEnterRoom
                    ? {
                        openModal: false, // testModal open 된 상태?
                        foldModal: false,
                        testNoticeMessage: undefined,
                        list: [],
                        questionList: new Map(),
                        answerList: [],
                        statistics: new Map(),
                        submitStatus: new Map(),
                        selectTestId: '',
                        selectUser: undefined,
                        selectUserSubmit: undefined,
                        surveyFrequency: {},
                    }
                    : {}),
            };
        }
    }
    return state;
});
const notifyBeforeTestEnd = async (store, test_id) => {
    try {
        const response = await getTestTime(test_id);
        if (response?.result?.remaining !== undefined) {
            const remainingTime = response.result.remaining; // 남은 시간 (초)
            const endTime = Date.now() + remainingTime * 1000; // 종료 시간
            // 알림 시간 설정
            const notifyTimes = [
                { time: endTime - 180 * 1000, message: '모의고사가 종료되기 3분 전입니다!' }, // 3분 전
                { time: endTime - 60 * 1000, message: '모의고사가 종료되기 1분 전입니다!' }, // 1분 전
            ];
            const currentTime = Date.now();
            // 각 알림 시간에 대해 setTimeout 설정
            notifyTimes.forEach(({ time, message }) => {
                if (currentTime < time) {
                    const timeoutDuration = time - currentTime;
                    setTimeout(() => {
                        store.dispatch(setTestNoticeMessage(message));
                        // 2초 후에 메시지를 초기화
                        setTimeout(() => {
                            store.dispatch(setTestNoticeMessage('')); // 빈 메시지로 초기화
                        }, 2000); // 2초 후에 초기화
                    }, timeoutDuration);
                }
                else {
                    const timeLeft = time - currentTime;
                    const minutes = Math.floor(timeLeft / 1000 / 60);
                    if (minutes > 0) {
                        store.dispatch(setTestNoticeMessage(`약 ${minutes}분 남았습니다.`));
                        setTimeout(() => {
                            store.dispatch(setTestNoticeMessage('')); // 빈 메시지로 초기화
                        }, 2000); // 2초 후에 초기화
                    }
                }
            });
        }
    }
    catch (error) {
        console.error('Error fetching test time:', error);
    }
};
StateListenerRegistry.register(
// @ts-ignore
(state) => {
    const list = getTestList(state);
    return list.find((testItem) => testItem.status === 'open');
}, async (testItem, store) => {
    if (testItem && testItem.status === 'open') {
        notifyBeforeTestEnd(store, testItem.id);
        const openModal = getIsTestOpenModal(store.getState);
        if (!openModal)
            store.dispatch(toggleTestModal(true));
    }
}, {
    deepEquals: true,
});
let intervalTest = null;
StateListenerRegistry.register(
// @ts-ignore
(state) => {
    if (getIsTeacher(state))
        return; // 선생님일 경우 아무것도 하지 않음
    if (!getIsEnterTestRoom(state))
        return; // 시험방에 들어가지 않은 경우 아무것도 하지 않음
    const list = getTestList(state);
    const openItems = list.filter((testItem) => testItem.status === 'open');
    let testList = [];
    openItems.forEach((testItem) => {
        const submitStatus = getSubmitUserByTestIdAndMemberId(state, testItem.id, getExternalUserId(state));
        if (submitStatus && submitStatus?.not_submitted) {
            testList.push(testItem.id);
        }
        else {
            testList = testItem.id;
        }
    });
    return testList;
}, async (testList, store) => {
    if (!testList)
        return;
    if (testList && typeof testList === 'string') {
        intervalTest && clearInterval(intervalTest);
        store.dispatch(setFocusTestId(testList));
        return;
    }
    intervalTest && clearInterval(intervalTest);
    setDisconnectWebsocketList(testList);
    intervalTest = setInterval(() => {
        disconnectWebsocket(undefined);
    }, 1000 * 60 * 1);
}, {
    deepEquals: true,
});
StateListenerRegistry.register(
// @ts-ignore
(state) => (getFocusTestId(state) ? getTestItemByTestId(state) : undefined), async (testItem, store) => {
    if (!testItem)
        return; // testItem이 없을 경우 아무것도 하지 않음
    if (getIsTeacher(store.getState))
        return; // 선생님일 경우 아무것도 하지 않음
    const member_id = getExternalUserId(store.getState);
    store.dispatch(setFocusUser(member_id, '-'));
    // 설문형일 경우 설문 빈도 조회
    if (testItem.type === '설문형') {
        const frequencyQueryString = objectToQueryString({
            test_id: testItem.id,
        });
        try {
            const response = await getSurveyFrequency(frequencyQueryString);
            store.dispatch(setSurveyFrequency(response.result));
        }
        catch (error) {
            console.error('설문 빈도 조회 실패:', error);
        }
    }
    // 파일첨부형일 경우의 처리
    else if (testItem.status === '파일첨부형') {
        // 필요한 경우 이 블록에 추가 작업을 구현
    }
});
