import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../common';
import { useMutation } from 'react-query';
import { postAttachment, postSubmitAnswer } from './querys';
import { AttachFile } from './AttachFile';
import { AttachFileAnswer } from './AttachFileAnswer';
import { shallowEqual, useSelector } from 'react-redux';
import { getAnswerList, getQuestionList, getSubmittedAttachmentData } from '../../test';
// @ts-ignore
const AttachmentQuestionStyled = styled.div `
	margin: 0;
	box-sizing: border-box;

	* {
		box-sizing: border-box;
		margin: 0;
	}

	.attachment-container {
		* {
			box-sizing: border-box;
			margin: 0;
		}

		flex-direction: column;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}

		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 540px;

		.button {
			display: inline-flex;
			width: min-content;
			justify-content: center;
			align-items: center;

			.overText {
				min-width: 76px;
				font-family: var(--b-l-medium-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b-l-medium-font-size, 14px);
				line-height: var(--b-l-medium-line-height, 20px);
				font-weight: var(--b-l-medium-font-weight, 400);
				display: inline-block;
			}
		}

		.btn_silver {
			background-color: #f3f4f8;
			color: #4d4d4d;
		}

		.md_round {
			padding: 4px 8px;
			gap: 4px;
			border-radius: 100px;
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;

		.field-global-001 {
			flex-direction: column;
		}
	}

	.box {
		border-radius: 4px;
		padding: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 10px;
		border: 1px solid #b6bac0;
		min-height: 135px;
	}

	.attach_item {
		svg {
			min-width: 16px;
		}
	}
	${props => props.$isMobile && mobileStyles}
`;
const mobileStyles = css `
	.attachment-container {
		* {
			box-sizing: border-box;
			margin: 0;
		}

		flex-direction: column;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.dropdown-list {
			z-index: 2;
		}

		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.button {
			display: inline-flex;
			width: min-content;
			justify-content: center;
			align-items: center;

			.overText {
				min-width: 76px;
				font-family: var(--b-l-medium-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b-l-medium-font-size, 14px);
				line-height: var(--b-l-medium-line-height, 20px);
				font-weight: var(--b-l-medium-font-weight, 400);
				display: inline-block;
			}
		}

		.btn_silver {
			background-color: #f3f4f8;
			color: #4d4d4d;
		}

		.md_round {
			padding: 4px 8px;
			gap: 4px;
			border-radius: 100px;
		}

		.cursor-pointer {
			cursor: pointer;
		}
	}

	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;

		.field-global-001 {
			flex-direction: column;
		}
	}

	.box {
		border-radius: 4px;
		padding: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 10px;
		border: 1px solid #b6bac0;
		min-height: 135px;
	}

	.attach_item {
		svg {
			min-width: 16px;
		}
	}
`;
export const AttachmentQuestionContainer = React.memo(({ test_id, testItem, isSubmit }) => {
    const { isMobile } = useGlobalState();
    const questionData = useSelector((state) => getQuestionList(state, test_id), shallowEqual);
    const answerData = useSelector((state) => getAnswerList(state, test_id), shallowEqual);
    const submittedData = useSelector((state) => getSubmittedAttachmentData(state), shallowEqual);
    const [submittedFileList, setSubmittedFileList] = useState([]);
    const [attachFileList, setAttachFileList] = useState([]);
    const questionFileList = testItem?.attachments || [];
    // useEffect(() => {
    //     console.log('question_id:', questionData[0].id);
    //     console.log('testItem:', testItem);
    // }, [testItem, questionData]);
    useEffect(() => {
        console.log('answerData:', answerData);
        if (!answerData || answerData.length === 0)
            return;
        const valuesArray = answerData.map(item => item?.value);
        // console.log("valuesArray", valuesArray);
        setSubmittedFileList(valuesArray);
    }, [answerData]);
    // useEffect(() => {
    //     console.log('submittedData', submittedData);
    //     if (!submittedData || submittedData.length === 0) return;
    //     setSubmittedFileList(submittedData)
    // }, submittedData)
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const onAttachmentSubmit = async () => {
        try {
            let values = [];
            if (attachFileList.length === 0) {
                alert('답변 파일을 업로드해주세요.');
                return;
            }
            for (let i = 0; i < attachFileList.length; i++) {
                const file = attachFileList[i];
                if (!file || !file.name) {
                    continue;
                }
                // @ts-ignore
                const filepath = `${questionData[0].id}/answer/${i + 1}`;
                const value = `/api/mimac/file/${filepath}/${file.name}`;
                await postAttachment(file, filepath);
                values.push(value);
            }
            await submitAnswerMutation.mutateAsync({
                // @ts-ignore
                id: test_id,
                props: [
                    {
                        // @ts-ignore
                        question_id: questionData[0].id,
                        values: values,
                    },
                ],
            });
        }
        catch (error) {
            alert(error);
            console.error(error);
        }
    };
    return (React.createElement(AttachmentQuestionStyled, { "$isMobile": isMobile },
        React.createElement("div", { className: "attachment-container" },
            React.createElement("div", { className: "task-label overText" }, "\uD30C\uC77C\uCCA8\uBD80\uD615"),
            React.createElement("div", { className: "attachment-display" },
                React.createElement(AttachFile, { isTeacher: true, showRegist: false, isRegist: false, attachFileList: questionFileList })),
            isSubmit ? (React.createElement("div", { className: "attachment-display" },
                React.createElement(AttachFileAnswer, { isTeacher: false, showRegist: !isSubmit, isRegist: !isSubmit, attachFileList: submittedFileList }))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "attachment-display" },
                    React.createElement(AttachFileAnswer, { isTeacher: false, showRegist: !isSubmit, isRegist: !isSubmit, attachFileList: attachFileList, setAttachFileList: setAttachFileList })),
                React.createElement("div", { className: "btn-container", style: { flexDirection: 'row' } },
                    React.createElement(Button, { className: "btn-black", text: "common.submit", styles: {
                            buttonWrap: {
                                marginRight: 0,
                            },
                        }, onClick: onAttachmentSubmit })))))));
});
