import { getAccessToken } from '../../features/base/local';
import { getRoomOption } from '../../features/room';
function getWebsocketURLBase(urlBase) {
    if (urlBase.startsWith('http:')) {
        return `ws:${urlBase.substring(5)}`;
    }
    else if (urlBase.startsWith('https:')) {
        return `wss:${urlBase.substring(6)}`;
    }
    throw new Error('알 수 없는 프로토콜');
}
const formatMessage = msg => {
    if (!msg || !msg?.format) {
        return '';
    }
    if (!msg?.params) {
        return msg.format;
    }
    return msg.format.replace(/\{(\w+)\}/g, (match, p1) => {
        return msg.params[p1];
    });
};
export class MockTestWebsocket {
    constructor(disconnectWebsocket) {
        this.disconnectWebsocket = disconnectWebsocket;
        this.onMessage = [];
        this.connection = null;
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = '';
        this.timeInterval = null;
        this.meeting_id = getRoomOption(APP.store.getState)?.uuid;
    }
    cleanUp() {
        this.onMessage = [];
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = '';
    }
    connect(handleMessage) {
        const connection = new WebSocket(`${getWebsocketURLBase(window.location.origin)}/svc/room/ws`);
        return new Promise((resolve, reject) => {
            this.connection = connection;
            connection.onopen = async () => {
                this.timeInterval = setInterval(async () => {
                    await this._request('GetServerTime', null, 5000);
                }, 1000 * 60);
                connection.onmessage = message => {
                    handleMessage && handleMessage(JSON.parse(message.data));
                    this.onMessage.forEach(handle => handle(message.data));
                };
                connection.onclose = async () => {
                    APP.eventManager.publish('TEST_CLOSE');
                    this.timeInterval && clearInterval(this.timeInterval);
                    this.connection = null;
                    reject(false);
                };
                try {
                    const response = await this.setAuth();
                    console.log('setAuth');
                    if (response.status === 200) {
                        const joinResponse = await this.joinRoom();
                        if (joinResponse.status === 200) {
                            resolve(true);
                        }
                    }
                }
                catch (err) {
                    reject('api.defaultError');
                }
                resolve(true);
            };
            connection.onerror = async (err) => {
                APP.eventManager.publish('TEST_CLOSE');
                this.timeInterval && clearInterval(this.timeInterval);
                this.connection = null;
                // 임시저장
                this.disconnectWebsocket(undefined);
                alert('네트워크 연결이 불안정합니다. 다시 접속 부탁드립니다.');
                window.close();
                reject(err);
            };
        });
    }
    async disconnect() {
        try {
            await this.leaveRoom();
            this.connection?.close();
        }
        catch (err) {
            console.log(err);
        }
    }
    async setAuth() {
        const token = getAccessToken(APP.store.getState);
        if (!token)
            return Promise.reject('api.defaultError');
        const response = await this._request('User.Auth.Set', { access_token: token }, 5000);
        if (response?.status != 200) {
            return Promise.reject('서버 오류 - set Auth');
        }
        return response;
    }
    async kickMember() {
        const response = await this._request('Room.Member.Kick', 5000);
        if (response?.status != 200) {
            return Promise.reject('서버 오류 - Room.Member.Kick');
        }
    }
    async joinRoom() {
        const response = await this._request('Room.Member.Session.Add', 5000);
        if (response?.status != 200) {
            return Promise.reject('서버 오류 - Room.Member.Session.Add');
        }
        return response;
    }
    async leaveRoom() {
        const response = await this._request('Room.Member.Session.Remove', 5000);
        if (response?.status != 200) {
            return Promise.reject('서버 오류 - Room.Member.Session.Remove');
        }
        return response;
    }
    _request(clazz, payload, timeout = 5000) {
        if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            const requestID = `${Date.now()}-${++this.nextId}`;
            this.connection.send(JSON.stringify({
                class: clazz,
                id: requestID,
                payload: {
                    ...payload,
                    meeting_id: this.meeting_id,
                    group_id: '',
                },
            }));
            return this._waitFor(data => JSON.parse(data), res => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
        }
        console.log('웹소켓 연결 안됨.', payload);
        return Promise.reject('api.refreshError');
    }
    _subscribe(handle) {
        this.onMessage.push(handle);
        return () => {
            const index = this.onMessage.findIndex(element => element === handle);
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }
    async _waitFor(hook, check, timeout, clazz) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubscribe;
            const cleanup = () => {
                clearTimeout(timerID);
                unsubscribe();
            };
            unsubscribe = this._subscribe(data => {
                try {
                    const result = hook(data);
                    if (!check(result))
                        return;
                    cleanup();
                    resolve(result);
                }
                catch (err) {
                    cleanup();
                    reject(err);
                }
            });
            timerID = setTimeout(() => {
                cleanup();
                reject(new Error(`${clazz} timeout`));
            }, timeout);
        });
    }
}
