import _ from 'lodash';
import { PAGE_TYPE } from '../../features/base/app/constants.ts';
import { getLocalIDMember } from '../../features/base/members/index.ts';
import { getPropertyValue } from '../../features/base/settings/functions.ts';
import { leaveRoom, setRoomJoined } from '../../features/room';
import { getLocalSharingStatus } from '../../features/screen-share';
import { INIT_VISIBLE, VIDEO_MODE_LIST, setLayoutMode, videoLayoutMode } from '../../features/video-layout';
import JitsiManagement from './jitsi';
import MateManagement from './mate';

let isLeaving = false;

class Management {
	constructor() {
		this.jitsi = new JitsiManagement();
		this.mateManagement = new MateManagement('');
		this.jitsiConference = null;
		this.leaveStatus = true;
	}

	setLeaveStatus(leaveStatus) {
		this.leaveStatus = leaveStatus;
	}

	/**
	 * 회의실 입장
	 */
	join(password, role_name, isTemp = false) {
		const { dispatch, getState } = APP.store;
		return new Promise((resolve, reject) => {
			this.mateManagement
				.connectAndJoin({ password, role_name }, null, isTemp)
				.then(result => {
					const user_id = getLocalIDMember(getState);
					this.jitsi
						.connectAndJoin(isTemp, user_id)
						.then(conference => {
							this.jitsiConference = conference;

							this.leaveStatus = false;
							isLeaving = false;
							return resolve();
						})
						.catch(err => {
							console.log(err);
							this.leave(err);
							reject(err);
						});
				})
				.catch(err => {
					this.leave(err);
					reject(err);
				});
		});
	}

	/**
	 * 회의실 퇴장
	 */
	async leave(alertMessage, isDupLogin = false) {
		// If the leave process is already running, return immediately
		if (isLeaving || this.leaveStatus) {
			console.log('Leave function is already running.');
			return;
		}

		// Mark the leave process as running
		isLeaving = true;
		this.leaveStatus = true;

		const individualLive = getPropertyValue(APP.store.getState, 'individualLive');

		try {
			if (individualLive) {
				await APP.API.quitMeeting();
			}

			if (getLocalSharingStatus(APP.store.getState)) {
				console.log('Stopping screen sharing...');
				APP.store.dispatch(setLayoutMode(videoLayoutMode.default, false));
				await APP.management.setScreenOption(null, null);
				APP.management.stopScreenShare(false);
			}

			await Promise.all([
				this.mateManagement
					?.disconnect()
					.catch(err => console.error('Error disconnecting mateManagement:', err)),
				this.jitsi?.disconnect().catch(err => console.error('Error disconnecting jitsi:', err)),
			]);

			console.log('Leave process completed successfully.');

			await APP.store.dispatch(setRoomJoined(false));
			await APP.store.dispatch(leaveRoom());

			if (isDupLogin) {
				await APP.eventManager.publish('UPDATE_PAGE', { page: PAGE_TYPE.DUP_LOGIN });
				return;
			} else {
				APP.eventManager.publish('UPDATE_PAGE', { page: PAGE_TYPE.LOBBY });
				setTimeout(() => {
					alertMessage && alert(alertMessage);
					window.close();
				}, 1000);
			}
		} catch (err) {
			console.error('Error during leave process:', err);
			APP.UI.alertMessage(err, true);

			window.close();

			location.reload();
		}
	}

	toggleE2EE(isEnable) {
		this.jitsi.toggleE2EE(isEnable);
	}

	conferenceAddedTrack(mediaType) {
		this.jitsi.conferenceAddedTrack(mediaType);
	}

	conferenceRemovedTrack(mediaType) {
		this.jitsi.conferenceRemovedTrack(mediaType);
	}

	conferenceMutedVideoTrack(isMuted) {
		if (APP.peerconnections && APP.peerconnections.length > 0) {
			APP.peerconnections.map(peerconnection => {
				peerconnection.muteVideo(isMuted);
			});
		}
	}

	conferenceReplaceTrack(newTrackId) {
		if (APP.peerconnections && APP.peerconnections.length > 0) {
			APP.peerconnections.map(peerconnection => {
				peerconnection.replaceTrack(newTrackId);
			});
		}
	}

	conferenceMutedVideoTrack(user_id, isMuted) {
		if (APP.peerconnections && APP.peerconnections.length > 0) {
			const peer = APP.peerconnections.find(peerconnection => peerconnection.user_id === user_id);

			if (peer) {
				peer.muteVideo(isMuted);
			}
		}
	}

	/**
	 * (TRACK) jitsi conference user_id를 mate에 업데이트 해준다.
	 * @param {string} bridge_id
	 */
	async setBridgeId(bridge) {
		const response = await this.mateManagement.setBridgeId(bridge);

		if (response.status !== 200) {
			/**
			 * (fix) 여기서의 에러 처리 필요
			 */
			console.log(' bridge id 세팅 오류 ');
		}
	}

	/**
	 * (LAYOUT) 레이아웃 변경
	 * @param {string} mode
	 * @returns
	 */
	async changeLayout(mode) {
		// 나의 방 권한이 존재하는 경우만 실행
		const viewVisible = _.cloneDeep(INIT_VISIBLE);
		if (VIDEO_MODE_LIST.indexOf(mode) !== -1) {
			// 레이아웃
			const response = await this.mateManagement.setLayoutMode(mode);
			if (response.status !== 200) {
				/**
				 * (fix) 여기서의 에러 처리 필요
				 */
				console.log(' 레이아웃 모드 오류 - ' + mode);
				return response;
			}
		} else {
			viewVisible[mode] = true;
		}

		// 화면 가시성
		return this.mateManagement.setVisibleLayoutMode(viewVisible);
	}

	/**
	 * (DOCUMENT) 문서 공유 저장 & 공유
	 * @param {Object} data
	 * @returns
	 */
	setShareItem(data) {
		return this.mateManagement.setShareItem(data);
	}
	/**
	 * (DOCUMENT) 문서 삭제
	 * @param {Object} data
	 * @returns
	 */
	deleteShareItem(data) {
		return this.mateManagement.deleteShareItem(data);
	}

	/**
	 * (DOCUMENT) 현재 문서 Foucs
	 * @param {string} uuid
	 * @param {Number} index
	 * @param {string} mode
	 * uuid, index: Number, mode
	 * @returns
	 */
	focusShareItem(uuid, index, mode) {
		return this.mateManagement.setFocusShareItem(uuid, index, mode);
	}

	/**
	 * (CANVAS) 문서 포인트 전송
	 * @param {string} uuid
	 * @param {Number} index
	 * @param {string} key
	 * @param {Object} property
	 * uuid, index: Number, key, property: Object
	 * @returns
	 */
	sendSharePoints(uuid, index, key, property) {
		return this.mateManagement.sendSharePoints(uuid, index, key, property);
	}

	/**
	 * (CANVAS) 문서 포인트 삭제 (라인 한줄 )
	 * @param {string} uuid
	 * @param {Number} index
	 * @param {string|null} key
	 * @param {boolean} isAll
	 * //uuid, index: Number, key|null, isAll
	 * @returns
	 */
	deleteShareLine(uuid, index, key, isAll) {
		return this.mateManagement.deleteShareLine(uuid, index, key, isAll);
	}
	/**
	 * (CANVAS) 문서 판서 삭제 ( 한페이지 )
	 * @param {string} uuid
	 * @param {Number} index
	 * @returns
	 */
	deleteShareCanvasIndex(uuid, index) {
		return this.mateManagement.deleteShareCanvasIndex(uuid, index);
	}

	/**
	 * (MEMBER) 발표자 권한 요청
	 * @param {string} member_uuid
	 * @returns
	 */
	requestPresentation(member_uuid) {
		return this.mateManagement.requestPresentation(member_uuid);
	}
	/**
	 * (MEMBER) 발표자 권한 응답
	 * @param {string} user_uuid
	 * @param {boolean} accepted
	 * @returns
	 */
	responsePresentation(user_uuid, accepted) {
		return this.mateManagement.responsePresentation(user_uuid, accepted);
	}

	/**
	 * (MEMBER) 사용자 권한 변경
	 * @param {string} user_uuid
	 * @param {string} role
	 * @param {string} group_id
	 * @returns
	 */
	setMemberRole(user_uuid, role) {
		return this.mateManagement.setMemberRole(user_uuid, role);
	}

	/**
	 * (MEMBER) 사용자 PIN 변경
	 * @param {string} user_uuid
	 * @param {boolean} pinned
	 * @returns
	 */
	setMemberPinned(user_uuid, pinned) {
		return this.mateManagement.setMemberPinned(user_uuid, pinned);
	}

	/**
	 * (MEMBER) 사용자 강제 퇴장
	 * @param {string} user_uuid
	 * @returns
	 */
	setMemberKick(user_uuid) {
		return this.mateManagement.setMemberKick(user_uuid);
	}

	/**
	 * (MEMBER) 사용자 손들기
	 * @param {string} user_uuid
	 * @param {boolean} handler
	 * @returns
	 */
	raiseHand(user_uuid, handler) {
		return this.mateManagement.raiseHand(user_uuid, handler);
	}

	/**
	 * (TRACK) 비디오 ON / OFF
	 * @param {string} user_uuid
	 * @param {boolean} muted
	 * @returns
	 */
	setVideo(user_uuid, muted) {
		return this.mateManagement.setVideo(user_uuid, muted);
	}

	/**
	 * (TRACK) 오디오 ON / OFF
	 * @param {string} user_uuid
	 * @param {boolean} muted
	 * @returns
	 */
	setAudio(user_uuid, muted) {
		return this.mateManagement.setAudio(user_uuid, muted);
	}

	/**
	 * (TRACK) 전체 비디오 ON / OFF
	 * @param {boolean} muted
	 * @returns
	 */
	setAllVideo(muted) {
		return this.mateManagement.setAllVideo(muted);
	}

	/**
	 * (TRACK) 전체 오디오 ON / OFF
	 * @param {boolean} muted
	 * @returns
	 */
	setAllAudio(muted) {
		return this.mateManagement.setAllAudio(muted);
	}

	/**
	 * (CHAT) 채팅 전송
	 * @param {objcet} data
	 */
	sendMessage(data) {
		return this.mateManagement.sendMessage(data);
	}
	/**
	 * (CHAT) 파일 전송
	 * @param {Objcet} data
	 */
	sendFile(data) {
		return this.mateManagement.sendFile(data);
	}

	/**
	 * (SCREEN SHARE)
	 * 화면 녹화 시작
	 * @returns
	 */
	startScreenShare() {
		return this.jitsi.startScreenShare();
	}
	/**
	 * (SCREEN SHARE)
	 * 화면 녹화 종료
	 * @returns
	 */
	stopScreenShare() {
		return this.jitsi.stopScreenShare();
	}

	/**
	 * (SCREEN SHARE)
	 * 현재 화면 공유 중이거나 화면 공유 종료를 알림.
	 * @param {string} jitsi_id
	 * @param {string} member_uuid
	 * @returns
	 */
	setScreenOption(jitsi_id, member_uuid) {
		return this.mateManagement.setScreenOption(jitsi_id, member_uuid);
	}

	/**
	 * (POLICY)
	 * 현재 권한
	 * @param {Object} policy
	 * @returns
	 */
	updateRoomPolicy(policy) {
		return this.mateManagement.updateRoomPolicy({ policy });
	}

	/**
	 * (POLICY)
	 * 그리드 수 변경
	 * @param {Number} count
	 */
	setGridCount(count) {
		return this.mateManagement.setGridCount(count);
	}

	/**
	 * (MEMBER) 녹화 중을 알림.
	 * @param {*} user_uuid
	 * @param {*} recording
	 * @returns
	 */
	setRecording(user_uuid, recording) {
		return this.mateManagement.setRecording(user_uuid, recording);
	}

	/**
	 * (TRACK)
	 * @param {string} newTrackId
	 * @param {string} type
	 * @param {object} options
	 */
	updateTrack(newTrackId, type, options) {
		this.jitsi.updateTrack(newTrackId, type, options);
	}

	/**
	 * (TRACK)
	 * @param {*} oldTrack
	 * @param {*} newTrack
	 */
	replaceTrack(oldTrack, newTrack) {
		return this.jitsi.replaceTrack(oldTrack, newTrack);
	}

	setConstraints(data) {
		return this.jitsi.setConstraints(data);
	}

	/**
	 * 로컬 사용자의 연결 상태 이벤트 등록
	 * @param {function} handler
	 */
	registerUpdateLocalStats(handler) {
		this.jitsi.registerUpdateLocalStats(handler);
	}
	/**
	 * remote 사용자의 연결 상태 이벤트 등록
	 * @param {function} handler
	 */
	registerUpdateRemoteStats(handler) {
		this.jitsi.registerUpdateRemoteStats(handler);
	}

	/**
	 * 전체 상태 업데이트
	 * @param {boolean} running
	 * @returns
	 */
	updateGroupStatus(running) {
		return this.mateManagement.updateGroupStatus(running);
	}

	/**
	 * 그룹에 알림 메시지 전달
	 * @param {string} message: 전달할 메시지
	 * @param {string} group_id: 그룹 아이디
	 */
	notifyGroupMessage(message, group_id) {
		return this.mateManagement.notifyGroupMessage(message, group_id);
	}

	/**
	 * (SURVEY) 설문조사 알림
	 * @returns
	 */
	notifySurvey(uuid) {
		return this.mateManagement.notifySurvey(uuid);
	}
	/**
	 * (SURVEY) 설문조사 응답
	 * @param { string } uuid
	 * @returns
	 */
	replySurvey(uuid) {
		return this.mateManagement.replySurvey(uuid);
	}

	updateNickname(nickname) {
		return this.mateManagement.updateNickname(nickname);
	}

	/**
	 * Audio Output
	 */
	getAudioOutputDeviceId() {
		return this.jitsi.getAudioOutputDeviceId();
	}
	setAudioOutputDevice(newId) {
		return this.jitsi.setAudioOutputDevice(newId);
	}

	callOfferMember(offer) {
		return this.mateManagement.callOfferMember(offer);
	}

	callAnswerMember(answer) {
		return this.mateManagement.callAnswerMember(answer);
	}

	callCandidateMember(candidate) {
		return this.mateManagement.callCandidateMember(candidate);
	}
}

export default Management;
