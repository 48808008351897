import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getTestSubmitStatusByTestId } from '../../test';
import { setFocusUser, setSubmitOption } from '../../test/action';
import { Table } from '../common';
// @ts-ignore
const DSTL001DetailTableStyled = styled.div `
	&.detail_table:not(&.popup-table) {
		flex: 1;
		overflow: hidden;
		height: 100%;
		min-height: 300px;

		.fext-field {
			height: 100%;
			overflow: hidden;
		}
	}

	&.popup-table {
		.fext-field {
			height: 100%;
			overflow: hidden;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		// line-height: 32px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.task-label {
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		min-height: 240px;
		position: relative;
		overflow: hidden;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.scroll-content {
			width: 100%;
			overflow: auto;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.body-item {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
				width: 20px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.more_txt {
			color: var(--color-black, #101010);
			text-decoration: underline;
			cursor: pointer;
		}

		.detail_more_txt {
			color: var(--color-pink, #f46790);
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;
const count = 10;
export const SubmissionStatusTable = React.memo(({ isTest, test_id, subjectId, isDetail = false, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const staticsData = useSelector((state) => getTestSubmitStatusByTestId(state, test_id));
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [questionCount, setQuestioncount] = useState(0);
    const [submissionStatus, setSubmissionStatus] = useState();
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'nameId', space: 1, sort: true },
            { title: 'live_status', space: 1, sort: true },
            // { title: 'numberOfQuestion', space: 2, sort: true },
            { title: 'grades', space: 1, sort: true },
            { title: 'etc', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'member_name', space: 1 },
            { title: 'status', space: 1 },
            // { title: 'answer', space: 2 },
            { title: 'score', space: 1 },
            { title: 'etc', space: 1 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    useEffect(() => {
        if (staticsData) {
            const { not_submitted, submitted, question_count, items, total_count } = staticsData;
            setTestData(items);
            setTotalCount(total_count);
            setQuestioncount(question_count);
            setSubmissionStatus(`${submitted}/${total_count}`);
        }
    }, [staticsData]);
    useEffect(() => {
        dispatch(setSubmitOption({
            offset: page * count,
            limit: count,
        }));
    }, [page]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } }, headItem.title === 'etc' ? (React.createElement("p", { className: isDetail ? 'detail_more_txt' : 'more_txt', onClick: () => {
                dispatch(setFocusUser(item.member_id, item.member_name));
            } }, isDetail ? '보기' : '자세히')) : (React.createElement("p", { className: "overText", style: styles?.bodyText }, headItem.title === 'status'
            ? item.not_submitted
                ? t('assignment.notSubmitted')
                : t('assignment.submitted')
            : headItem.title === 'score'
                ? item.corrects === -1
                    ? '-'
                    : item[headItem.title]
                : headItem.title === 'answer'
                    ? `${item.corrects === -1 ? '-' : item.corrects}/${questionCount}`
                    : item[headItem.title])))));
    };
    const renderTitle = useMemo(() => (React.createElement("div", { className: "task-label", style: { width: '100%', alignItems: 'space-between' } },
        React.createElement("p", null, `${t('assignment.submissionStatus', {
            submissionStatus: submissionStatus || '',
        })} ${subjectId ? `- ${subjectId}` : ''}`))), [subjectId, submissionStatus]);
    return (React.createElement(DSTL001DetailTableStyled, { className: "field-global-001 detail_table", style: { flexDirection: 'column' } },
        React.createElement(Table, { className: "task-list-002", showIndex: false, isInifinite: false, page: page, data: testData, sortDefault: "member_id", totalCount: totalCount, headTitleList: headTitleList, initPageSize: 5, renderTitle: renderTitle, renderItem: renderItem, handlerMoreData: handlerMoreData })));
});
