import React from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { Button } from '../common';
import { useMutation } from 'react-query';
import { postSubmitAnswer } from './querys';
import { SurveyLayout } from './SurveyLayout';
// @ts-ignore
const SurveyQuestionStyled = styled.div `

    margin: 0;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
        margin: 0;
    }

    .survey-container {
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		-webkit-box-pack: start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 600px;

		* {
			box-sizing: border-box;
			margin: 0;
		}
	}

	.survey {
		width: 600px;
		border-radius: 12px;
		border: 1px solid #f3f4f8;
		position: relative;
	}

	.body4 {
		font-weight: normal;
	}

	.survey-header {
		flex-direction: row;
		padding: 12px 16px 12px 16px;
		gap: 30px;
		background: var(--color-silver, #f3f4f8);
		border-radius: 8px 8px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.survey-title {
		gap: 12px;
		font-size: var(--b4-font-size, 14px);
		display: flex;
		flex: 1;
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.dropdown {
		display: flex;
		flex-direction: column;
		gap: 6px;
		align-items: flex-start;
		justify-content: flex-start;
		cursor: pointer;
		width: 100%;
		position: relative;
	}

	.survey .dropdown-field,
	.survey .survey-input {
		border-width: 0px !important;
		border: none;
		padding: 0;
	}

	.dropdown-popup .dropdown-field {
		border-radius: 4px;
		padding: 10px;
	}

	.dropdown-field {
		background: var(--color-white, #ffffff);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 100%;
	}

	.dropdown-field .placeholder {
		text-align: left;
		font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
	}

	.dropdown-popup .placeholder {
		color: var(--color-black, #101010);
		font-size: var(--b5-font-size, 12px);
		line-height: var(--b5-line-height, 22px);
		font-weight: var(--b5-font-weight, 400);
	}

	.survey-body {
		padding: 16px;
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.survey .survey-body .survey-content {
		display: flex;
		gap: 12px;
		flex-direction: column;
	}

	.checkbox,
	.tablet .checkbox {
		display: flex;
		gap: 12px;
		flex-wrap: wrap;
	}

	.flex.column {
		flex-direction: column;
	}

	.survey .survey-body label {
		height: 40px;
	}

	.checkbox label,
	.tablet .checkbox label {
		gap: 6px;
		cursor: pointer;
	}

	.checkbox label {
		gap: 6px;
		cursor: pointer;
	}

	.checkbox-item {
		display: flex;
		flex-direction: row;
		gap: 5px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		cursor: pointer;
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b-l-medium-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b-l-medium-font-size, 14px);
		line-height: var(--b-l-medium-line-height, 20px);
		font-weight: var(--b-l-medium-font-weight, 400);
		position: relative;
		display: flex;
	}

	.survey .survey-body .survey-content input:not(.suvey-input) {
		border: none;
		padding: 12px 0;
	}

	input[type='checkbox'] {
		display: none;
	}

	.checkbox label span,
	.tablet .checkbox label span {
		font-size: var(--b4-font-size, 12px);
		line-height: 150%;
		letter-spacing: -0.02em;
	}

	.checkbox label span {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.suvey-input {
		padding: 6px 10px;
	}

    ${props => props.$isMobile && mobileStyles}
`;
const mobileStyles = css `

    .survey-container {
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		* {
			box-sizing: border-box;
			margin: 0;
		}
	}

	.survey {
		width: 100%;
		border-radius: 12px;
		border: 1px solid #f3f4f8;
		position: relative;
	}

	.body4 {
		font-size: 14px;
	}

	.survey .survey-header {
		flex-direction: column;
		padding: 6px 10px 6px 10px;
	}

	.survey .survey-header {
		background: var(--color-silver, #f3f4f8);
		border-radius: 8px 8px 0px 0px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		gap: 0;
	}

	.survey .dropdown {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 6px 0px;
	}

	.survey .dropdown .dropdown-list {
		top: 33px !important;
	}

	.survey .survey-header .survey-title {
		gap: 8px;
		font-size: var(--b4-font-size, 12px);
		width: 100%;
	}

	.survey .dropdown-field,
	.survey .survey-input {
		border-width: 0px !important;
		border: none;
		padding: 0;
	}

`;
export const SurveyQuestionContainer = React.memo(({ test_id, testItem, isSubmit }) => {
    const { isMobile } = useGlobalState();
    const [changeSurveyData, setChangeSurveyData] = React.useState([]);
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const onSurveySubmit = async () => {
        try {
            if (!test_id)
                return;
            let submitData = [];
            for (const survey of changeSurveyData) {
                if (survey.type === 'choice' || survey.type === 'checkbox') {
                    const selectedOptions = survey.data.filter(option => option.checked);
                    if (selectedOptions.length === 0) {
                        alert('모든 항목에 답변해주세요.');
                        return;
                    }
                    if (survey.type === 'choice') {
                        submitData.push({
                            question_id: survey.id,
                            values: selectedOptions.map(option => option.title),
                        });
                    }
                    else if (survey.type === 'checkbox') {
                        selectedOptions.forEach(option => {
                            submitData.push({
                                question_id: survey.id,
                                values: [option.title],
                            });
                        });
                    }
                }
                else if (survey.type === 'short' || survey.type === 'long') {
                    if (survey.data === '') {
                        alert('모든 항목에 답변해주세요.');
                        return;
                    }
                    submitData.push({
                        question_id: survey.id,
                        values: [survey.data],
                    });
                }
            }
            if (submitData.length === 0) {
                alert('모든 항목에 답변해주세요.');
                return;
            }
            // Answer submission mutation
            await submitAnswerMutation.mutateAsync({
                id: test_id,
                props: submitData,
            });
        }
        catch (error) {
            alert(error);
        }
    };
    return (React.createElement(SurveyQuestionStyled, { "$isMobile": isMobile },
        React.createElement("div", { className: "survey-container" },
            React.createElement("div", { className: "task-label overText" }, "\uC124\uBB38\uD615"),
            React.createElement("div", { className: "attachment-display" },
                !isSubmit ? '설문에 참여해주세요' : '제출 완료',
                React.createElement(SurveyLayout, { testItem: testItem, isSubmit: isSubmit, isRegist: false, setChangeSurveyData: setChangeSurveyData }))),
        !isSubmit && (React.createElement("div", { className: "btn-container", style: { flexDirection: 'row' } },
            React.createElement(Button, { className: "btn-black", text: "common.submit", styles: {
                    buttonWrap: {
                        marginRight: 0,
                    },
                }, onClick: onSurveySubmit })))));
});
