import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useGlobalState } from '../../../app/GlobalStateProvider';
import { getAnswerList, getIsSubmitUser, getQuestionList, getSubmitData } from '../../../test';
import { InputElement, Table } from '../../common';
import { Colors } from '../../common/Colors';
import { Icon } from '../../ui';
export const ErrataTable = React.memo(({ isDetail }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    // 제출 사용자 및 미제출 사용자 구분
    const notSubmit = useSelector((state) => getIsSubmitUser(state), shallowEqual);
    // 질문 및 정답 조회
    const questionList = useSelector((state) => getQuestionList(state), shallowEqual);
    // 정답 조회
    const answwerList = useSelector((state) => getAnswerList(state), shallowEqual);
    const [submitData, setSubmitData] = React.useState([]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'subject', space: 2, sort: false },
            { title: 'no', space: 1, sort: false },
            { title: 'answer', space: 2, sort: false },
            ...(isDetail ? [{ title: 'submitAnswer', space: 2, sort: false }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'errata', space: 1, sort: false }] : []),
            { title: 'score', space: 1, sort: false, minWidth: 60, maxWidth: 60 },
        ];
        const tableReadDataKeys = [
            { title: 'subject_item', space: 2 },
            { title: 'number', space: 1 },
            { title: 'answer', space: 2 },
            ...(isDetail ? [{ title: 'value', space: 2 }] : []),
            ...(isDetail && !notSubmit ? [{ title: 'correction', space: 1 }] : []),
            { title: 'point', space: 1, sort: false, minWidth: 60, maxWidth: 60 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isDetail, notSubmit]);
    React.useEffect(() => {
        const mergedList = getSubmitData(questionList, answwerList);
        setSubmitData(mergedList);
    }, [questionList, answwerList]);
    const renderBodyItem = (item, headItem, styles, isMobile) => {
        const getContent = () => {
            switch (headItem.title) {
                case 'correction':
                    return React.createElement(Icon, { icon: item[headItem.title] === 'O' ? 'pass' : 'fail' });
                case 'answer':
                case 'submitAnswer':
                    return !item[headItem.title] ? ('-') : item.type === 'choice' ? (React.createElement("div", { className: `answer ${headItem.title === 'answer' ? 'pink' : ''}`, style: { ...styles?.bodyText } },
                        React.createElement("div", { className: "frame" },
                            React.createElement("p", null, item[headItem.title])))) : (React.createElement("div", { style: { ...styles?.bodyText, overflow: 'hidden' } },
                        React.createElement("p", { className: "overText5", style: {
                                color: ` ${headItem.title === 'answer' ? 'var(--color-pink,#f46790)' : 'black'}`,
                            } }, item[headItem.title])));
                case 'point':
                    return (React.createElement("div", { className: "input_answer_wrapper" },
                        React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[headItem.title] }),
                        "\uC810"));
                case 'subject_item':
                    return (React.createElement("div", { className: "overText2 subject-text" }, ['history', 'character'].includes(item.subject_item)
                        ? t(`assignment.${item.subject_item}`)
                        : item[headItem.title]));
                case 'number':
                    return React.createElement("p", { className: "bold_number overText" }, item[headItem.title]);
                default:
                    return React.createElement("p", { className: "overText5" }, item[headItem.title]);
            }
        };
        return (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                minWidth: headItem?.minWidth,
                maxWidth: headItem?.maxWidth,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, getContent()));
    };
    const renderItem = (item, styles) => tableReadDataKeys.map(headItem => renderBodyItem(item, headItem, styles, false));
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "sel_eval_item", style: { color: Colors.light_grey, width: '100%', flex: 1, minHeight: 450 } }, t('assignment.noSubmitPersonGuid')));
    }, []);
    return (React.createElement("div", { className: `errata_table ${notSubmit && 'nosubmit'} ${isMobile && 'mobile'}` },
        React.createElement(Table, { className: "popup-list-005", showIndex: false, page: 0, isInifinite: true, data: submitData, renderEmpty: notSubmit && renderEmpty, totalCount: submitData.length, headTitleList: headTitleList, styles: {
                headWrapper: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                headItem: {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }, forceShowheader: notSubmit ? false : true, renderItem: renderItem })));
});
