import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { enterRoom, getRoomOption, isRoomJoined } from '../../room';
import { toggleLoad } from '../loading';
import { getDisplayName } from '../settings';
import { appWillMount } from './actions';
import BaseApp from './components/BaseApp';
import { InitStyled } from './components/styles';
import { PAGE_TYPE } from './constants';
const BaseContainer = () => {
    const dispatch = useDispatch();
    // Redux state selectors
    const { roomUid, displayname, isJoined, appLoad, } = useSelector((state) => ({
        roomUid: getRoomOption(state)?.uuid,
        displayname: getDisplayName(state),
        isJoined: isRoomJoined(state),
        appLoad: !!state['features/base/app'].app,
    }), shallowEqual);
    // Other settings from redux
    const { enableLobby, enableNickname, individualLive, } = useSelector((state) => state['features/base/settings']);
    const { isDesktop } = useGlobalState();
    // Local state for managing current page
    const [page, setPage] = useState(location.pathname.split('/')[1] === 'room'
        ? location.hash.substring(1)
        : location.pathname.split('/')[1] || PAGE_TYPE.LOBBY);
    // Event handler to prevent context menu
    const handlerPreventMenu = (e) => e.preventDefault();
    // Lifecycle method when component mounts
    useEffect(() => {
        _mount();
        window.addEventListener('contextmenu', handlerPreventMenu);
        return () => {
            dispatch(toggleLoad(false));
            window.removeEventListener('contextmenu', handlerPreventMenu);
        };
    }, []);
    // Handle changes in individualLive setting
    useEffect(() => {
        if (individualLive) {
            window.addEventListener('beforeunload', quitUser);
        }
        return () => {
            window.removeEventListener('beforeunload', quitUser);
        };
    }, [individualLive]);
    // Subscribe to page update events
    useEffect(() => {
        if (appLoad)
            APP.eventManager.subscribe('UPDATE_PAGE', handlerPage);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_PAGE', handlerPage);
        };
    }, [appLoad]);
    // Method to initialize app
    const _mount = () => dispatch(appWillMount(true));
    // Method to handle page changes
    const handlerChangePage = (page) => {
        if (!appLoad)
            return;
        if (page === PAGE_TYPE.LOBBY) {
            if (!enableLobby)
                setPage(PAGE_TYPE.ERROR);
            else
                setPage(PAGE_TYPE.LOBBY);
        }
        else if (page === PAGE_TYPE.NICKNAME) {
            if (!enableNickname)
                setPage(PAGE_TYPE.DEVICE);
            else
                setPage(PAGE_TYPE.NICKNAME);
        }
        else {
            setPage(page);
        }
    };
    // Method to handle quitting user action
    const quitUser = async () => {
        await APP.API.quitMeeting();
    };
    // Event handler for page changes
    const handlerPage = (props) => {
        if (props.test) {
            setPage(props.page);
        }
        else {
            handlerChangePage(props.page);
        }
    };
    // Effect to handle initial load and room joining logic
    useEffect(() => {
        if (appLoad) {
            if (isJoined)
                handlerChangePage(PAGE_TYPE.MAIN);
            else {
                if (location.pathname.includes('room')) {
                    if (roomUid) {
                        if (navigator.product === 'ReactNative') {
                            if (!displayname)
                                handlerChangePage(PAGE_TYPE.NICKNAME);
                            // @ts-ignore
                            else
                                dispatch(enterRoom());
                        }
                        else {
                            if (!displayname)
                                handlerChangePage(PAGE_TYPE.NICKNAME);
                            else
                                handlerChangePage(PAGE_TYPE.DEVICE);
                        }
                    }
                }
                else {
                }
            }
        }
        else {
            dispatch(toggleLoad(true));
        }
    }, [appLoad, isJoined, roomUid, displayname]);
    // If app is not loaded yet, return null
    if (!appLoad)
        return null;
    // Render the BaseApp with current page and necessary props
    return (React.createElement(BrowserRouter, null,
        React.createElement(InitStyled, { isDesktop: isDesktop }),
        React.createElement(BaseApp, { page: page, roomUid: roomUid })));
};
export default BaseContainer;
