import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
const AnswerContainerStyled = styled.div `
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	gap: 10px;

	.submit {
		position: relative;
		cursor: pointer;

		.frame {
			border-radius: 50px;
			border-style: solid;
			border-color: var(--color-black, #101010);
			border-width: 1px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 28px;
			height: 28px;
		}
		.value {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-decoration: none; /* Prevents underline */
			cursor: default; /* Ensures the text does not act as a link */
			-webkit-touch-callout: none; /* Prevents touch callout on mobile */
			-webkit-user-select: none; /* Prevents selection */
			-moz-user-select: none; /* Prevents selection */
			-ms-user-select: none; /* Prevents selection */
			user-select: none; /* Prevents selection */
		}

		&.active {
			.frame {
				border-color: var(--color-pink, #f46790);
			}

			.value {
				color: var(--color-pink, #f46790);
			}
		}
	}
`;
export const AnswerContainer = ({ defaultValue = 0, isOnlyAnswer = false, maxCount, onChange, }) => {
    const [number, setNumber] = useState(defaultValue);
    useEffect(() => {
        if (number !== defaultValue) {
            setNumber(defaultValue);
        }
    }, [defaultValue]);
    const containers = useMemo(() => {
        if (isOnlyAnswer) {
            return (React.createElement("div", { className: "submit" },
                React.createElement("div", { className: "frame" },
                    React.createElement("div", { className: "value" }, defaultValue))));
        }
        const arr = [];
        for (let i = 0; i < maxCount; i++) {
            arr.push(React.createElement("div", { className: `submit ${number === i + 1 ? 'active' : ''}`, onClick: () => {
                    onChange(i + 1);
                    setNumber(i + 1);
                }, key: i },
                React.createElement("div", { className: "frame" },
                    React.createElement("div", { className: "value" }, (i + 1).toString()))));
        }
        return arr;
    }, [defaultValue, isOnlyAnswer, maxCount, number, onChange]);
    return React.createElement(AnswerContainerStyled, { className: "answer-container" }, containers);
};
