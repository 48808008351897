import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getSelectedSubjectListAndSelectSubject, getSubmitUserScoreByTestId } from '../../../test';
import MyPdfViewer from '../MyPdfViewer';
import { DetailQuestionContainer } from './DetailQuestionContainer';
import { ErrataTable } from './ErrataTable';
import { getIsTeacher } from '../../local';
export const OmrContainer = React.memo(({ testItem, notSubmit }) => {
    const isTeacher = useSelector((state) => getIsTeacher(state), shallowEqual);
    // 과제 정보 조회
    const score = useSelector((state) => getSubmitUserScoreByTestId(state, testItem.id), shallowEqual);
    const { isChoice } = useSelector((state) => getSelectedSubjectListAndSelectSubject(state), shallowEqual);
    console.log(isChoice, notSubmit);
    return (React.createElement("div", { className: "field-short file-container " },
        (testItem.status === 'closed' ? true : isChoice) &&
            testItem.attachments &&
            testItem.attachments.length > 0 && (React.createElement("div", { className: "test-area" },
            React.createElement("div", { className: "task-label overText" }, "\uD14C\uC2A4\uD2B8 \uD30C\uC77C"),
            React.createElement("div", { className: "fext-field" },
                React.createElement(MyPdfViewer, { attachments: testItem.attachments })))),
        React.createElement("div", { className: !notSubmit ? 'test-area' : 'test-area-before-submit', style: {
                flexDirection: 'column',
                height: '100%',
                alignItems: 'flex-start',
                width: '100%',
            } },
            React.createElement("div", { className: "task-label" },
                !isChoice && notSubmit ? '과목 선택' : '문항 풀이',
                testItem.status === 'open' && !notSubmit && ` - ${score}점`),
            React.createElement("div", { className: "fext-field" }, testItem.status === 'open' && notSubmit ? (React.createElement(DetailQuestionContainer, { test_id: testItem.id, subject: testItem.subject })) : (React.createElement(ErrataTable, { isDetail: true }))))));
});
