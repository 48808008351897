import i18next from 'i18next';
import { PAGE_TYPE } from '../../../features/base/app/constants';
import { addMessage, setIsChatRead, updateMessage } from '../../../features/base/chat/action';
import { getAccessToken, getLocalID } from '../../../features/base/local';
import { setAudioMuted, setVideoMuted } from '../../../features/base/media';
import { MEMBER_STATUS, PERMISSION, ROLE, addLocalMember, addMember, addMembers, checkRoleLevel, getLocalIDMember, getLocalMember, getLocalMemberRole, getMemberByUserID, getUserIDByMemberId, removeMember, requestPresentation, responsePresentation, setBridgeId, setHandlerMember, setMemberNick, setMemberPinned, setMemberRecord, setMemberRole, setSpeakerId, } from '../../../features/base/members';
import { saveNotification, showNotification } from '../../../features/base/notification/actions';
import { getPropertyValue, updateGridOption, updateRoomPolicy, updateSettings } from '../../../features/base/settings';
import { isChatOpend, isMemberOpend, setLiveEnquiries, toggleMember } from '../../../features/base/sidebar';
import { convertVodList } from '../../../features/base/vod/functions';
import { deleteCanvasIndex, deleteCanvaskey, setCanvasPoint } from '../../../features/canvas/actions';
import { connectionDual } from '../../../features/dualConference/action';
import { getRoomOption, setRoomPermissions } from '../../../features/room';
import { showStartAttendance } from '../../../features/room-attendance/actions';
import { getStartScreenShare, setShareScreenUserId, stopScreen } from '../../../features/screen-share';
import { addDocumentList, addShareItem, getDocumentItemByUUID, removeShareItem, setFocusShareItem, updateShareItem, } from '../../../features/share-layout';
import { DEFAULT_INDEX, DOCUMENT_MODE, VOD_MODE } from '../../../features/share-layout/constants';
import { VIDEO_MODE_LIST, getCurrentMode, setLayoutMode, videoLayoutMode } from '../../../features/video-layout';
import { createTaskQueue } from '../../util/helpers';
import PeerConnection from '../PeerConnection';
import { command } from './command';
import { onRefetchTestList } from '../../../features/test/action';
import { toggleLoad } from '../../../features/base/loading';
const isRemote = true;
const taskQueue = createTaskQueue();
class MateMessage {
    constructor() {
        this.initialEntrySetting = this.initialEntrySetting.bind(this);
    }
    async handleMessage(data, group_id) {
        switch (data.class) {
            case 'Event.Room.Member.Call.Offer':
                const from_member_id = data.payload.from;
                const from_user_id = getUserIDByMemberId(APP.store.getState, from_member_id);
                const peer = new PeerConnection(from_user_id, from_member_id, true);
                const { userSelectedCameraDeviceId } = APP.store.getState()['features/base/settings'];
                peer.initLocalStream(userSelectedCameraDeviceId, data.payload.offer);
                if (!Array.isArray(APP.peerconnections)) {
                    APP.peerconnections = [];
                }
                const isExitPeer = APP.peerconnections.find(peer => peer.user_id === from_user_id);
                try {
                    isExitPeer && (await isExitPeer.disconnect());
                    APP.peerconnections.push(peer);
                }
                catch (error) {
                    console.log(error);
                }
                break;
            case 'Event.Room.Member.Call.Answer':
                APP.eventManager.publish('CALL_ANSWER', data.payload);
                break;
            case 'Event.Room.Member.Call.IceCandidate':
                APP.eventManager.publish('CALL_CANDIDATE', data.payload);
                // console.log(APP.peerconnections);
                APP.peerconnections.forEach(peer => {
                    // console.log(peer);
                    // console.log(data.payload.from);
                    // console.log(data.payload.ice_candidate);
                    // console.log(peer.checkMember(data.payload.from));
                    if (peer.checkMember(data.payload.from)) {
                        peer.addIceCandidate(data.payload.ice_candidate);
                    }
                });
                break;
            case 'Meeting.Meeting.Closed':
            case 'Room.Member.Leave':
                APP.management.leave();
                self.close();
                APP.eventManager.publish('UPDATE_PAGE', {
                    page: PAGE_TYPE.ERROR,
                });
                break;
            case `Event.${command['set-nickname']}`:
                this.handlerChangeNick(data.payload);
                break;
            case `Event.Room.Notification.Receive`:
                try {
                    let recevieMessage = JSON.parse(data.payload.message)?.message;
                    APP.store.dispatch(saveNotification(recevieMessage, isRemote));
                }
                catch (error) { }
                break;
            // set bridge session
            case `Event.${command['set-jitsi-id']}`:
                this.handlerSetBridgeSession(data.payload);
                break;
            // 문서 상태 변경
            case 'Meeting.Document.StatusChanged':
                this.handlerDocumentStatus(data.payload);
                break;
            // 사용자 입장
            case `Event.${command['join-room']}ed`:
                taskQueue.enqueue(async (onFinish) => {
                    await this.handlerJoined(data.payload.message, data.payload.group_id);
                    onFinish();
                });
                break;
            // 사용자 퇴장 Event.Room.Member.Left
            case `Event.${command['left-member-room']}`:
                taskQueue.enqueue(async (onFinish) => {
                    await this.handlerLefted(data.payload.user_id, data.payload.group_id);
                    onFinish();
                });
                break;
        }
        switch (data.class) {
            case `Event.${command['member-kick']}ed`:
                this.handlerKickMember(data.payload);
                break;
            // 레이아웃 모드 변경 (grid, pin, voice, screen)
            case `Event.${command[videoLayoutMode.screen]}`:
                this.handlerScreenOption(data.payload);
                break;
            case `Event.${command[videoLayoutMode.grid]}`:
                this.handlerUpdateLayoutMode(videoLayoutMode.grid);
                break;
            case `Event.${command[videoLayoutMode.seminar]}`:
                this.handlerUpdateLayoutMode(videoLayoutMode.seminar);
                break;
            case `Event.${command[videoLayoutMode.pin]}`:
                this.handlerUpdateLayoutMode(videoLayoutMode.pin);
                break;
            case `Event.${command[videoLayoutMode.voice]}`:
                this.handlerUpdateLayoutMode(videoLayoutMode.voice);
                break;
            // 화면 가시성 레이아웃 모드 변경 ( screen, document, note, white, vad )
            case `Event.${command['set-layout-visiblity']}`:
                this.handlerUpdateViewVisible(data.payload);
                break;
            /**
             * share (document)
             */
            // 문서 focus
            case `Event.${command['set-vod-file']}`:
                break;
            case `Event.${command['del-vod-file']}`:
                break;
            case `Event.${command['set-document']}`:
                this.handlerUpdateDocument(data.payload);
                break;
            case `Event.${command['del-document']}`:
                this.handlerDelDocument(data.payload);
                break;
            case `Event.${command[videoLayoutMode.white]}`:
                this.handlerFocusItem(videoLayoutMode.white, data.payload);
                break;
            case `Event.${command[videoLayoutMode.document]}`:
                this.handlerFocusItem(videoLayoutMode.document, data.payload);
                break;
            case `Event.${command['set-canvas']}`:
                this.handlerSetShareCanvas(data.payload);
                break;
            case `Event.${command['del-canvas']}`:
                this.handlerDelShareCanvas(data.payload);
                break;
            case `Event.${command['focus-vod-item']}`:
                this.handlerFocusItem(videoLayoutMode.vod, data.payload);
                break;
            /**
             * 채팅
             */
            case `Event.${command['send-text']}`:
            case `Event.${command['send-private-text']}`:
                this.handlerSetMessage(data.payload);
                break;
            case `Event.${command['send-file']}`:
                this.handlerSetFile(data.payload);
                break;
            /**
             * Member
             */
            // 녹화 - recording
            case `Event.${command['set-member-record']}`:
                this.handlerRecording(data.payload);
                break;
            case `Event.${command[`set-audio-false`]}`:
            case `Event.${command[`set-all-audio-false`]}`:
                this.handlerUpdateAudio(false);
                break;
            case `Event.${command[`set-audio-true`]}`:
            case `Event.${command[`set-all-audio-true`]}`:
                this.handlerUpdateAudio(true);
                break;
            case `Event.${command[`set-video-false`]}`:
            case `Event.${command[`set-all-video-false`]}`:
                this.handlerUpdateVideo(false);
                break;
            case `Event.${command[`set-video-true`]}`:
            case `Event.${command[`set-all-video-true`]}`:
                this.handlerUpdateVideo(true);
                break;
            case `Event.${command['set-role']}`:
                this.handlerUpdateRole(data.payload.message, data.payload.group_id);
                break;
            /**
             * 발표자 요청
             */
            case `Event.${command['request-presentation']}`:
                this.handlerRequestPresentation(data.payload);
                break;
            case `Event.${command['response-presentation']}`:
                this.handlerResponsePresentation(data.payload);
                break;
            case `Event.${command['member-add-pin']}ed`:
                this.handlerUpdatePin(data.payload, true);
                break;
            case `Event.${command['member-del-pin']}d`:
                this.handlerUpdatePin(data.payload, false);
                break;
            case `Event.${command['set-handler-up']}`:
                this.handlerRaiseHand(data.payload, true);
                break;
            case `Event.${command['set-handler-down']}`:
                this.handlerRaiseHand(data.payload, false);
                break;
            /**
             * survey 설문좃사
             */
            case `Event.${command['share-survey']}`:
                this.notifySurvey(data.payload);
                break;
            case `Event.${command['reply-survey']}`:
                this.replySurvey(data.payload);
                break;
            /**
             * policy
             */
            case `Event.Room.Grid.Count.Set`:
                this.setGridOption(data.payload);
                break;
            case `Event.${command['set-policy']}d`:
                this.updatePolicy(data.payload);
                break;
            case 'Meeting.Meeting.Signaled':
                this.handlerMimac(data.payload);
                break;
        }
    }
    async handlerMimac(data) {
        const { class: classes } = data.payload;
        switch (classes) {
            case 'MIMAC.Conference.Finished':
                APP.management.leave('강의가 종료되었습니다.');
                break;
            case 'MIMAC.Test.Started':
            case 'MIMAC.Test.Finished':
            case 'MIMAC.Test.Submitted':
                APP.store.dispatch(onRefetchTestList());
                APP.store.dispatch(toggleLoad(false));
                break;
            case 'MIMAC.Enquiry.Queue.Accepted':
                APP.store.dispatch(setLiveEnquiries(data.payload.member_id));
                APP.eventManager.publish('UPDATE_LIVE_ENQUIRY', data.payload);
                break;
            case 'MIMAC.Enquiry.Quit':
                if (data.payload.enquiry_id === getRoomOption(APP.store.getState).uuid) {
                    const is_host = data.payload.is_host;
                    if (is_host) {
                        await APP.management.leave('1:1 라이브가 종료되었습니다');
                    }
                    else
                        APP.store.dispatch(setLiveEnquiries());
                }
                APP.eventManager.publish('UPDATE_LIVE_ENQUIRY', data.payload);
                break;
            case 'MIMAC.Enquiry.Queue.Changed':
                if (checkRoleLevel(getLocalMemberRole(APP.store.getState), ROLE.PRESENTER)) {
                    const openSide = isMemberOpend(APP.store.getState);
                    if (!openSide) {
                        APP.store.dispatch(toggleMember());
                    }
                }
                APP.eventManager.publish('UPDATE_LIVE_ENQUIRY', data.payload);
                break;
            case 'Enquiry.MembersChanged':
                APP.eventManager.publish('UPDATE_LIVE_ENQUIRY', data.payload);
                break;
        }
    }
    // 회의실 입장 초기 세팅
    initialEntrySetting(data) {
        return new Promise(async (resolve, reject) => {
            const { dispatch, getState } = APP.store;
            const user_uuid = getLocalID(getState);
            if (!user_uuid)
                return reject('인증에 실패했습니다.');
            const { member_uuid, layout, view_visible, role, permissions, pinned, members, document_list, video_list, hide_member_info, speaker_id, auto_set_document, last_notification, } = data;
            // try {
            // 	// 마이맥 api
            // 	const conferenceBackAPI = `/api/mimac/v1/conferences?id=${getRoomOption(getState).uuid}&with_more_info=true`;
            // 	const testResponse = await fetch(conferenceBackAPI)
            // 		.then(res => res.json())
            // 		.then(res => {
            // 			return res.result;
            // 		});
            // 	// VOD TEST, BACKGROUND
            // 	if (
            // 		testResponse &&
            // 		testResponse.items.length > 0 &&
            // 		testResponse.items[0].type &&
            // 		testResponse.items[0].type === 'mock_test'
            // 	) {
            // 		try {
            // 			const response = await fetch(
            // 				`/api/mimac/v1/tests?conference_id=${getRoomOption(getState).uuid}`,
            // 			)
            // 				.then(res => res.json())
            // 				.then(res => {
            // 					return res.result;
            // 				});
            // 			if (response) {
            // 				dispatch(setTestMode(true, testResponse.items[0].background, response.items[0].id));
            // 			}
            // 		} catch (error) {
            // 			console.log(error);
            // 		}
            // 	}
            // } catch (err) {}
            /**
             * Policy
             */
            const individualLive = hide_member_info && !auto_set_document;
            dispatch(updateSettings({ individualLive }));
            dispatch(setSpeakerId(speaker_id));
            if (individualLive && checkRoleLevel(role, ROLE.MANAGER)) {
                await APP.API.readyMeeting();
            }
            // 로컬 사용자 입장
            await dispatch(addLocalMember({
                user_uuid,
                member_uuid,
                role,
                permissions,
                hidden: false,
                pinned,
            }));
            // members 배열의 각 멤버 객체에 isLocal 속성 추가
            const response = await fetch(`/api/meeting/v1/members?meeting_id=${getRoomOption(getState).uuid}&offset=0&limit=${members.length}`)
                .then(res => res.json())
                .then(res => {
                return res.result.items;
            });
            const updatedMembers = members.map(async (member) => {
                const item = response.find(i => i.user_uuid === member.user_uuid);
                if (item) {
                    await dispatch(addMember({
                        ...member,
                        nickname: item?.nickname || '',
                    }));
                }
                else {
                    await dispatch(addMember({
                        ...member,
                        nickname: member.nickname || '',
                    }));
                }
            });
            // addMembers 함수 호출
            await dispatch(addMembers(updatedMembers, member_uuid));
            dispatch(setRoomPermissions(permissions));
            if (last_notification) {
                const { message, group_id: last_group } = JSON.parse(last_notification);
                if (last_group === '') {
                    dispatch(saveNotification(message, isRemote));
                }
                else {
                    dispatch(showNotification({
                        titleKey: 'notify.notifyMessage',
                        description: message,
                    }));
                }
            }
            let list = [];
            if (APP.config.name === 'class') {
                const response = await APP.API.getKlassDocument();
                if (response.complete) {
                    response.data &&
                        response.data.map(item => {
                            if (document_list.find(dItem => dItem.uuid === item.uuid))
                                return;
                            const data = {
                                uuid: item.uuid,
                                user_uuid,
                                file: {
                                    name: item.filename,
                                },
                                isWhiteBoard: false,
                                shared: true,
                                page: item.resource_id,
                                list: item.completed_pages,
                                count: item.completed_pages.length,
                                complete: true,
                                config: DOCUMENT_MODE,
                            };
                            dispatch(addShareItem(data, false));
                        });
                }
            }
            /**
             * (SCREEN MODE) 화면 공유
             */
            const screen_track_id = data.screen_shared_jitsi_id;
            if (screen_track_id) {
                const screen_member_id = data.screen_shared_member_uuid;
                const screen_user_id = getMemberByUserID(getState, screen_member_id);
                dispatch(setShareScreenUserId(screen_track_id, screen_user_id || screen_member_id, isRemote));
            }
            /**
             * video-layout
             */
            // 레이아웃 저장
            const mode = getCurrentMode(getState, { mode: layout, viewVisible: view_visible });
            dispatch(setLayoutMode(mode, isRemote));
            dispatch(updateSettings({ hide_member_info }));
            /**
             * Share
             */
            const isSharedPermission = permissions.includes(PERMISSION.SHARE);
            const values = Object.entries(video_list).reduce((acc, [v, itemList]) => acc.concat(itemList), []);
            const vod_list = await convertVodList(values, document_list);
            const filteredVodConfigs = vod_list.filter(itemA => {
                return document_list.some(itemB => itemB.uuid === itemA.uuid);
            });
            const otherConfigs = document_list.filter(itemA => itemA.config !== VOD_MODE);
            // Document list 저장
            dispatch(addDocumentList([...list, ...vod_list, ...filteredVodConfigs, ...otherConfigs], member_uuid, isSharedPermission));
            // 함수 사용
            getMeetingIceServer()
                .then(iceServerData => {
                // ICE 서버 데이터로 무언가를 수행
                dispatch(updateSettings({ iceServer: iceServerData }));
            })
                .catch(error => {
                // 에러 처리
                APP.UI.alertMessage('듀얼모니터 서버 설정에 실패했습니다.');
            });
            // share document
            if (APP.config.option.workbook) {
                if (data.document_focus_item) {
                    const { uuid, index } = data.document_focus_item;
                    const focusUId = uuid === '' ? data.whiteboard_focus_item.uuid : uuid;
                    const focusIndex = uuid === '' ? data.whiteboard_focus_item.index : index;
                    dispatch(setFocusShareItem(focusUId, focusIndex, videoLayoutMode.document, isRemote));
                }
            }
            else {
                if (data.document_focus_item) {
                    const { uuid, index } = data.document_focus_item;
                    dispatch(setFocusShareItem(uuid, index, videoLayoutMode.document, isRemote));
                }
                if (data.whiteboard_focus_item) {
                    const { uuid, index } = data.whiteboard_focus_item;
                    dispatch(setFocusShareItem(uuid, index, videoLayoutMode.white, isRemote));
                }
            }
            if (data.vod_shared_video_uuid) {
                dispatch(setFocusShareItem(data.vod_shared_video_uuid, DEFAULT_INDEX, videoLayoutMode.vod, isRemote));
            }
            dispatch(updateRoomPolicy(individualLive ? { ...data.policy, pin_option: 'auto' } : data.policy, checkRoleLevel(role, ROLE.MANAGER) ? false : true));
            return resolve(true);
        });
    }
    /**
     * 사용자 입장
     */
    async handlerJoined(message) {
        try {
            if (message.type !== 'hidden') {
                APP.eventManager.publish('UPDATE_LIVE_ENQUIRY');
                const { dispatch } = APP.store;
                const remote = {
                    ...message,
                    local: false,
                    status: MEMBER_STATUS.OCCUPIDE,
                    existed: false,
                };
                await dispatch(addMember(remote));
            }
        }
        catch (error) {
            APP.UI.alertMessage('error');
        }
    }
    async handlerLefted(user_id) {
        try {
            const { getState, dispatch } = APP.store;
            const member = getMemberByUserID(getState, user_id);
            if (member && !member?.local) {
                await APP.store.dispatch(removeMember(member));
            }
        }
        catch (error) {
            APP.UI.alertMessage('error');
        }
    }
    handlerSetBridgeSession({ message }) {
        // from -> member_uuid
        const bridge_sessions = message.bridge_sessions;
        const user_uuid = message.user_id;
        if (bridge_sessions && bridge_sessions.length > 0)
            APP.store.dispatch(setBridgeId(user_uuid, bridge_sessions[0]));
    }
    /**
     * (MEMBER) 사용자 녹화 알림.
     * @param {object} data
     */
    handlerRecording(data) {
        const { getState, dispatch } = APP.store;
        const { to, recording } = data;
        const user_uuid = getUserIDByMemberId(getState, to);
        if (user_uuid) {
            dispatch(setMemberRecord(user_uuid, recording, isRemote));
        }
    }
    /**
     * (LAYOUT)
     * 레이아웃 모드 변경
     * @param {string} mode
     */
    handlerUpdateLayoutMode(mode) {
        APP.store.dispatch(setLayoutMode(mode, isRemote));
    }
    /**
     * (LAYOUT)
     * 화면 가시성으로 레이아웃 모드 변경
     * @param {object} {view_visible}
     */
    handlerUpdateViewVisible({ view_visible }) {
        let mode = null;
        Object.keys(view_visible).map(v => {
            if (view_visible[v])
                mode = v;
        });
        if (!mode) {
            const currentMode = getCurrentMode(APP.store.getState);
            if (VIDEO_MODE_LIST.includes(currentMode))
                mode = currentMode;
            else
                mode = videoLayoutMode.default;
        }
        mode && this.handlerUpdateLayoutMode(mode);
    }
    /**
     * (TRACK)
     * 사용자 오디오 ON / OFF
     * @param {Boolean} muted
     */
    handlerUpdateAudio(muted) {
        APP.store.dispatch(setAudioMuted(muted));
    }
    /**
     * (TRACK)
     * 사용자 비디오 ON / OFF
     * @param {Boolean} muted
     */
    handlerUpdateVideo(muted) {
        APP.store.dispatch(setVideoMuted(muted));
    }
    /**
     * (MEMBER)
     * 사용자 권한 업데이트
     * @param {object} data
     */
    handlerUpdateRole(data, group_id) {
        const { target, role, permissions } = data;
        APP.store.dispatch(setMemberRole(target, role, isRemote, group_id !== '' ? true : false));
        if (target === getLocalID(APP.store.getState)) {
            // 회의실 내 권한 세팅
            APP.store.dispatch(setRoomPermissions(permissions));
        }
    }
    /**
     * (MEMBER)  발표자 권한 요청
     * @param {object} data
     * @returns
     */
    handlerRequestPresentation(data) {
        const { from } = data;
        APP.store.dispatch(requestPresentation(false, from));
    }
    /**
     * (MEMBER) 사용자 발표 응담
     * @param {object} data
     */
    handlerResponsePresentation(data) {
        const { to, accepted } = data;
        const user_uuid = getUserIDByMemberId(APP.store.getState, to);
        APP.store.dispatch(responsePresentation(user_uuid, accepted, isRemote));
    }
    /**
     * (MEMBER) 사용자 PIN 변경
     * @param {object} data
     * @param {Boolean} pinned
     */
    handlerUpdatePin(data, pinned) {
        APP.store.dispatch(setMemberPinned(data.message.target, pinned, isRemote));
    }
    /**
     * (MEMBER) 사용자 강제 퇴장
     * @param {object} data
     */
    async handlerKickMember(data) {
        const { getState, dispatch } = APP.store;
        const { to, from, type } = data;
        if (type === 'close') {
            await APP.management.leave();
        }
        else {
            const toUserId = getUserIDByMemberId(getState, to);
            const fromUserId = getUserIDByMemberId(getState, from);
            const localID = getLocalID(getState);
            if (localID === toUserId) {
                if (fromUserId !== toUserId) {
                    const fromMember = getMemberByUserID(getState, fromUserId);
                    const displayName = fromMember?.nickname;
                    const individualLive = getPropertyValue(getState, 'individualLive');
                    const text = i18next.t(individualLive ? 'notify.closeLive' : 'notify.kickMember', { displayName });
                    await APP.management.leave(text);
                }
                else {
                    await APP.management.leave(i18next.t('notify.dupLogin'), true);
                }
            }
        }
    }
    /**
     * (MEMBER) 사용자 닉네임 수정
     * @param {*} data
     */
    handlerChangeNick(data) {
        const user_id = getUserIDByMemberId(APP.store.getState, data.from);
        if (user_id) {
            APP.store.dispatch(setMemberNick(user_id, data.message.nickname, isRemote));
        }
    }
    /**
     * (MEMBER) 손들기
     * @param {object} data
     * @param {Boolean} handler
     */
    handlerRaiseHand(data, handler) {
        const { getState, dispatch } = APP.store;
        const { to, message } = data;
        const user_uuid = getUserIDByMemberId(getState, to);
        user_uuid && dispatch(setHandlerMember(user_uuid, handler, message?.at, isRemote));
    }
    /**
     * (SCREEN SHARE)
     * 화면 공유 옵션 업데이트
     * @param {object} data
     */
    handlerScreenOption(data) {
        const { target, jitsi_id } = data;
        const changeJitsi = !jitsi_id || jitsi_id === '' ? null : jitsi_id;
        const changeTarge = !target || target === '' ? null : target;
        if (!changeJitsi && getStartScreenShare(APP.store.getState)) {
            APP.store.dispatch(stopScreen());
            return;
        }
        APP.store.dispatch(setShareScreenUserId(changeJitsi, changeTarge, isRemote));
    }
    /**
     * 문서
     */
    /**
     * 현재 문서 공유시 공유 업데이트
     */
    handlerUpdateDocument(data) {
        const { dispatch, getState } = APP.store;
        const { document } = data;
        const user_uuid = getMemberByUserID(getState, document.member_uuid);
        const localId = getLocalIDMember(getState);
        if (document.shared || user_uuid === localId) {
            dispatch(updateShareItem(document.uuid, {
                ...document,
                user_uuid,
            }, isRemote));
        }
    }
    /**
     * 문서 삭제
     * @param {*} data
     */
    handlerDelDocument(data) {
        APP.store.dispatch(removeShareItem(data.uuid, isRemote));
    }
    /**
     * 문서 변환 완료 시 상태 변경
     * @param {object} {data}
     */
    handlerDocumentStatus(data) {
        const { uuid, resource_id, status: documentStatus, completed_pages, complete } = data;
        if (documentStatus === 'failed') {
            APP.UI.alertMessage('share.no_converting');
            APP.store.dispatch(removeShareItem(uuid, isRemote));
            return;
        }
        const document = getDocumentItemByUUID(APP.store.getState, uuid);
        if (document) {
            if (completed_pages.length >= 1) {
                APP.store.dispatch(updateShareItem(uuid, {
                    uuid,
                    page: resource_id,
                    count: completed_pages.length,
                    list: completed_pages,
                    complete: true,
                    isWhiteBoard: false,
                }));
            }
        }
    }
    /**
     * 문서 포커스
     * @param {string} mode
     * @param {object<{uuid, index}>} data
     */
    handlerFocusItem(mode, { video_uuid, uuid, index }) {
        if (mode === videoLayoutMode.vod) {
            APP.store.dispatch(setFocusShareItem(video_uuid, DEFAULT_INDEX, mode, isRemote));
        }
        else {
            APP.store.dispatch(setFocusShareItem(uuid, index, mode, isRemote));
        }
    }
    /**
     * 문서 캔버스
     * @param {object<{ uuid, index, key, value }}} data
     */
    handlerSetShareCanvas(data) {
        const { uuid, index, key, value } = data;
        APP.store.dispatch(setCanvasPoint({ uuid, index: Number(index), key, ...value }, isRemote));
    }
    /**
     * 문서 캔버스 지우기
     * @param {*} data
     */
    handlerDelShareCanvas(data) {
        const { uuid, index, key, isAll } = data;
        if (!isAll)
            APP.store.dispatch(deleteCanvaskey(uuid, Number(index), key, isRemote));
        else
            APP.store.dispatch(deleteCanvasIndex(uuid, Number(index), isRemote));
    }
    /**
     * 채팅
     */
    handlerSetMessage({ message, time }) {
        const { dispatch, getState } = APP.store;
        if (!isChatOpend(getState)) {
            dispatch(setIsChatRead(false));
            return;
        }
        if (getState()['features/base/sidebar'].hideChat)
            return;
        else {
            const data = JSON.parse(message);
            const local = getLocalMember(getState);
            const kstTimestamp = new Date(time).getTime();
            if (data?.from === local?.user_uuid) {
                // dispatch(updateMessage(data, local));
                dispatch(updateMessage({ ...data, isMe: true, timestamp: kstTimestamp }));
            }
            else {
                dispatch(addMessage({ ...data, timestamp: kstTimestamp }, false));
            }
        }
    }
    handlerSetFile({ message }) {
        const data = JSON.parse(message);
        if (data?.type === 'dualConnection') {
            dispatch(connectionDual(data));
            return;
        }
        // APP.store.dispatch(addShareFile(message, isRemote));
    }
    /**
     * (POLICY)
     * 현재 방 설정
     * @param {object} policy
     * @returns
     */
    updatePolicy({ policy }) {
        APP.store.dispatch(updateRoomPolicy(policy, isRemote));
    }
    /**
     * (POLICY)
     * 그리드 옵션 설정
     */
    setGridOption({ grid_count }) {
        APP.store.dispatch(updateGridOption(null, grid_count, isRemote));
    }
    handlerStartAttendances(data) {
        const { name, uuid, check_duration } = data;
        APP.store.dispatch(showStartAttendance({ name, uuid, check_duration }));
    }
}
const getMeetingIceServer = async () => {
    try {
        const meeting_id = getRoomOption(APP.store.getState).uuid;
        if (!meeting_id) {
            throw new Error('Meeting ID is not available');
        }
        const accessToken = getAccessToken(APP.store);
        if (!accessToken || accessToken === '') {
            throw new Error('Access token is not available');
        }
        const response = await fetch(`/api/meeting/v1/meetings/${meeting_id}/ice-servers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('ICE server data:', data.result.ice_servers);
        return data.result.ice_servers;
    }
    catch (error) {
        console.error('Error fetching ICE server:', error);
        // 여기서 에러를 처리하거나 상위 컴포넌트로 throw할 수 있습니다.
        return APP.config.ice_servers;
    }
};
export default new MateMessage();
