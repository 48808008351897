import React from 'react';
import SurveyResultItem from './SurveyResultItem';
import { shallowEqual, useSelector } from 'react-redux';
import { getQuestionList, getSurveyData } from '../../test';
import { objectToQueryString } from './functions';
import { useDispatch } from 'react-redux';
import { setSurveyFrequency } from '../../test/action';
import { getSurveyFrequency } from './querys';
// SurveyLayout 컴포넌트
export const SurveyResultLayout = React.memo(({ testItem, isRegist, isSubmit }) => {
    const dispatch = useDispatch();
    const questionList = useSelector((state) => getQuestionList(state), shallowEqual);
    const [frequencies, setFrequencies] = React.useState(undefined);
    // const frequencies = useSelector((state: IStore['getState']) => getSurveyFrequency(state), shallowEqual);
    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    const getFrequency = async () => {
        const frequencyQueryString = objectToQueryString({
            test_id: testItem.id,
        });
        try {
            const response = await getSurveyFrequency(frequencyQueryString);
            setFrequencies(response.result);
            dispatch(setSurveyFrequency(response.result));
        }
        catch (error) {
            console.error('설문 빈도 조회 실패:', error);
        }
    };
    const [surveyData, setSurveyData] = React.useState([]);
    React.useEffect(() => {
        getFrequency();
        const data = getSurveyData(testItem, questionList, []);
        setSurveyData(data);
    }, [testItem, questionList]);
    return frequencies && !isEmptyObject(frequencies) ? (surveyData.map((item, index) => (React.createElement(SurveyResultItem, { key: index, index: index, item: item, isSubmit: isSubmit, isRegist: isRegist, frequencies: frequencies })))) : (React.createElement("div", { style: { flex: 1, alignSelf: 'center', justifySelf: 'center' } }, "\uC81C\uCD9C\uB41C \uC124\uBB38\uC774 \uC5C6\uC2B5\uB2C8\uB2E4."));
});
