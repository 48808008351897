import React from 'react';
import LayoutSort from '../../../../assets/icons/common/ic-layout-sort.svg';
import ArrowIcon from '../../../../assets/icons/common/ic-arrow.svg';
import TwoArrowIcon from '../../../../assets/icons/common/ic-2arrow.svg';
import Fail from '../../../../assets/icons/common/ic-union.svg';
import Pass from '../../../../assets/icons/common/ic-pass.svg';
import LiveTest from '../../../../assets/icons/common/icon-live-test.svg';
import MenuPdf from '../../../../assets/icons/common/ic-menu.svg';
import ChatLock from '../../../../assets/icons/chat/icon-control-mutechat.svg';
import LobbyEnter from '../../../../assets/icons/common/lobby_enter.svg';
import LobbyReservation from '../../../../assets/icons/common/lobby_reservation.svg';
import LobbyCreate from '../../../../assets/icons/common/lobby_create.svg';
import NickEnter from '../../../../assets/icons/common/nickname_enter.svg';
import Info from '../../../../assets/icons/common/info.svg';
import ChatInfo from '../../../../assets/icons/layout/chat.svg';
import Logo from '../../../../assets/icons/common/logo.svg';
import PreviewEnterIcon from '../../../../assets/icons/common/preview_enter.svg';
import NoList from '../../../../assets/icons/common/ic_no_list.svg';
import PageNext from '../../../../assets/icons/common/ic_next.svg';
import PagePrevious from '../../../../assets/icons/common/ic_previous.svg';
import Enter from '../../../../assets/icons/common/ic_enter.svg';
import EditRoom from '../../../../assets/icons/common/ic_edit.svg';
import DeleteRoom from '../../../../assets/icons/common/ic_delete.svg';
import DetailRoom from '../../../../assets/icons/common/ic_detail.svg';
import BackArrow from '../../../../assets/icons/common/back arrow.svg';
import CommonAdd from '../../../../assets/icons/common/ic_add.svg';
import CommonDel from '../../../../assets/icons/common/ic_close.svg';
import DeviceConnect from '../../../../assets/icons/common/ic_link.svg';
import Video from '../../../../assets/icons/common/ic_video.svg';
import Speaker from '../../../../assets/icons/common/ic_speaker.svg';
import Mic from '../../../../assets/icons/common/ic_mic.svg';
import Id from '../../../../assets/icons/common/ic_id.svg';
import Password from '../../../../assets/icons/common/ic_password.svg';
import Logout from '../../../../assets/icons/common/ic_logout.svg';
import StartMeetingOff from '../../../../assets/icons/common/ic_startmeeting_off.svg';
import StartMeetingOn from '../../../../assets/icons/common/ic_startmeeting_on.svg';
import ReservationMeetingOn from '../../../../assets/icons/common/ic_reservationmeeting_on.svg';
import ReservationMeetingOff from '../../../../assets/icons/common/ic_reservationmeeting_off.svg';
import VodMeetingOn from '../../../../assets/icons/common/ic_vod_on.svg';
import VodMeetingOff from '../../../../assets/icons/common/ic_vod_off.svg';
import EndMeetingOff from '../../../../assets/icons/common/ic_endmeeting_off.svg';
import EndMeetingOn from '../../../../assets/icons/common/ic_endmeeting_on.svg';
import ALLShow from '../../../../assets/icons/common/ic_all.svg';
import NoCamera from '../../../../assets/icons/common/icon-no-camera.svg';
import NoUser from '../../../../assets/icons/common/icon-no-user.svg';
import OffCamer from '../../../../assets/icons/common/icon-off-camera.svg';
import ClassDocumentModeIcon from '../../../../assets/icons/class/document.svg';
import ClassVideoIcon from '../../../../assets/icons/class/ic_camera.svg';
import ClassVodModeIcon from '../../../../assets/icons/class/ic_vod.svg';
import DocumentModeIcon from '../../../../assets/icons/layout/document.svg';
import GridModeIcon from '../../../../assets/icons/layout/grid.svg';
import NoteModeIcon from '../../../../assets/icons/layout/note.svg';
import PinModeIcon from '../../../../assets/icons/layout/presenter.svg';
import ScreenModeIcon from '../../../../assets/icons/layout/screen.svg';
import SeminarModeIcon from '../../../../assets/icons/layout/seminar.svg';
import VadModeIcon from '../../../../assets/icons/layout/vad.svg';
import VideoIcon from '../../../../assets/icons/layout/video.svg';
import VodModeIcon from '../../../../assets/icons/layout/vod.svg';
import Chat from '../../../../assets/icons/layout/chat.svg';
import Exit from '../../../../assets/icons/layout/exit.svg';
import Member from '../../../../assets/icons/layout/member.svg';
import Personnel from '../../../../assets/icons/layout/personnel.svg';
import Setting from '../../../../assets/icons/layout/setting.svg';
import Time from '../../../../assets/icons/layout/time.svg';
import Wifi from '../../../../assets/icons/layout/wifi.svg';
import Presenter from '../../../../assets/icons/member/presentation.svg';
import AudioMuted from '../../../../assets/icons/member/audio_mute.svg';
import AudioUnMuted from '../../../../assets/icons/member/audio_unmute.svg';
import VideoMuted from '../../../../assets/icons/member/video_mute.svg';
import VideoUnMuted from '../../../../assets/icons/member/video_unmute.svg';
import MicChange from '../../../../assets/icons/options/mic_change.svg';
import ModeChange from '../../../../assets/icons/options/mode_change.svg';
import VideoChange from '../../../../assets/icons/options/video_change.svg';
import Close from '../../../../assets/icons/layout/close.svg';
import Open from '../../../../assets/icons/layout/open.svg';
import Pinned from '../../../../assets/icons/member/pinned.svg';
import Delete from '../../../../assets/icons/share/delete.svg';
import DocumentAdd from '../../../../assets/icons/share/document_add.svg';
import Previous from '../../../../assets/icons/share/previous.svg';
import Share from '../../../../assets/icons/share/share.svg';
import WhiteBoardAdd from '../../../../assets/icons/share/whiteboard_add.svg';
import FileArrowUp from '../../../../assets/icons/canvas/FileArrowUp.svg';
import AllEraser from '../../../../assets/icons/canvas/all_eraser.svg';
import Choice from '../../../../assets/icons/canvas/choice.svg';
import Circle from '../../../../assets/icons/canvas/circle.svg';
import Color from '../../../../assets/icons/canvas/color.svg';
import Eraser from '../../../../assets/icons/canvas/eraser.svg';
import Figure from '../../../../assets/icons/canvas/figure.svg';
import FillCircle from '../../../../assets/icons/canvas/fill_circle.svg';
import FillRectangle from '../../../../assets/icons/canvas/fill_rectangle.svg';
import FillTriangle from '../../../../assets/icons/canvas/fill_triangle.svg';
import FitHeight from '../../../../assets/icons/canvas/fitHeight.svg';
import FitPage from '../../../../assets/icons/canvas/fitPage.svg';
import FitWidth from '../../../../assets/icons/canvas/fitWidth.svg';
import Handler from '../../../../assets/icons/canvas/handler.svg';
import LineEraser from '../../../../assets/icons/canvas/line_eraser.svg';
import Menu from '../../../../assets/icons/canvas/menu.svg';
import MenuOpen from '../../../../assets/icons/canvas/menu_open.svg';
import Minus from '../../../../assets/icons/canvas/minus.svg';
import Opacity from '../../../../assets/icons/canvas/opacity.svg';
import PartEraser from '../../../../assets/icons/canvas/part_eraser.svg';
import Pen from '../../../../assets/icons/canvas/pen.svg';
import Plus from '../../../../assets/icons/canvas/plus.svg';
import Rectangle from '../../../../assets/icons/canvas/rectangle.svg';
import Text from '../../../../assets/icons/canvas/text.svg';
import Thickness from '../../../../assets/icons/canvas/thickness.svg';
import Triangle from '../../../../assets/icons/canvas/triangle.svg';
import Zoom from '../../../../assets/icons/canvas/zoom.svg';
import ClassColor from '../../../../assets/icons/class/color.svg';
import ClassEraser from '../../../../assets/icons/class/eraser.svg';
import ClassPen from '../../../../assets/icons/class/pen.svg';
import ClassText from '../../../../assets/icons/class/text.svg';
import CheckBoxCheck from '../../../../assets/icons/common/ic-checked.svg';
import CheckBoxUnCheck from '../../../../assets/icons/common/ic-unchecked.svg';
import Checked from '../../../../assets/icons/chat/checked.svg';
import ChatClose from '../../../../assets/icons/chat/close.svg';
import Down from '../../../../assets/icons/chat/down.svg';
import Download from '../../../../assets/icons/chat/download.svg';
import Etc from '../../../../assets/icons/chat/etc.svg';
import File from '../../../../assets/icons/chat/file.svg';
import RaiseHand from '../../../../assets/icons/chat/handler.svg';
import FileDownOff from '../../../../assets/icons/chat/ic_filedownload_off.svg';
import FileDownOn from '../../../../assets/icons/chat/ic_filedownload_on.svg';
import ChatSave from '../../../../assets/icons/chat/ic_savechat.svg';
import Invite from '../../../../assets/icons/chat/invite.svg';
import Search from '../../../../assets/icons/chat/search.svg';
import Up from '../../../../assets/icons/chat/up.svg';
import DualMonitor from '../../../../assets/icons/options/dual.svg';
import Group from '../../../../assets/icons/options/group.svg';
import UrlAdd from '../../../../assets/icons/options/ic_url.svg';
import FileAdd from '../../../../assets/icons/options/ic_addfile.svg';
import RoomFileAdd from '../../../../assets/icons/options/ic_roomaddfile.svg';
import YoutubeAdd from '../../../../assets/icons/options/ic_addyoutube.svg';
import Attendance from '../../../../assets/icons/options/ic_attendance.svg';
import E2EE from '../../../../assets/icons/options/ic_e2ee.svg';
import Option from '../../../../assets/icons/options/ic_option.svg';
import Emoji from '../../../../assets/icons/options/ico_emoji.svg';
import MemberAdd from '../../../../assets/icons/options/member_add.svg';
import Security from '../../../../assets/icons/options/security.svg';
import SelfRecord from '../../../../assets/icons/options/self_record.svg';
import ServerRecord from '../../../../assets/icons/options/server_record.svg';
import SettingDevice from '../../../../assets/icons/options/set_device.svg';
import AllAudioOff from '../../../../assets/icons/options/all_audio_off.svg';
import AllAudioOn from '../../../../assets/icons/options/all_audio_on.svg';
import AllPermissionSetting from '../../../../assets/icons/options/all_permission_setting.svg';
import AllVideoOff from '../../../../assets/icons/options/all_video_off.svg';
import AllVideoOn from '../../../../assets/icons/options/all_video_on.svg';
import FullScreenOff from '../../../../assets/icons/options/fullscreen_off.svg';
import FullScreenOn from '../../../../assets/icons/options/fullscreen_on.svg';
import Modify from '../../../../assets/icons/options/modify.svg';
import VodDown from '../../../../assets/icons/common/ic_download.svg';
import VodShow from '../../../../assets/icons/common/ic_view.svg';
import NickCompletion from '../../../../assets/icons/options/ic_completion.svg';
import NickEdit from '../../../../assets/icons/options/ic_edit.svg';
import VodPause from '../../../../assets/icons/options/ic_pause.svg';
import VodStart from '../../../../assets/icons/options/ic_play.svg';
import VodVolumeOff from '../../../../assets/icons/options/ic_soundoff.svg';
import VodVolumeOn from '../../../../assets/icons/options/ic_soundon.svg';
import Blur from '../../../../assets/icons/options/ic_blur.svg';
import Survey from '../../../../assets/icons/options/ic_survey.svg';
import NotiClose from '../../../../assets/icons/options/ic_close.svg';
import Notification from '../../../../assets/icons/options/ic_notice.svg';
import NotiOpen from '../../../../assets/icons/options/ic_open.svg';
import NoMember from '../../../../assets/icons/options/img_noperson.svg';
import NoRecord from '../../../../assets/icons/options/img_novideo.svg';
import Accept from '../../../../assets/icons/common/icon-accept.svg';
import Reject from '../../../../assets/icons/common/icon-refuse.svg';
// @ts-ignore
import RadioChecked from '../../../../assets/icons/common/ic-radio-checked.svg';
// @ts-ignore
import RadioUnchecked from '../../../../assets/icons/common/ic-radio-unchecked.svg';
// import CheckIcon from '../../assest/icon/ic-checked.svg';
import CheckIcon from '../../../../assets/icons/common/ic-checked.svg';
//@ts-ignore
import UnCheckedIcon from '../../../../assets/icons/common/ic-unchecked.svg';
// @ts-ignore
import FileDown from '../../../../assets/icons/common/ic-file-down.svg';
// @ts-ignore
import Cancel from '../../../../assets/icons/common/ic-cancel.svg';
const Icon = React.memo(({ className, style, icon, size = 20, width = size, height = size, fill, stroke, color }) => {
    color = color || fill;
    switch (icon) {
        case 'icon-not-camera':
            return React.createElement(NoCamera, { width: size, height: size, fill: color || fill });
        case 'icon-not-user':
            return React.createElement(NoUser, { width: size, height: size, fill: color || fill });
        case 'icon-off-camera':
            return React.createElement(OffCamer, { width: size, height: size, fill: color || fill });
        case 'chat_lock':
            return React.createElement(ChatLock, { width: size, height: size, fill: color || fill });
        case 'radio_checked':
            return React.createElement(RadioChecked, { width: width, height: height, stroke: stroke, fill: color || fill });
        case 'radio_unchecked':
            return React.createElement(RadioUnchecked, { width: width, height: height, stroke: stroke, fill: color || fill });
        case 'checkbox_checked':
            return React.createElement(CheckBoxCheck, { width: size, height: size, fill: color || fill });
        case 'checkbox_unchecked':
            return React.createElement(CheckBoxUnCheck, { width: size, height: size, fill: color || fill });
        case 'logout':
            return React.createElement(Logout, { width: width, height: size, fill: color || fill });
        case 'logo':
            return React.createElement(Logo, { width: 130, height: 55, fill: color || fill });
        case 'mobile_logo':
            if (size === 72) {
                return React.createElement(Logo, { width: 72, height: 40, fill: color || fill });
            }
            else {
                return React.createElement(Logo, { width: 130, height: 40, fill: color || fill });
            }
        case 'test':
            return React.createElement(LiveTest, { width: size, height: size, fill: color || fill });
        case 'menu-pdf':
            return React.createElement(MenuPdf, { width: size, height: size, fill: color || fill });
        case 'lobby_create':
            return React.createElement(LobbyCreate, { width: size, height: size, fill: color || fill });
        case 'lobby_enter':
            return React.createElement(LobbyEnter, { width: size, height: size, fill: color || fill });
        case 'lobby_reservation':
            return React.createElement(LobbyReservation, { width: size, height: size, fill: color || fill });
        case 'nickname_enter':
            return React.createElement(NickEnter, { width: size, height: size, fill: color });
        case 'no_list':
            return React.createElement(NoList, { width: size, height: size, fill: color || fill });
        case 'page_next':
            return React.createElement(PageNext, { width: size, height: size, fill: color || fill });
        case 'page_previous':
            return React.createElement(PagePrevious, { width: size, height: size, fill: color || fill });
        case 'info':
        case 'error':
            return React.createElement(Info, { width: size, height: size, fill: color || fill });
        case 'chat_info':
            return React.createElement(ChatInfo, { width: size, height: size, fill: color || fill });
        case 'enter_room':
            return React.createElement(Enter, { width: size, height: size, fill: color || fill });
        case 'edit_room':
            return React.createElement(EditRoom, { width: size, height: size, fill: color || fill });
        case 'delete_room':
            return React.createElement(DeleteRoom, { width: size, height: size, fill: color || fill });
        case 'detail_room':
            return React.createElement(DetailRoom, { width: size, height: size, fill: color || fill });
        case 'back_arrow':
            return React.createElement(BackArrow, { width: size, height: size, fill: color || fill });
        case 'common_add':
            return React.createElement(CommonAdd, { width: size, height: size, fill: color || fill });
        case 'common_del':
            return React.createElement(CommonDel, { width: size, height: size, stroke: color });
        case 'device_connect':
            return React.createElement(DeviceConnect, { width: size, height: size, fill: color || fill });
        case 'video':
            return React.createElement(Video, { width: size, height: size, fill: color || fill });
        case 'mic':
            return React.createElement(Mic, { width: size, height: size, fill: color || fill });
        case 'speaker':
            return React.createElement(Speaker, { width: size, height: size, fill: color || fill });
        case 'id':
            return React.createElement(Id, { width: size, height: size, fill: color || fill });
        case 'password':
            return React.createElement(Password, { width: size, height: size, fill: color || fill });
        /**
         * 입장 전
         */
        case 'enter':
            return React.createElement(PreviewEnterIcon, { width: size, height: size, fill: color || fill });
        /**
         * layout
         */
        case 'shared_screen':
            return React.createElement(ScreenModeIcon, { width: size, height: size, fill: color || fill });
        case 'video_mode':
            return React.createElement(VideoIcon, { width: size, height: size, fill: color || fill });
        case 'GRID_MODE':
            if (APP.config.name === 'class') {
                return React.createElement(ClassVideoIcon, { width: size, height: size, fill: '#545454', stroke: color });
            }
            return React.createElement(GridModeIcon, { width: size, height: size, fill: color || fill });
        case 'PINNED_MODE':
            return React.createElement(PinModeIcon, { width: size, height: size, fill: color || fill });
        case 'SEMINAR_MODE':
            return React.createElement(SeminarModeIcon, { width: size, height: size, fill: color || fill });
        case 'VAD_MODE':
            return React.createElement(VadModeIcon, { width: size, height: size, fill: color || fill });
        case 'document':
        case 'share_mode':
            // ClassDocumentModeIcon
            if (APP.config.name === 'class') {
                return React.createElement(ClassDocumentModeIcon, { width: size, height: size, fill: '#545454', stroke: color });
            }
            return React.createElement(DocumentModeIcon, { width: size, height: size, fill: color || fill });
        case 'common_note':
            return React.createElement(NoteModeIcon, { width: size, height: size, fill: color || fill });
        case 'vod':
            if (APP.config.name === 'class') {
                return React.createElement(ClassVodModeIcon, { width: size, height: size, fill: '#545454', stroke: color });
            }
            return React.createElement(VodModeIcon, { width: size, height: size, fill: color || fill });
        case 'out':
            return React.createElement(Exit, { width: size, height: size, fill: color || fill });
        case 'chat':
            return React.createElement(Chat, { width: size, height: size, fill: color || fill });
        case 'member':
            return React.createElement(Member, { width: size, height: size, fill: color || fill });
        case 'setting':
            return React.createElement(Setting, { width: size, height: size, fill: color || fill });
        case 'open':
            return React.createElement(Open, { width: size, height: size, fill: color || fill });
        case 'close':
            return React.createElement(Close, { width: size, height: size, fill: color || fill });
        case 'camera_on':
            return React.createElement(VideoUnMuted, { width: size, height: size, fill: color || fill });
        case 'camera_off':
            return React.createElement(VideoMuted, { width: size, height: size, fill: color || fill });
        case 'mic_on':
            return React.createElement(AudioUnMuted, { width: size, height: size, fill: color || fill });
        case 'mic_off':
            return React.createElement(AudioMuted, { width: size, height: size, fill: color || fill });
        case 'pinned':
            return React.createElement(Pinned, { width: size, height: size, fill: color || fill });
        case 'presenter':
            return React.createElement(Presenter, { width: size, height: size, fill: color || fill });
        case 'time':
            return React.createElement(Time, { width: size, height: size, fill: color || fill });
        case 'personnel':
            return React.createElement(Personnel, { width: size, height: size, fill: color || fill });
        case 'wifi':
            return React.createElement(Wifi, { width: size, height: size, fill: color || fill });
        case 'layout-sort':
            return React.createElement(LayoutSort, { width: size, height: size, fill: color || fill });
        /**
         * share
         */
        case 'whiteboard_add':
            return React.createElement(WhiteBoardAdd, { width: size, height: size, fill: color || fill });
        case 'document_add':
            return React.createElement(DocumentAdd, { width: size, height: size, fill: color || fill });
        case 'previous':
            return React.createElement(Previous, { width: size, height: size, fill: color || fill });
        case 'shared':
            return React.createElement(Share, { width: size, height: size, fill: color || fill });
        case 'delete':
            return React.createElement(Delete, { width: size, height: size, fill: color || fill });
        /**
         * canvas
         */
        case 'menu':
            if (APP.config.name === 'class') {
                return React.createElement(FileArrowUp, { width: size, height: size, fill: color || fill });
            }
            return React.createElement(Menu, { width: size, height: size, stroke: color, fill: color || fill });
        case 'menu_open':
            if (APP.config.name === 'class') {
                return React.createElement(FileArrowUp, { width: size, height: size, fill: color || fill });
            }
            return React.createElement(MenuOpen, { width: size, height: size, stroke: color, fill: color || fill });
        case 'handler':
            return React.createElement(Handler, { width: size, height: size, fill: color || fill, stroke: color });
        case 'choice':
            return React.createElement(Choice, { width: size, height: size, fill: color || fill, stroke: color });
        case 'pen':
            if (APP.config.name === 'class') {
                return React.createElement(ClassPen, { width: size, height: size, fill: color || fill, stroke: color });
            }
            return React.createElement(Pen, { width: size, height: size, fill: color || fill, stroke: color });
        case 'opacity':
            return React.createElement(Opacity, { width: size, height: size, fill: color || fill, stroke: color });
        case 'thickness':
            return React.createElement(Thickness, { width: size, height: size, fill: color || fill });
        case 'color':
            if (APP.config.name === 'class') {
                return React.createElement(ClassColor, { width: size, height: size, fill: color || fill });
            }
            return React.createElement(Color, { width: size, height: size, fill: color || fill });
        case 'eraser':
            return React.createElement(Eraser, { width: size, height: size, fill: color || fill });
        case 'part_eraser':
            return React.createElement(PartEraser, { width: size, height: size, fill: color || fill });
        case 'line_eraser':
            if (APP.config.name === 'class') {
                return React.createElement(ClassEraser, { width: size, height: size, fill: color || fill });
            }
            return React.createElement(LineEraser, { width: size, height: size, fill: color || fill });
        case 'all_eraser':
            return React.createElement(AllEraser, { width: size, height: size, fill: color || fill });
        case 'figure':
            return React.createElement(Figure, { width: size, height: size, fill: color || fill });
        case 'rectangle':
            return React.createElement(Rectangle, { width: size, height: size, fill: color || fill });
        case 'fill_rectangle':
            return React.createElement(FillRectangle, { width: size, height: size, fill: color || fill });
        case 'circle':
            return React.createElement(Circle, { width: size, height: size, fill: color || fill });
        case 'fill_circle':
            return React.createElement(FillCircle, { width: size, height: size, fill: color || fill });
        case 'triangle':
            return React.createElement(Triangle, { width: size, height: size, fill: color || fill });
        case 'fill_triangle':
            return React.createElement(FillTriangle, { width: size, height: size, fill: color || fill });
        case 'text':
            if (APP.config.name === 'class') {
                return React.createElement(ClassText, { width: size, height: size, fill: color || fill });
            }
            return React.createElement(Text, { width: size, height: size, fill: color || fill, stroke: color });
        case 'zoom':
            return React.createElement(Zoom, { width: size, height: size, fill: color || fill, stroke: color });
        case 'fitPage':
            return React.createElement(FitPage, { width: size, height: size, fill: color || fill, stroke: color });
        case 'fitWidth':
            return React.createElement(FitWidth, { width: size, height: size, fill: color || fill, stroke: color });
        case 'fitHeight':
            return React.createElement(FitHeight, { width: size, height: size, fill: color || fill, stroke: color });
        case 'plus':
            return React.createElement(Plus, { width: size, height: size, fill: color || fill, stroke: color });
        case 'minus':
            return React.createElement(Minus, { width: size, height: size, fill: color || fill, stroke: color });
        /**
         * Chat
         */
        case 'raise_hand':
            return React.createElement(RaiseHand, { width: size, height: size, fill: color || fill });
        case 'etc':
            return React.createElement(Etc, { width: size, height: size, fill: color || fill });
        case 'invite':
            return React.createElement(Invite, { width: size, height: size, fill: color || fill });
        case 'search':
            return React.createElement(Search, { width: size, height: size, fill: color || fill });
        case 'sideClose':
            return React.createElement(ChatClose, { width: size, height: size, fill: color || fill });
        case 'down':
            return React.createElement(Down, { width: size, height: size, fill: color || fill, stroke: color });
        case 'up':
            return React.createElement(Up, { width: size, height: size, fill: color || fill, stroke: color });
        case 'file':
            return React.createElement(File, { width: size, height: size, fill: color || fill });
        case 'download':
            return React.createElement(Download, { width: size, height: size, fill: color || fill });
        case 'checked':
            return React.createElement(Checked, { width: size, height: size, fill: color || fill });
        case 'unChecked':
            return React.createElement(Circle, { width: size, height: size, fill: color || fill });
        case 'logo':
            return React.createElement(FillCircle, { width: size, height: size, fill: color || fill });
        case 'selfRecord':
            return React.createElement(SelfRecord, { width: size, height: size, fill: color || fill });
        case 'serverRecord':
            return React.createElement(ServerRecord, { width: size, height: size, fill: color || fill });
        case 'settingDevice':
            return React.createElement(SettingDevice, { width: size, height: size, fill: color || fill });
        case 'dualMonitor':
            return React.createElement(DualMonitor, { width: size, height: size, fill: color || fill });
        case 'group':
            return React.createElement(Group, { width: size, height: size, fill: color || fill });
        case 'modify':
            return React.createElement(Modify, { width: size, height: size, fill: color || fill });
        case 'memberAdd':
            return React.createElement(MemberAdd, { width: size, height: size, fill: color || fill });
        case 'vodDown':
            return React.createElement(VodDown, { width: size, height: size, fill: color || fill });
        case 'vodPlay':
            return React.createElement(VodShow, { width: size, height: size, fill: color || fill });
        case 'accept':
            return React.createElement(Accept, { width: size, height: size, fill: color || fill });
        case 'reject':
            return React.createElement(Reject, { width: size, height: size, fill: color || fill });
        /**
         * options
         */
        case 'security':
            return React.createElement(Security, { width: size, height: size, fill: color || fill });
        case 'devicePermission':
            return React.createElement(AllPermissionSetting, { width: size, height: size, fill: color || fill });
        case 'allAudioOn':
            return React.createElement(AllAudioOn, { width: size, height: size, fill: color || fill });
        case 'allAudioOff':
            return React.createElement(AllAudioOff, { width: size, height: size, fill: color || fill });
        case 'allVideoOn':
            return React.createElement(AllVideoOn, { width: size, height: size, fill: color || fill });
        case 'allVideoOff':
            return React.createElement(AllVideoOff, { width: size, height: size, fill: color || fill });
        case 'fullscreenOn':
            return React.createElement(FullScreenOn, { width: size, height: size, fill: color || fill });
        case 'fullscreenOff':
            return React.createElement(FullScreenOff, { width: size, height: size, fill: color || fill });
        case 'modeChange':
            return React.createElement(ModeChange, { width: size, height: size, fill: color || fill });
        case 'videoChange':
            return React.createElement(VideoChange, { width: size, height: size, fill: color || fill });
        case 'micChange':
            return React.createElement(MicChange, { width: size, height: size, fill: color || fill });
        case 'attendance':
            return React.createElement(Attendance, { width: size, height: size, fill: color || fill });
        case 'option':
            return React.createElement(Option, { width: size, height: size, fill: color || fill });
        case 'emoji':
            return React.createElement(Emoji, { width: size, height: size, fill: color || fill, stroke: color });
        case 'chatSave':
            return React.createElement(ChatSave, { width: size, height: size, fill: color || fill });
        case 'e2ee':
            return React.createElement(E2EE, { width: size, height: size, fill: color || fill });
        case 'youtubeAdd':
            return React.createElement(YoutubeAdd, { width: size, height: size, fill: color || fill });
        case 'urlAdd':
            return React.createElement(UrlAdd, { width: size, height: size, fill: color || fill });
        case 'fileAdd':
            return React.createElement(FileAdd, { width: size, height: size, fill: color || fill });
        case 'roomFileAdd':
            return React.createElement(RoomFileAdd, { width: size, height: size, fill: color || fill });
        case 'nickCompletion':
            return React.createElement(NickCompletion, { width: size, height: size, fill: color || fill });
        case 'nickEdit':
            return React.createElement(NickEdit, { width: size, height: size, fill: color || fill });
        case 'vodStart':
            return React.createElement(VodStart, { width: size, height: size, fill: color || fill });
        case 'vodPause':
            return React.createElement(VodPause, { width: size, height: size, fill: color || fill });
        case 'vodVolumeOn':
            return React.createElement(VodVolumeOn, { width: size, height: size, fill: color || fill });
        case 'vodVolumeOff':
            return React.createElement(VodVolumeOff, { width: size, height: size, fill: color || fill });
        case 'fileDownOn':
            return React.createElement(FileDownOn, { width: size, height: size, fill: color || fill });
        case 'fileDownOff':
            return React.createElement(FileDownOff, { width: size, height: size, fill: color || fill });
        case 'blur':
            return React.createElement(Blur, { width: size, height: size, fill: color || fill });
        case 'survey':
            return React.createElement(Survey, { width: size, height: size, fill: color || fill });
        case 'notification_close':
            return React.createElement(NotiClose, { width: size, height: size, fill: color || fill });
        case 'notification_open':
            return React.createElement(NotiOpen, { width: size, height: size, fill: color || fill });
        case 'notification':
            return React.createElement(Notification, { width: size, height: size, fill: color || fill });
        case 'no_member':
            return React.createElement(NoMember, { width: size, height: size });
        case 'no_record':
            return React.createElement(NoRecord, { width: size, height: size });
        case 'heldRoom_off':
            return React.createElement(StartMeetingOff, { width: size, height: size, fill: color || fill });
        case 'heldRoom_on':
            return React.createElement(StartMeetingOn, { width: size, height: size, fill: color || fill });
        case 'endRoom_off':
            return React.createElement(EndMeetingOff, { width: size, height: size, fill: color || fill });
        case 'endRoom_on':
            return React.createElement(EndMeetingOn, { width: size, height: size, fill: color || fill });
        case 'reserveRoom_on':
            return React.createElement(ReservationMeetingOn, { width: size, height: size, fill: color || fill });
        case 'reserveRoom_off':
            return React.createElement(ReservationMeetingOff, { width: size, height: size, fill: color || fill });
        case 'vod_list_off':
            return React.createElement(VodMeetingOff, { width: size, height: size, fill: color || fill });
        case 'vod_list_on':
            return React.createElement(VodMeetingOn, { width: size, height: size, fill: color || fill });
        case 'all':
            return React.createElement(ALLShow, { width: size, height: size, fill: color || fill });
        case 'arrow':
            return (React.createElement(ArrowIcon, { className: className, style: style, width: width, height: height, stroke: stroke, fill: color || fill }));
        case 'twoarrow':
            return (React.createElement(TwoArrowIcon, { className: className, style: style, width: width, height: height, stroke: stroke }));
        case 'fail':
            return (React.createElement(Fail, { className: className, style: style, width: width, height: height, stroke: stroke, fill: color || fill }));
        case 'pass':
            return (React.createElement(Pass, { className: className, style: style, width: width, height: height, stroke: stroke, fill: color || fill }));
        case 'checked':
            return React.createElement(CheckIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'unchecked':
            return React.createElement(UnCheckedIcon, { width: width, height: height, stroke: stroke, fill: fill });
        case 'radio_checked':
            return React.createElement(RadioChecked, { width: width, height: height, stroke: stroke, fill: fill });
        case 'radio_unchecked':
            return React.createElement(RadioUnchecked, { width: width, height: height, stroke: stroke, fill: fill });
        case 'file-down':
            return React.createElement(FileDown, { width: width, height: height, stroke: stroke, fill: fill });
        case 'cancel':
            return React.createElement(Cancel, { width: width, height: height, stroke: stroke, fill: fill });
        default:
            return null;
    }
});
export default React.memo(Icon);
