import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getMergeOmrFrequency, getQuestionList } from '../../../test';
import { setOmrFrequency } from '../../../test/action';
import { InputElement, Table } from '../../common';
import { AnswerContainer } from '../AnswerContainer';
export const DetailQuestionTable = React.memo(({ test_id, subject, selectedSubjectList, setSubmitQuestionIds, }) => {
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    // question list
    const questionList = useSelector((state) => getQuestionList(state), shallowEqual);
    // omr frequency
    const omrFrequency = useSelector((state) => getMergeOmrFrequency(state), shallowEqual);
    const { headTitleList, tableReadDataKeys } = useMemo(() => {
        const headTitleList = [
            { title: 'subject_item', space: 2, sort: false },
            { title: 'no', space: 1, sort: false },
            { title: 'answer', space: 4, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'subject_item', space: 2 },
            { title: 'number', space: 1 },
            { title: 'omr', space: 4 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    const isMathSubject = React.useMemo(() => subject === 'math', [subject]);
    const onChange = (questionId, text) => {
        const newAnswer = [];
        let isUpdated = false;
        omrFrequency.answer.forEach(answer => {
            // 동일한 question_id가 있으면 덮어쓰기
            if (answer.question_id === questionId) {
                newAnswer.push({ ...answer, values: [text] });
                isUpdated = true; // 수정되었음을 표시
            }
            else {
                // 그 외 답변은 그대로 추가
                newAnswer.push(answer);
            }
        });
        // 동일한 question_id가 없으면 새 답변 추가
        if (!isUpdated) {
            newAnswer.push({
                question_id: questionId,
                values: [text],
            });
        }
        dispatch(setOmrFrequency(test_id, {
            client: {
                subject_item: omrFrequency.subject_item,
                answer: newAnswer,
            },
        }));
    };
    React.useEffect(() => {
        const list = questionList
            .map(item => {
            const prevSubmitAnswer = omrFrequency.answer.find(answer => answer.question_id === item.id);
            if (selectedSubjectList.length > 1) {
                if (omrFrequency.subject_item === item.subject_item) {
                    return { ...item, myAnswer: prevSubmitAnswer?.values };
                }
                if (item.subject_item === '공통') {
                    return { ...item, myAnswer: prevSubmitAnswer?.values };
                }
            }
            else {
                return { ...item, myAnswer: prevSubmitAnswer?.values };
            }
        })
            .filter(item => item);
        setList(list);
        setSubmitQuestionIds(list && list.length > 0
            ? list.map(item => {
                return { id: item.id, index: item.number };
            })
            : []);
    }, [selectedSubjectList, questionList, omrFrequency]);
    const renderItem = (item, styles) => {
        return (React.createElement(QuestionItem, { tableData: tableReadDataKeys, isNumber: isMathSubject, item: item, styles: styles, subject: subject, onChange: onChange }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { className: "task-list-002", showIndex: false, isInifinite: true, page: 0, data: list, totalCount: list.length, headTitleList: headTitleList, styles: {
                headWrapper: {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                headItem: {
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }, renderItem: renderItem, forceShowheader: false })));
});
export const QuestionItem = React.memo(({ tableData, isNumber, item, styles, subject, onChange, }) => {
    const { t } = useTranslation();
    const myAnswer = item.myAnswer && item.myAnswer.length > 0 ? item.myAnswer[0] : '' || '';
    return tableData.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } }, headItem.title === 'omr' ? (item.type === 'choice' ? (React.createElement(AnswerContainer, { maxCount: item.examples, defaultValue: myAnswer ? parseInt(myAnswer) : undefined, onChange: text => onChange(item.id, String(text)) })) : (React.createElement(InputAnswer, { isNumber: isNumber, 
        // @ts-ignore
        isDisable: headItem.title === 'answer' || headItem.title === 'submitAnswer', myAnswer: myAnswer, onChange: text => onChange(item.id, text) }))) : headItem.title === 'subject_item' ? (React.createElement("div", null, ['history', 'character'].includes(subject) ? t(`assignment.${subject}`) : item[headItem.title])) : headItem.title === 'number' ? (React.createElement("p", { className: "bold_number overText" }, item[headItem.title])) : (React.createElement("p", { className: "overText" }, item[headItem.title])))));
});
const InputAnswer = React.memo(({ isNumber, isDisable, myAnswer, onChange, }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = myAnswer;
        }
    }, [myAnswer]);
    return (React.createElement(InputElement, { ref: inputRef, className: "input_answer_wrapper", type: "text", isNumber: isNumber, maxNumLen: isNumber ? 5 : undefined, isDisable: isDisable, placeholder: t('assignment.inputAnswerPlaceholder'), onChange: onChange }));
});
