import { checkOccupiedUser, tempLogin } from '../base/local';
import { toState } from '../base/redux';
import { setRoomOption } from './actions';
/**
 * (ACTION) 임시 방 입장
 */
export const tempEnterRoom = async (data) => {
    return APP.API.searchRoom(data, false, true).then(async (response) => {
        if (response.complete) {
            await tempLogin();
            if (response.data.start_time) {
                const start_time = Date.parse(response.data.start_time);
                response.data.start_time = start_time;
            }
            const data = {
                ...response.data.room,
                start_time: response.data.start_time,
            };
            APP.store.dispatch(setRoomOption(data));
            return { complete: true, data };
        }
        return { complete: false, data: response.data };
    });
};
/**
 * (ACTION) 방 생성
 */
export const createRoom = (name) => {
    return APP.API.createRoom(name).then(response => {
        if (response.complete) {
            const room_uuid = response.data;
            if (room_uuid)
                return tempEnterRoom({ uuid: room_uuid });
            else
                return { complete: false, data: 'api.defaultError' };
        }
        return { complete: false, data: response.data };
    });
};
export const enterRoom = () => {
    console.log('enterRoom');
    return new Promise(async (resolve, reject) => {
        try {
            const { getState } = APP.store;
            // Check for occupied users
            const occupideResponse = await checkOccupiedUser();
            if (!occupideResponse.complete) {
                // Redirect to lobby if necessary
                return resolve(occupideResponse);
            }
            // Check room password if required
            const passwordCheck = await checkRoomPassword(getState);
            if (!passwordCheck.complete) {
                return resolve(passwordCheck);
            }
            if (passwordCheck.message) {
                // Room requires password, return message
                return resolve({ complete: false, message: 'room.passwordChecking' });
            }
            // No password required - join the room
            APP.management
                .join()
                .then(response => {
                resolve({ complete: true, message: response });
            })
                .catch(err => {
                resolve({
                    complete: false,
                    message: err?.message || err || 'An error occurred while entering the room.',
                });
            });
        }
        catch (err) {
            // Handle errors
            resolve({ complete: false, message: err?.message || err || 'An error occurred while entering the room.' });
        }
    });
};
// 방 상태 가져오기
const getRoomState = (stateful) => {
    const state = toState(stateful);
    return state['features/room'];
};
/**
 * 사용자 방에서의 권한
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getPermissions(stateful) {
    return getRoomState(stateful).permissions;
}
/**
 * 방 권한 체크
 * @param {IStore['getState']} stateful
 * @param {string} permission
 * @returns
 */
export function isCheckPermission(stateful, permission) {
    const permissions = getPermissions(stateful);
    return permissions.includes(permission);
}
/**
 * 현재 방 옵션 가져오기 ,
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getRoomOption(stateful) {
    return getRoomState(stateful).option;
}
export function getRoomName(stateful) {
    const activeGroupId = getActiveGroupId(stateful);
    if (activeGroupId !== '') {
        return activeGroupId;
    }
    return getRoomOption(stateful)?.name;
}
/**
 * 방 비밀번호 확인
 * @param stateful
 * @returns
 */
export function checkRoomPassword(stateful) {
    const { uuid } = getRoomOption(stateful);
    return APP.API.getRoom({ uuid }).then((response) => {
        if (response.complete) {
            const data = response.data.items;
            return { complete: true, message: data[0].password_checking };
        }
        return { complete: false, message: 'api.defaultError' };
    });
}
/**
 * 방 미디어 정보 가져오기
 * @param stateful
 * @returns
 */
export function getMediaServerInfo(stateful) {
    return getRoomState(stateful).media_server_info;
}
/**
 * 현재 방에 들어와 있는지에 대한 여부
 * @param {*} stateful
 * @returns
 */
export function isRoomJoined(stateful) {
    return getRoomState(stateful).joined;
}
/**
 * 현재 방에 입장한 그룹 아이디
 * @param {*} stateful
 * @returns
 */
export function getActiveGroupId(stateful) {
    return getRoomState(stateful).group_id;
}
