import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBoxElement } from '../common';
import { Icon } from '../ui';
import { useGlobalState } from '../../app/GlobalStateProvider';
export const SurveyCheckBox = ({ id, className, isRadioButton = false, defaultList = [], isRegist = false, isSubmit = false, setChangeSurveyData, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [list, setList] = useState(defaultList);
    React.useEffect(() => {
        // console.log("defaultList", defaultList);
        setList(defaultList);
    }, [defaultList]);
    const handlerCheck = (id, checked) => {
        if (isRadioButton) {
            setList(prevList => {
                return prevList.map(item => {
                    if (item.id === id) {
                        return { ...item, checked: true };
                    }
                    return { ...item, checked: false };
                });
            });
        }
        else {
            setList(prevList => {
                return prevList.map(item => {
                    if (item.id === id) {
                        return { ...item, checked };
                    }
                    return item;
                });
            });
        }
    };
    const handleAddOption = e => {
        setList(prevList => {
            return [...prevList, { id: `id-${prevList.length}`, title: undefined, checked: false }];
        });
    };
    const onChange = (id, value) => {
        setList(prevList => {
            return prevList.map(item => {
                if (item.id === id) {
                    return { ...item, title: value };
                }
                return item;
            });
        });
    };
    React.useEffect(() => {
        console.log("list:", list);
        setChangeSurveyData({
            id: id,
            data: list,
        });
    }, [list]);
    return (React.createElement("div", { className: `checkbox flex column ${className}` },
        list &&
            typeof list !== 'string' &&
            list.map((item) => (React.createElement(CheckBoxElement, { key: item.id, iconSize: isMobile ? 18 : 24, isRadioButton: isRadioButton, ...item, option: {
                    input: {
                        show: isRegist,
                        placeholder: t('survey.inputAnswerPlaceholder'),
                        className: 'body4',
                        onChange,
                    },
                }, handlerCheck: handlerCheck, style: { justifyContent: 'flex-start' }, isDisable: isSubmit }))),
        isRegist && (React.createElement("label", { className: "flex row center", onClick: handleAddOption, style: { justifyContent: 'flex-start' } },
            React.createElement(Icon, { icon: isRadioButton ? 'radio_unchecked' : 'unchecked', size: isMobile ? 18 : 25 }),
            React.createElement("div", { className: "body4 add-item-text" }, t('common.add'))))));
};
